import React, {useState, useEffect, useRef, useCallback} from 'react'
import { Layout, Row, Col, Select, Button, Card, Divider, Form, message, Spin } from "antd";
import PlaceOrderBg from "../../assets/images/placeorder_bg.jpg"
import "./placeorder.css"
import {
  AllAddressIcon,ProductDetailsButtonAdd,
  OrderPlaceEdit,
  OrderPlaceUserIcon,
  OrderPlaceLocationIcon,
  OrderPlacePhoneIcon,
  OrderPlaceAddIcon,
  ChangeIcon
} from 'assets/SvgIcons';
import AddedProductsTable from './AddedProductsTable';
import AddProductModal from './AddProductModal';
import { getWebsites } from 'utils/oauth-client';
import { getDefaultAddress } from 'utils/customer-client';
import AddNewAddressModal from './AddNewAddressModal';
import { manualOrderPlace } from 'utils/order-client';
import {useHistory, useLocation} from "react-router-dom";
import AllAddressesModal from "./AllAddressesModal";

function PlaceOrder() {

  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mySectionRef = useRef(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [openModal, setOpenModal] = useState(false);
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const [openAllAddressModal, setOpenAllAddressModal] = useState(false)
  const [websites, setWebsites] = useState([]);
  const [selectedWebsite, setSelectedWebsite] = useState("");
  const [defaultAddress, setDefaultAddress] = useState({shipping: null, billing: null})
  const [shippingInfo, setShippingInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [placeProduct, setPlaceProduct] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [addressAction, setAddressAction] = useState('create');
  const [addressType, setAddressType] = useState({
    billing: null,
    shipping: null
  });
  const [seletedAddressType, setSelectedAddressType]= useState("");
  const [orderPlaceSuccess, setOrderPlaceSuccess] = useState(false)
  const productUrl = queryParams.get('productUrl');

  useEffect(() => {
    const response = getWebsites();
    response.then((res) => {
      if(res?.data?.length > 0 ) {
        const mappedData = res?.data?.map((website)=> ({
          value: website.name.toLowerCase(),
          label: website.name
        }))
        setWebsites(mappedData);
      }
    }).catch(({ response }) => {
      if (response?.data) {
        messageApi.open({
          type: 'error',
          content: response?.data?.message,
          style: {marginTop: '50px'}
        })
      }
    })
  }, [])

  useEffect(()=> {
    setSelectedWebsite(websites?.[0]?.value)
  },[websites])

  useEffect(()=> {
    if (productUrl){
      setOpenModal(true)
    }
  },[productUrl])

  const fetchDefaultAddress = useCallback(()=> {
    const response = getDefaultAddress()
    response.then(res=> {
      setDefaultAddress({
        billing: res.data.data.billing,
        shipping: res.data.data.shipping
      })
    }).catch(({ response }) => {
      if (response?.data) {
        messageApi.open({
          type: 'error',
          content: response?.data?.message,
          style: {marginTop: '50px'}
        })
      }
    })
  },[isLoading])

  useEffect(() => {
    fetchDefaultAddress()
  }, [fetchDefaultAddress]);

  const handlePlaceOrder = () => {
    if (placeProduct.length === 0) {
      messageApi.open({
        type: 'warning',
        content: 'Please add a product!',
        style: { marginTop: '50px' }
      });
    }
    else if (selectedProducts.length === 0) {
      messageApi.open({
        type: 'warning',
        content: 'Please select at least one product',
        style: { marginTop: '50px' }
      });
    }
    else {
      if (addressType.billing === null && addressType.shipping === null) {
        messageApi.open({
          type: 'warning',
          content: 'Please add billing and shipping address',
          style: { marginTop: '50px' }
        });
        scrollToSection();

      }
      else if (addressType.billing === null) {
        messageApi.open({
          type: 'warning',
          content: 'Please add billing  address',
          style: { marginTop: '50px' }
        });
        scrollToSection();

      }
      else if (addressType.shipping === null) {
        messageApi.open({
          type: 'warning',
          content: 'Please add shipping address',
          style: { marginTop: '50px' }
        });
        scrollToSection();
      }
      else {
        const addressPayload = {
          billing_address_id: addressType?.billing?.id,
          shipping_address_id: addressType?.shipping?.id
        }
        const placeOrderPayload = {
          products: selectedProducts,
          address: addressPayload
        }
        setOrderPlaceSuccess(true)
        const response = manualOrderPlace(placeOrderPayload)
        response.then(res => {
          if (res.status === 201 || res.status === 200) {
            messageApi.open({
              type: 'success',
              content: 'Order placed successfully!',
              style: { marginTop: '50px' }
            });
          }
          setPlaceProduct([])
          setAddressType({ billing: null, shipping: null })
          setOrderPlaceSuccess(false)
          setTimeout(()=> {
            history.push({
              pathname: `/order`,
            });
          },500)
        }).catch(({ response }) => {
          if (response?.data) {
            messageApi.open({
              type: 'error',
              content: response?.data?.message,
              style: {marginTop: '50px'}
            })
          }
        }).finally(x => setOrderPlaceSuccess(false))
      }
    }
  }

  const handleEdit = (e,address) => {
    e.stopPropagation()
    setSelectedAddress(address)
    setAddressAction('edit');
    setOpenAddressModal(true);
  }

  useEffect(() => {
    setAddressType({
      ...addressType,
      billing: defaultAddress.billing,
      shipping: defaultAddress.shipping
    })
  }, [isLoading,defaultAddress])

  const scrollToSection = () => {
    if (mySectionRef.current) {
      mySectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleWebsiteOptionsChange = (value)=> {
    setSelectedWebsite(value);
  }

  return (
    <>
      {contextHolder}
      {/* header section */}
      <Form onFinish={handlePlaceOrder}>
        <Layout
          style={{
            width: "98%",
            margin: "0 auto",
            minHeight: "190px",
            paddingTop: "42px",
            paddingBottom: "42px",
            background: "#FFF",
            borderRadius: '8px',
            backgroundImage: "url(" + PlaceOrderBg + ")",
            opacity: orderPlaceSuccess ? "0.5" : "1"
          }}
          ref={mySectionRef}
        >
          <h1 className='place-order-first-section-header'>Create Order</h1>
          <Row gutter={[0, 16]} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='place-order-first-section-input'>
            <Col>
              <span className='place-order-first-section-label'>Select Your Website</span>
            </Col>
            <Col style={{ paddingLeft: '16px' }}>
              <Select
                value={selectedWebsite}
                className='place-order-first-section-select'
                onChange={handleWebsiteOptionsChange}
                options={websites}
              />
            </Col>
          </Row>
        </Layout>

        {/* customer address section */}

        {isLoading ? <>
          <Layout
            style={{
              width: "98%",
              margin: "0 auto",
              minHeight: "350px",
              marginTop: '24px',
              background: "#FFF",
              borderRadius: '8px',
              boxShadow: '0px 2px 4px 0px rgba(29, 49, 72, 0.05)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              Opacity: isLoading ? "0.5": "1"
            }}
          >
            <Spin />
          </Layout>
        </> : <Layout
          style={{
            width: "98%",
            margin: "0 auto",
            minHeight: "350px",
            marginTop: '24px',
            background: "#FFF",
            borderRadius: '8px',
            boxShadow: '0px 2px 4px 0px rgba(29, 49, 72, 0.05)',
            opacity: orderPlaceSuccess ? "0.5" : "1",
            position: 'relative'
          }}
        >
          {orderPlaceSuccess && <Spin style={{ position: 'absolute', top: "50%", left: '50%' }} />}
          <Row gutter={[24, 16]} style={{ padding: '27px', display: 'flex', justifyContent: 'start', width: "100%" }}>
            <Row style={{padding: '0px 18px',width: '100%',display: 'flex', flexWrap: 'wrap'}}>
              <Col span={20} >
                <h1 className='place-order-address-section-header'>Customer Address</h1>
              </Col>
              <Col span={4} style={{ display: 'flex', justifyContent: 'end'}}>
                   <span style={{display: 'flex',cursor: 'pointer'}} onClick={()=>{
                     setSelectedAddressType("all")
                     setOpenAllAddressModal(true)
                   }
                   }>
                         <AllAddressIcon/>
                         <h1 className='place-order-address-section-header' style={{color: '#F37B78',paddingLeft: '8px'}}>All Addresses</h1>
                   </span>
              </Col>
            </Row>

              {defaultAddress.billing ? (
                <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
                  {/* billing address */}
                  <Card
                    style={{
                      boxShadow: 'none',
                      minHeight: "226px",
                      border: "1px solid #E5E7EB"
                    }}
                  >
                    <Row gutter={[0, 16]} style={{ display: 'flex', alignItems: 'center' }}>
                      <Col xs={24} xxl={20}>
                        <span style={{ background: "rgba(243, 123, 120, 0.05)", padding: "6px 20px", borderRadius: "16px", border: '1px solid #F37B78', color: "#F37B78", fontSize: "14px" }} className='edit-import-status-text'>{defaultAddress.billing.is_default_billing === 1 ? 'Default Billing Address' : 'Billing Address' }</span>
                      </Col>
                      <Col xs={24} xxl={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                               <span onClick={(e) => handleEdit(e,defaultAddress.billing)} style={{ cursor: 'pointer' }}>
                                      <OrderPlaceEdit />
                               </span>
                        <Divider type="vertical" style={{ height: "15px" }} />
                        <span onClick={() => {
                          setSelectedAddressType("billing")
                          setOpenAllAddressModal(true)
                        }} style={{ cursor: 'pointer' }}>
                              <ChangeIcon />
                                 </span>
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: '22px', display: 'flex', flexDirection: 'column', gap: '4px' }}>
                      <Row style={{ display: 'flex', alignItems: 'center' }}>
                        <Col span={2} ><OrderPlaceUserIcon /></Col>
                        <Col span={22} >
                          <span className='place-order-address-text'>{defaultAddress.billing.name}</span>
                        </Col>
                      </Row>
                      <Row style={{ display: 'flex', alignItems: 'center' }}>
                        <Col span={2} ><OrderPlacePhoneIcon /></Col>
                        <Col span={22} >
                          <span className='place-order-address-text'>{defaultAddress.billing?.phone}</span>
                        </Col>
                      </Row>
                      <Row style={{ display: 'flex',width: "100%" }}>
                        <Col span={2} ><OrderPlaceLocationIcon /></Col>
                        <Col span={22} >
                          <span className='place-order-address-text' > {`${defaultAddress.billing?.address}, ${defaultAddress.billing?.area?.name}, ${defaultAddress.billing?.postal_code}, ${defaultAddress.billing?.district?.name}`}</span>
                        </Col>
                      </Row>
                    </Row>
                  </Card>
                </Col>
              ) : ""
            }

              {defaultAddress.shipping ? (
                <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
                  {/* billing address */}
                  <Card
                    style={{
                      boxShadow: 'none',
                      minHeight: "226px",
                      border: "1px solid #E5E7EB"
                    }}
                  >
                    <Row gutter={[0, 16]} style={{ display: 'flex', alignItems: 'center' }}>
                      <Col xs={24} xxl={20}>
                        <span style={{ background: "rgba(243, 123, 120, 0.05)", padding: "6px 20px", borderRadius: "16px", border: '1px solid #F37B78', color: "#F37B78", fontSize: "14px" }} className='edit-import-status-text'>{defaultAddress.shipping.is_default_shipping === 1 ? 'Default Shipping Address' : 'Shipping Address' }</span>
                      </Col>
                      <Col xs={24} xxl={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                         <span onClick={(e) => handleEdit(e,defaultAddress.shipping)} style={{ cursor: 'pointer' }}>
                               <OrderPlaceEdit />
                         </span>
                        <Divider type="vertical" style={{ height: "15px" }} />
                        <span onClick={() => {
                          setSelectedAddressType("shipping")
                          setOpenAllAddressModal(true)
                        }} style={{ cursor: 'pointer' }}>
                                 <ChangeIcon />
                         </span>
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: '22px', display: 'flex', flexDirection: 'column', gap: '4px' }}>
                      <Row style={{ display: 'flex', alignItems: 'center' }}>
                        <Col span={2} ><OrderPlaceUserIcon /></Col>
                        <Col span={22} >
                          <span className='place-order-address-text'>{defaultAddress.shipping.name}</span>
                        </Col>
                      </Row>
                      <Row style={{ display: 'flex', alignItems: 'center' }}>
                        <Col span={2} ><OrderPlacePhoneIcon /></Col>
                        <Col span={22} >
                          <span className='place-order-address-text'>{defaultAddress.shipping?.phone}</span>
                        </Col>
                      </Row>
                      <Row style={{ display: 'flex',width: "100%" }}>
                        <Col span={2} ><OrderPlaceLocationIcon /></Col>
                        <Col span={22} >
                          <span className='place-order-address-text' > {`${defaultAddress.shipping?.address}, ${defaultAddress.shipping?.area?.name}, ${defaultAddress.shipping?.postal_code}, ${defaultAddress.shipping?.district?.name}`}</span>
                        </Col>
                      </Row>
                    </Row>
                  </Card>
                </Col>
              ) : ""
            }

            {!defaultAddress.billing &&  <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
              <Card
                style={{
                  boxShadow: 'none',
                  minHeight: "226px",
                  display: 'flex', justifyContent: 'center', alignItems: 'center',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setOpenAllAddressModal(true);
                  setSelectedAddressType("billing")
                }}
              >
                <Row style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Row >
                          <span style={{ padding: '15px 15px', display: 'flex', justifyContent: 'center', borderRadius: "100%", background: 'rgba(156, 163, 175, 0.20)' }}>
                                <OrderPlaceAddIcon />
                          </span>
                  </Row>
                  <Row style={{ paddingTop: '12px' }}>
                    <span className='place-order-address-text'>Add New Billing Address</span>
                  </Row>
                </Row>
              </Card>
            </Col> }

            {!defaultAddress.shipping &&  <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
              <Card
                style={{
                  boxShadow: 'none',
                  minHeight: "226px",
                  display: 'flex', justifyContent: 'center', alignItems: 'center',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setOpenAllAddressModal(true);
                  setSelectedAddressType("shipping")
                }}
              >
                <Row style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Row >
                         <span style={{ padding: '15px 15px', display: 'flex', justifyContent: 'center', borderRadius: "100%", background: 'rgba(156, 163, 175, 0.20)' }}>
                               <OrderPlaceAddIcon />
                         </span>
                  </Row>
                  <Row style={{ paddingTop: '12px' }}>
                    <span className='place-order-address-text'>Add New Shipping Address</span>
                  </Row>
                </Row>
              </Card>
            </Col> }

            <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
              <Card
                style={{
                  boxShadow: 'none',
                  minHeight: "226px",
                  display: 'flex', justifyContent: 'center', alignItems: 'center',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setOpenAddressModal(true);
                  setAddressAction("create")
                }}
              >
                <Row style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Row >
                        <span style={{ padding: '15px 15px', display: 'flex', justifyContent: 'center', borderRadius: "100%", background: 'rgba(156, 163, 175, 0.20)' }}>
                               <OrderPlaceAddIcon />
                        </span>
                  </Row>
                  <Row style={{ paddingTop: '12px' }}>
                    <span className='place-order-address-text'>Add New Delivery Address</span>
                  </Row>
                </Row>
              </Card>
            </Col>
          </Row>
        </Layout>
        }

        <AddNewAddressModal
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          openAddressModal={openAddressModal}
          setOpenAddressModal={setOpenAddressModal}
          addressAction={addressAction}
          setAddressAction={setAddressAction}
          addressType={addressType}
          selectedAddress={selectedAddress}
          setOpenAllAddressModal={setOpenAllAddressModal}
        />

        <AllAddressesModal
          handleEdit={handleEdit}
          setOpenAddressModal={setOpenAddressModal}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          defaultAddress={defaultAddress}
          setDefaultAddress={setDefaultAddress}
          openAllAddressModal={openAllAddressModal}
          setOpenAllAddressModal={setOpenAllAddressModal}
          setAddressAction={setAddressAction}
          selectedAddressType={seletedAddressType}
        />

        {/* products section */}
        <Layout
          style={{
            width: "98%",
            margin: "0 auto",
            height: "auto",
            marginTop: '24px',
            background: "#FFF",
            borderRadius: '8px',
            boxShadow: '0px 2px 4px 0px rgba(29, 49, 72, 0.05)',
            opacity: orderPlaceSuccess ? "0.5" : "1"

          }}
        >
          <Row style={{ padding: '32px 32px' }}>
            <Row gutter={[0, 24]} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <Col span={21} xs={12} sm={17} md={18} lg={19} xl={20} xxl={21} >
                <h1 className='place-order-address-section-header'>Products ({placeProduct.length})</h1>
              </Col>
              <Col span={3} xs={12} sm={7} md={6} lg={5} xl={4} xxl={3} style={{ display: 'flex', justifyContent: 'end' }} >
                <Button onClick={() => setOpenModal(true)} style={{ height: '40px' }} className='edit-import-top-cta2' ><ProductDetailsButtonAdd />Add Products</Button>
              </Col>
            </Row>
            <Row style={{ marginTop: '16px', width: '100%', border: '1px solid #E5E7EB', borderRadius: '6px' }}>
              <AddedProductsTable placeProduct={placeProduct} setPlaceProduct={setPlaceProduct} setSelectedProducts={setSelectedProducts} />
            </Row>
            <Row
              style={{
                width: '100%',
                paddingTop: "32px",
                gap: "12px",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Form.Item>
                <Button
                  htmlType="submit"
                  style={{
                    color: "#FFF",
                    padding: "12px 33px",
                    display: "flex",
                    alignItems: "center",
                    background: "#F37B7B",
                    borderRadius: "5px",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "16px",
                  }}
                >
                  Place Order
                </Button>
              </Form.Item>
            </Row>
          </Row>
        </Layout>
        <AddProductModal shippingInfo={shippingInfo} setShippingInfo={setShippingInfo} openModal={openModal} setOpenModal={setOpenModal} placeProduct={placeProduct} setPlaceProduct={setPlaceProduct} productUrl={productUrl} />
      </Form >
    </>
  )
}

export default PlaceOrder
