import React from 'react'
import { Table } from "antd";
import { DateTime } from 'components'

const { Column } = Table
const TransactionsTable = Props => {
  const { type } = Props;

  return (
    <Table
      dataSource={Props.paymentHistory}
      rowKey="id"
      title={() => <span className="font-weight-700">Transactions</span>}
      loading={Props.isLoading}
      pagination={false}
    >
      <Column
        title="Date & Time"
        dataIndex="image"
        key="id"
        align="center"
        responsive={['md']}
        render={(id, record) => {
          return type === "shipment-invoice" ?
            <DateTime date={record.paid_at} />
            :
            <DateTime date={record.created_at} />
        }}
      />

      <Column
        title="Transaction Id"
        dataIndex="payable"
        key="transaction_id"
        render={(payable, record) => {
          return payable ? payable.transaction_id : null
        }}
      />
      <Column
        title="Amount"
        dataIndex="amount"
        key="amount"
        align={"center"}
        render={(amount, record) => {
          if (!amount) {
            return <></>
          }
          return type === "shipment-invoice" ? parseFloat(amount).toFixed(2) : amount.toFixed(2)
        }}
      />
    </Table>
  )
}

export default TransactionsTable
