import React, { useEffect, useState } from 'react'
import { Table, Row, Input, Button, message } from "antd";
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { store_rates } from 'consts/products';

function ProductPropertiesTable({
    product,
    editProduct,
    variation,
    setSelectedVariants,
    isSearched,
    fetched,
}) {

    const totalFx = store_rates.find((item) => item.storeId === product?.shop_id)?.totalFx;
    const [selectionType, setSelectionType] = useState('checkbox');
    const [rowData, setRowData] = useState([]);
    const [quantities, setQuantities] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState(null);

    useEffect(() => {
        if(editProduct){
        const data = variation?.skus?.map((sku, index) => {
          const propValues = sku.props.split(',');
          const variations = propValues.map(propValue => {
            const matchingProp = variation?.props?.find(prop => prop?.values?.some(value => value.id.toString() === propValue));
            return matchingProp
              ? {
                propId: matchingProp.id,
                propTitle: matchingProp.name,
                propLabel: matchingProp.name,
                propValue: propValue.trim(),
                propInnerValue: matchingProp.values.find(value => value.id.toString() === propValue)?.name || null,
                innerImage: matchingProp.values.find(value => value.id.toString() === propValue)?.thumb || null,
              }
              : [];
          });
          return {
            ...sku,
            key: index,
            sku: sku.id.toString(),
            price: (sku.price.actual * totalFx).toFixed(2),
            stock: sku.stock.available,
            variation: variations,
            qty: 0,
          };
        });
        const updatedProduct = data?.map((item) => {
          const matchingVariation = editProduct?.variations.find((v) => v.sku_id === item.id);
          if (matchingVariation) {
            return { ...item, qty: matchingVariation.qty };
          }
          return item;
        });
        setRowData(updatedProduct)
      }

    }, [fetched, editProduct,isSearched])


    useEffect(() => {
      if(rowData){
        const newZeroArray = Array.from(rowData, (x) => x?.qty);
        setQuantities(newZeroArray);
        const updatedSelectedIds = rowData
              .filter(item => item.qty > 0)
              .map(item => item.key);
          setSelectedRowKeys(updatedSelectedIds);
          const updatedVariantsArray = rowData.filter((item,index)=> {
              if(item.qty > 0 ){
                  return item
              }
          })
          setSelectedVariants(updatedVariantsArray)
      }
    }, [fetched,isSearched,rowData])

    const uniqueVariations = Array.from(
        new Set(rowData?.flatMap(sku => sku.variation?.map(variation => variation?.propTitle)))
    );

    const handleAdd = (_, index, stock, record) => {
        const updatedQuantities = [...quantities];
        updatedQuantities[index] = Math.min(100000, updatedQuantities[index] + 1);
        if (updatedQuantities[index] <= stock && updatedQuantities[index] < 100000) {
            setQuantities(updatedQuantities);
            const result = rowData.map((item) => {
                if (item.id === record.id) {
                    item.qty = updatedQuantities[index]
                    return item
                } else {
                    return item
                }
            })
            setRowData(result)
        }
    };

    const handleMinus = (key, index, _, record) => {
        const updatedQuantities = [...quantities];
        updatedQuantities[index] = Math.max(0, updatedQuantities[index] - 1);
        setQuantities(updatedQuantities);
        const result = rowData.map((item) => {
            if (item.id === record.id) {
                item.qty = updatedQuantities[index]
                return item
            } else {
                return item
            }
        })
        setRowData(result)
    };

    const handleInputChange = (e, index, stock, record) => {
        const value = parseInt(e.target.value);
        if (!isNaN(value) && value >= 0 && value < 100000 && value <= stock) {
            const updatedQuantities = [...quantities];
            updatedQuantities[index] = value;
            setQuantities(updatedQuantities);
            const result = rowData.map((item) => {
                if (item.id === record.id) {
                    item.qty = updatedQuantities[index]
                    return item
                } else {
                    return item
                }
            })
            setRowData(result)
        }
    };

    let columns = [];

    if (uniqueVariations.length === 1 && uniqueVariations[0] === undefined) {
        columns = [

            {
                title: "Quantity",
                dataIndex: "quantity",
                render: (_, record, index) => {
                    return record.stock === 0 ? <span className="order-place-table-text">Stock out</span> :
                        <Row>
                            <div style={{ position: "relative" }} >
                                <Button onClick={() => handleMinus(record.key, index, record.stock, record)} style={{ position: "absolute", left: 0, top: 0, zIndex: 100, height: "32px", display: 'flex', alignItems: 'center', justifyContent: 'center' }} icon={< MinusOutlined style={{ width: '17px' }} />}></Button>
                                <Input value={quantities[index]} className="custom-quantity-input" style={{ width: "130px", textAlign: 'center' }} type="number" max={record.stock} onChange={(e) => handleInputChange(e, index, record.stock, record)} />
                                <Button onClick={() => handleAdd(parseInt(record.key), index, record.stock, record)} style={{ position: "absolute", right: 0, top: 0, zIndex: 100, height: "32px", display: 'flex', alignItems: 'center', justifyContent: 'center' }} icon={< PlusOutlined style={{ width: '17px' }} />}></Button>
                            </div >
                        </Row >
                }
            },
            {
                title: "Unit Price",
                dataIndex: "unit_price",
                render: (_, record) => <span className="order-place-table-text" >৳{record?.price}</span>
            },
            {
                title: "Total Price",
                dataIndex: "total_price",
                render: (_, record, index) => <span style={{ maxWidth: '50px', whiteSpace: 'wrap' }} className="order-place-table-text" >{`৳${(record?.price * quantities[index]).toFixed(2)}`}</span>
            },
        ]
    } else {
        columns = [
            ...uniqueVariations.map(variation => ({
                title: variation,
                dataIndex: 'variation',
                key: variation,
                width: 150,
                render: (_, record) => {
                    const matchingVariation = record.variation.find(v => v?.propTitle === variation);
                    return matchingVariation ? <div>
                        <span style={{ whiteSpace: "wrap" }} className='edit-import-table-cell-text'>{matchingVariation.propInnerValue}</span>
                    </div> : "-";
                },
            })),
            {
                title: "Quantity",
                dataIndex: "quantity",
                render: (_, record, index) => {
                    return record.stock === 0 ? <span className="order-place-table-text">Stock out</span> :
                        <Row>
                            < div style={{ position: "relative" }} >
                                <Button onClick={() => handleMinus(record.key, index, record.stock, record)} style={{ position: "absolute", left: 0, top: 0, zIndex: 100, height: "32px", display: 'flex', alignItems: 'center', justifyContent: 'center' }} icon={< MinusOutlined style={{ width: '17px' }} />}></Button>
                                <Input value={quantities[index]} className="custom-quantity-input" style={{ width: "130px", textAlign: 'center' }} type="number" max={record.stock} onChange={(e) => handleInputChange(e, index, record.stock, record)} />
                                <Button onClick={() => handleAdd(parseInt(record.key), index, record.stock, record)} style={{ position: "absolute", right: 0, top: 0, zIndex: 100, height: "32px", display: 'flex', alignItems: 'center', justifyContent: 'center' }} icon={< PlusOutlined style={{ width: '17px' }} />}></Button>
                            </div >
                        </Row >
                }
            },
            {
                title: "Unit Price",
                dataIndex: "unit_price",
                render: (_, record) => <span className="order-place-table-text" >৳{record?.price}</span>
            },
            {
                title: "Total Price",
                dataIndex: "total_price",
                render: (_, record, index) => <span style={{ maxWidth: '50px', whiteSpace: 'wrap' }} className="order-place-table-text" >{`৳${(record?.price * quantities[index]).toFixed(2)}`}</span>
            },
        ]
    }

    function hasImage(objects) {
        return objects?.some(obj => obj.values.some(value => value.image !== null));
    }

    const hasImageInProps = hasImage(variation?.props);
    if (hasImageInProps) {
        let dynamicImageColumn = [{
            title: "Image",
            dataIndex: "image",
            width: 150,
            render:
                (_, record) =>
                    <div style={{ display: "flex", gap: "13px", alignItems: "center" }} >
                        <img
                            style={{ width: "63px", height: "63px", borderRadius: "8px" }}
                            src={record?.variation?.filter((item) => item?.innerImage !== null)?.find((image) => image.innerImage)?.innerImage}
                            alt="product"
                        />
                    </div>
        }]
        columns.splice(0, 0, ...dynamicImageColumn)
    } else {
        let dynamicImageColumn = [{
            title: "Image",
            dataIndex: "image",
            width: 150,
            render:
                (_, record) =>
                    <div style={{ display: "flex", gap: "13px", alignItems: "center" }}>
                        <img
                            style={{ width: "63px", height: "63px", borderRadius: "8px" }}
                            src={`https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png`}
                            alt="N/A"
                        />
                    </div>
        }]
        columns.splice(0, 0, ...dynamicImageColumn)
    }

    if (variation?.skus?.length > 0) {
        let dynamicSkuColumn = [
            {
                title: 'SKU',
                dataIndex: "sku",
                width: 150,
                render: (_, record) => (
                    <div style={{ whiteSpace: 'pre-line' }}>
                        <span className='edit-import-table-cell-text'>{record?.sku?.replace(/(.{12})/g, '$1\n')}</span>
                    </div>
                )
            }
        ]
        let DynamicStockColumn = [
            {
                title: "Stock",
                dataIndex: "stock",
                width: 100,
                render: (_, record) => (
                    <div>
                        <span className='edit-import-table-cell-text'>{record?.stock}</span>
                    </div>
                )
            }
        ]
        columns.splice(1, 0, ...dynamicSkuColumn)
        columns.splice(columns.length - 3, 0, ...DynamicStockColumn)
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedVariants(selectedRows)
            setSelectedRowKeys(selectedRowKeys)
        },
        getCheckboxProps: (record) => ({
            checked: record?.qty > 0
        }),
    };


    return (
        <Table
            rowSelection={{
                type: selectionType,
                ...rowSelection,
            }}
            rowKey={(record) => record.key}
            scroll={{ x: 800 }}
            columns={columns}
            dataSource={rowData}
            style={{ width: "100%" }}
            pagination={true}
        />
    )
}

export default ProductPropertiesTable
