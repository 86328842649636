import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { getQueryParams, insertUrlParams } from "utils/helpers";
import InvoiceList from "./InvoiceList";
import {
  BUY_AND_SHIP,
  SHIP_FOR_ME,
  SIDENAV_BAR_COLOR,
} from "consts/app-constants";
import InvoicePayment from "./InvoicePayment";
import { BuyingInvoice, ShipmentInvoice } from "../../assets/SvgIcons";

const { TabPane } = Tabs;

const Invoice = () => {
  const [currentAction, setCurrentAction] = useState("invoice-overview");
  const queryParams = getQueryParams();

  useEffect(() => {
    setCurrentAction("invoice-overview");
    if (queryParams["action"]) {
      setCurrentAction(queryParams["action"]);
    }
  }, [queryParams]);

  const handlePageAction = (page, tab = null) => {
    let paramData = [
      {
        key: "action",
        value: page,
      },
    ];

    if (tab) {
      paramData.push({
        key: "invoice-tab",
        value: tab,
      });
    }

    setCurrentAction(page);
    insertUrlParams(paramData);
  };

  const getExtraComponents = () => {
    switch (currentAction) {
      case "invoice-overview":
        return <InvoiceOverview handlePageAction={handlePageAction} />;
      case "invoice-payment":
        return <InvoicePayment handlePageAction={handlePageAction} />;
      default:
        return <></>;
    }
  };
  return getExtraComponents();
};

const InvoiceOverview = (Props) => {
  const { handlePageAction } = Props;
  const [currentTab, setCurrentTab] = useState(BUY_AND_SHIP);
  const queryParams = getQueryParams();

  useEffect(() => {
    if (queryParams["invoice-tab"]) {
      setCurrentTab(queryParams["invoice-tab"]);
    } else {
      setCurrentTab(BUY_AND_SHIP);
    }
  }, [queryParams]);

  return (
    <Tabs
      type="card"
      activeKey={currentTab}
      onChange={(tab) => {
        handlePageAction("invoice-overview", tab);
        setCurrentTab(tab);
      }}
    >
      <TabPane
        tab={
          <div className="tabs-header">
            <BuyingInvoice color={SIDENAV_BAR_COLOR} />
            <span className="tabs-header-title">Buying Invoice</span>
          </div>
        }
        key="buy-and-ship"
      >
        <InvoiceList
          invoiceType={BUY_AND_SHIP}
          handlePageAction={handlePageAction}
        />
      </TabPane>
      <TabPane
        tab={
          <div className="tabs-header">
            <ShipmentInvoice color={SIDENAV_BAR_COLOR} />
            <span className="tabs-header-title">Shipment Invoice</span>
          </div>
        }
        key="ship-for-me"
      >
        <InvoiceList
          invoiceType={SHIP_FOR_ME}
          handlePageAction={handlePageAction}
        />
      </TabPane>
    </Tabs>
  );
};

export default Invoice;
