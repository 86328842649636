import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Layout, Drawer, Affix } from "antd";
import Sidenav from "../Sidenav";
import Header from "../Header";
import Footer from "../Footer";
import TopBar from "../TopBar";
import { SIDENAV_BAR_COLOR } from "consts/app-constants";
const { Header: AntHeader, Content, Sider } = Layout;

function Main({ children }) {
  const [visible, setVisible] = useState(false);
  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");
  const openDrawer = () => setVisible(!visible);

  return (
    <>
      <TopBar />
      <Layout
        className={`layout-dashboard ${pathname === "profile" ? "layout-profile" : ""
          } ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}
        style={{ paddingTop: "80px" }}
      >
        <Drawer
          title={false}
          placement={"left"}
          closable={false}
          onClose={() => setVisible(false)}
          visible={visible}
          key={"right"}
          width={250}
          className={`drawer-sidebar`}
        >
          <Layout
            className={`layout-dashboard`}
          >
            <Sider
              trigger={null}
              width={250}
              theme="light"
              className={`sider-primary ant-layout-sider-primary active-route`}
              style={{ background: "transparent" }}
            >
              <Sidenav color={SIDENAV_BAR_COLOR} />
            </Sider>
          </Layout>
        </Drawer>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          trigger={null}
          width={240}
          theme="light"
          className={`sider-primary ant-layout-sider-primary active-route`}
          style={{ background: "#FFF", marginLeft: "-5px", marginTop: "-2px", boxShadow: '0px 4px 4px 0px rgba(29, 49, 72, 0.08)' }}
        >
          <Sidenav color={SIDENAV_BAR_COLOR} />
        </Sider>
        <Layout>
          <Affix >
            <AntHeader>
              <Header
                name={pathname}
                subName={pathname}
                onPress={openDrawer}
              />
            </AntHeader>
          </Affix>
          <Content className="content-ant" style={{ padding: "-10px" }}>{children}</Content>
          <Footer />
        </Layout>
      </Layout>
    </>
  );
}

export default Main;
