import { saveAs } from 'file-saver';

export const handleImageDownload = async (imageUrl) => {
    try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        saveAs(blob, 'downloaded-image.jpg');
    } catch (error) {
        console.error('Error downloading image:', error);
    }
};