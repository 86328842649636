import React, { useState, useEffect } from 'react'
import { Row,Col,Modal, Form, Input, Checkbox, Button, Select, message } from 'antd'
import { getLocation } from 'utils/generic-client';
import { storeAddress, updateAddress } from 'utils/customer-client';

function AddNewAddressModal({
                              openAddressModal,
                              setOpenAddressModal,
                              setIsLoading,
                              addressAction,
                              selectedAddress,
                            }) {

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [district, setDistrict] = useState(null);
  const [area, setArea] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null)
  const handleAddress = (data) => {
    let apiClient = null;
    if (addressAction === "create") {
      apiClient = storeAddress(data)
    }
    else {
      const payload = {...data}
      payload.area = selectedArea ? selectedArea : selectedAddress.area?.id
      apiClient = updateAddress(selectedAddress.id,payload);
    }
    setIsLoading(true)
    apiClient.then(res => {
      if (res.status === 200 || res.status === 201) {
        setOpenAddressModal(false)
        setArea(null)
        setTimeout(()=> {
          if (addressAction === "edit") {
            messageApi.open({
              type: 'success',
              content: 'Address updated successfully!',
              style: { marginTop: '50px' }
            });
          }
          else {
            messageApi.open({
              type: 'success',
              content: 'Address created successfully!',
              style: { marginTop: '50px' }
            });
          }
          setIsLoading(false)
        },1500)
      }
    })
  }

  useEffect(() => {
    getLocation().then(res => {
      setDistrict(res?.data?.data.map((district) => {
        return {
          value: district.id,
          label: district.name,
        }
      }))
    })
      .catch(({ response }) => {
        if (response?.data) {
          messageApi.open({
            type: 'error',
            content: response?.data?.message,
            style: {marginTop: '50px'}
          })
        }
      })
  }, [])

  const handleAddressSelect = (type, data) => {
    const getArea = getLocation(type).then(res => {
      setArea(res?.data?.data.map((area) => {
        return {
          value: area.id,
          label: area.name
        }
      }))
    }).catch(({ response }) => {
      if (response?.data) {
        messageApi.open({
          type: 'error',
          content: response?.data?.message,
          style: {marginTop: '50px'}
        })
      }
    })
  }

  useEffect(() => {
    form.resetFields();
    if (addressAction === "edit") {
      let data = {
        name: selectedAddress.name,
        phone: selectedAddress.phone,
        country: selectedAddress.country,
        district: selectedAddress.district?.id,
        area: selectedAddress.area?.name,
        address: selectedAddress.address,
        postal_code: selectedAddress.postal_code,
        is_default_shipping: selectedAddress.is_default_shipping,
        is_default_billing: selectedAddress.is_default_billing,
      }
      form.setFieldsValue(data);
    }
    else {
      form.setFieldsValue({ is_active: true })
    }
  }, [addressAction,selectedAddress,openAddressModal]);

  return (
    <>
      {contextHolder}
      <Modal
        style={{zIndex: 1000}}
        width={640}
        closable={true}
        open={openAddressModal}
        onCancel={() => {
          form.resetFields();
          setArea(null)
          setOpenAddressModal(false)
        }
        }
        footer={null}
      >
        <Row style={{marginTop: '30px'}}>
          <h2>Add Address</h2>
        </Row>
        <Form
          form={form}
          name="register"
          onFinish={handleAddress}
          scrollToFirstError
          layout='vertical'
        >
          <Col span={24}>
            <Form.Item
              name="name"
              label="Full Name"
              rules={[{ required: true, message: 'Input full name!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Row gutter={[16,16]}>
            <Col span={12}>
              <Form.Item
                name="phone"
                label="Phone"
                rules={[{ required: true, message: 'Input mobile number!' }]}
              >
                <Input style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="country"
                label="Country"
                rules={[{ required: true, message: 'Please select country' }]}
              >
                <Select options={[
                  { value: 'Bangladesh', label: 'Bangladesh' },
                ]} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16,16]}>
            <Col span={12}>
              <Form.Item
                name="district"
                label="District"
                rules={[{ required: true, message: 'Please select district' }]}
              >
                <Select
                  showSearch={true}
                  filterOption={(input, option) =>
                    option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA?.children?.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={handleAddressSelect}
                >
                  {
                    district?.map(((item, index) => {
                      return <Select.Option value={item.value} key={index}>{item.label}</Select.Option>
                    }))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="area"
                label="Area"
                rules={[{ required: true, message: 'Please select area' }]}
              >
                {
                  area ?
                    <Select
                      type="area"
                      options={area}
                      placeholder={"Please enter your area"}
                      onChange={(value,label)=>setSelectedArea(label.value)}
                    />
                    : <Input
                      type="text"
                      className="form-control"
                      name="postal_code"
                      placeholder="Please choose district first"
                      disabled
                      style={{ width: "100%" }}
                    />
                }
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="address"
            label="Street Address"
            rules={[{ required: true, message: 'Address must be needed' }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="postal_code"
            label="Postal Code"
            rules={[{ required: true, message: 'Postal code must be needed' }]}
          >
            <Input />
          </Form.Item>
          <Row >
            <Col span={12}>
              <Form.Item
                name="is_default_billing"
                label="Default Billing"
                valuePropName="checked"
              >
                <Checkbox >Save as default billing address</Checkbox>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="is_default_shipping"
                label="Default Shipping"
                valuePropName="checked"
              >
                <Checkbox >Save as default shipping address</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row
            style={{
              width: '100%',
              paddingTop: "32px",
              paddingBottom: '32px',
              gap: "12px",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Form.Item >
              <Button
                style={{
                  color: "#4D4D4D",
                  padding: "12px 32px",
                  display: "flex",
                  alignItems: "center",
                  background: "#DDD",
                  borderRadius: "5px",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "16px",
                }}
                onClick={() => {
                  setArea(null)
                  form.resetFields();
                  setOpenAddressModal(false)
                }
                }
              >
                <span>Cancel</span>
              </Button>
            </Form.Item>
            <Form.Item >
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  color: "#FFF",
                  padding: "12px 33px",
                  display: "flex",
                  alignItems: "center",
                  background: "#F37B7B",
                  borderRadius: "5px",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "16px",
                }}
              >
                {addressAction === "edit" ? "Update" : "Create"}
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default AddNewAddressModal;
