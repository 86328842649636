import React, { useEffect, useState } from "react";
import MyOrder from "./MyOrder";
import MyParcel from "./MyParcel";
import ProductAction from "./ProductAction";
import ProductDetail from "./ProductDetail";
import { Tabs } from "antd";
import {
  clearQuerySearchParamsByKeys,
  getQueryParams,
  insertUrlParams,
} from "utils/helpers";
import { MyOrderIcon, MyParcelIcon } from "../../assets/SvgIcons";
import { SIDENAV_BAR_COLOR } from "../../consts/app-constants";

const { TabPane } = Tabs;

const BuyAndShip = () => {
  const [currentAction, setCurrentAction] = useState("myorder");
  const [productId, setProductId] = useState(null);
  const queryParams = getQueryParams();

  useEffect(() => {
    setCurrentAction("myorder");

    if (queryParams["action"]) {
      setCurrentAction(queryParams["action"]);
    }

    if (queryParams["product_id"]) {
      setProductId(queryParams["product_id"]);
    }
  }, [queryParams]);

  const handlePageAction = (page, product, order) => {
    let clearableParams = [];
    let paramData = [];

    if (page === "invoice" && order) {
      setCurrentAction(page);
      paramData.push({
        key: "order",
        value: order.order_number,
      });
      const redirectUrl = `${window.location.origin}/invoice?order=${order.order_number}`;
      window.location.href = redirectUrl;
    }else if (page === "shipment-invoice" && order) {
      setCurrentAction(page);
      paramData.push({
        key: "order",
        value: order.order_number,
      });
      const redirectUrl = `${window.location.origin}/invoice?action=invoice-overview&invoice-tab=ship-for-me&order=${order.order_number}`;
      window.location.href = redirectUrl;
    } else {
        paramData.push({
          key: "action",
          value: page,
        });
        setCurrentAction(page);

        if (product) {
          paramData.push({
            key: "product_id",
            value: product.id,
          });
          setProductId(product.id);
        } else {
          clearableParams.push("product_id");
        }

        insertUrlParams(paramData);
    }

    if (clearableParams.length > 0) {
      clearQuerySearchParamsByKeys(clearableParams);
    }
  };

  const getMyOrderComponent = () => {
    switch (currentAction) {
      case "product-action":
        return (
          <ProductAction
            handlePageAction={handlePageAction}
            productId={productId}
          />
        );
      case "product-detail":
        return (
          <ProductDetail
            handlePageAction={handlePageAction}
            productId={productId}
          />
        );
      case "buy-invoice":
        return <></>;
      default:
        return <></>;
    }
  };

  return (
    <>
      <Tabs
        type="card"
        activeKey={currentAction}
        onChange={(active_key) => handlePageAction(active_key)}
      >
        <TabPane
          tab={
            <div className="tabs-header">
              <MyOrderIcon color={SIDENAV_BAR_COLOR} />
              <span className="tabs-header-title">My Order</span>
            </div>
          }
          key="myorder"
        >
          <MyOrder handlePageAction={handlePageAction} />
        </TabPane>
        <TabPane
          tab={
            <div className="tabs-header">
              <MyParcelIcon color={SIDENAV_BAR_COLOR} />
              <span className="tabs-header-title"> My Parcel</span>
            </div>
          }
          key="myparcel"
        >
          <MyParcel handlePageAction={handlePageAction} />
        </TabPane>
      </Tabs>
      <>{getMyOrderComponent()}</>
    </>
  );
};

export default BuyAndShip;
