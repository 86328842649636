import {
    Row,
} from "antd";

import React, { useCallback, useEffect, useState } from "react";
import { getDropInformation } from "utils/drop-client";
const INITIAL_STATE = {
    name: "",
    version: "",
    author: "",
    author_homepage: "",
    requires_php: "",
    last_updated: "",
    slug: "",
    download_url: "",
    request_time_elapsed: "",
}
function Tutorial() {
    const [stagingInfo, setStagingInfo] = useState(INITIAL_STATE);
    const [prodInfo, setProdInfo] = useState(INITIAL_STATE);

    const fetchData = useCallback(
        () => {
            getDropInformation(process.env.REACT_APP_DROP_STAGING_SERVER_VERSION_URL)
                .then(res => {
                    if (res && res.data) {
                        setStagingInfo({ ...stagingInfo, ...res.data });
                    }
                }).catch(err => {
                })

            getDropInformation(process.env.REACT_APP_DROP_STAGING_SERVER_VERSION_URL)
                .then(res => {
                    if (res && res.data) {
                        setProdInfo({ ...prodInfo, ...res.data });
                    }
                }).catch(err => {
                })
        },
        [stagingInfo, prodInfo],
    )

    useEffect(() => {
        fetchData();
    }, [fetchData])


    return (
        <>
            <div className="tabled">
                <Row gutter={[12, 3]} justify={"center"}>
                    {/*<Col xs={24} md={24} lg={12} xl={12} xxl={12}>*/}
                    {/*    <Tooltip title="upcoming">*/}
                    {/*        <div*/}
                    {/*            style={{*/}
                    {/*                display: "flex",*/}
                    {/*                flexDirection: "column",*/}
                    {/*                alignItems: "center",*/}
                    {/*                marginTop: "10%"*/}
                    {/*            }}*/}
                    {/*        >*/}

                    {/*            <Button*/}
                    {/*                type="primary"*/}
                    {/*                icon={<DownCircleFilled />}*/}
                    {/*                onClick={() => {*/}
                    {/*                    let url = prodInfo.download_url !== ""*/}
                    {/*                        ? prodInfo.download_url*/}
                    {/*                        : process.env.REACT_APP_DROP_API_SERVER_VERSION_URL + "/?action=download&slug=moveon-drop-wp"*/}
                    {/*                    window.open(url, '_blank');*/}
                    {/*                }}*/}
                    {/*                style={{ cursor: 'not-allowed' }}*/}

                    {/*            >*/}
                    {/*                Download Wordpress Plugin*/}
                    {/*            </Button>*/}
                    {/*        </div>*/}
                    {/*    </Tooltip>*/}
                    {/*</Col>*/}
                    <div ><h1>Upcoming!</h1></div>

                </Row>
            </div>
        </>
    );
}

export default Tutorial;
