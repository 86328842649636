import React, {useState} from "react";

import {
    Row,
    Col,
    Button,
    Input,
    Typography,
    Form,
    Modal,
    message, Select,
} from "antd";

import {
    PlusCircleTwoTone
} from "@ant-design/icons";
import {storeWebsite} from "utils/oauth-client";

const WebsiteAdd = Props => {
    const {show, onHide} = Props
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false)
    const onSubmit = (formData) => {
        setIsLoading(true);
        console.log(formData["type"],"formData")
        if (formData["type"] === "wordpress") {
            formData["redirect"] = formData["redirect"] + "/wp-admin/admin.php?page=moveon-drop-wp";
        }
        delete formData["type"]
        storeWebsite(formData).then((response) => {
                message.success("Website added successfully")
                Props.handleWebsiteAdd();
            }).catch(err => {
                if (err.response) {
                    if (err.response.status === 403) {
                        Modal.error({
                            title: 'Unauthorized',
                            content: 'The action is not authorized'
                        });
                    } else {
                        Modal.error({
                            title: 'Error Occurred',
                            content: err.response.data ? err.response.data.message : "Something went wrong"
                        });
                    }
                } else {
                    Modal.error({
                        title: 'Error Occurred',
                        content: 'Unknown error occurred'
                    });
                }
        }).finally(() => setIsLoading(false))
    }
    const urlValidator = (rule, value, callback, type) => {
        try {
            if (type === "wordpress") {
                let pattern = /(https?:\/\/(?:www\.|(?!www))(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9])/
                let match = value.match(pattern);
                let matched = match && value === match[0];

                if (!matched) {
                    throw new Error("Please enter a valid URL. ie: https://example.com");
                }
            }
            callback();
        } catch (err) {
            callback(err);
        }
    };
    return(
        <Modal
            className="settings-drawer"
            mask={true}
            width={660}
            onCancel={onHide}
            placement={"right"}
            visible={show}
            footer={[
                <Button key="back" onClick={onHide}>
                    Cancel
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    icon={<PlusCircleTwoTone/>}
                    loading={isLoading}
                    onClick={() => form.submit()}
                >
                    Add
                </Button>
            ]}
        >
            <div>
                <div className="header-top">
                    <Typography.Title level={4}>
                        Add a website
                    </Typography.Title>
                </div>
                <Row>
                    <Col span={24}>
                        <Form
                            name="basic"
                            onFinish={onSubmit}
                            className="row-col"
                            form={form}
                            layout="vertical"
                            initialValues={{
                                type: 'wordpress'
                            }}
                        >
                            <Form.Item
                                name="name"
                                label="Website name"
                                rules={[
                                    { required: true, message: "Please enter the website name" },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="type"
                                label="Website type"
                                rules={[
                                    { required: true, message: "Please select the type of your website" },
                                ]}
                            >
                                <Select
                                    options={[
                                        { value: 'wordpress', label: 'Wordpress' },
                                        { value: 'custom', label: 'Custom' }
                                    ]}
                                    onSelect={value => form.setFieldsValue({"type": value})}
                                />
                            </Form.Item>
                            <Form.Item
                                name="redirect"
                                label="Website Url"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter your website url"
                                    },
                                    {
                                        validator: (rule, value, callback) => urlValidator(rule, value, callback, form.getFieldValue('type'))
                                    }
                                ]}
                            >
                                <Input placeholder={"EX: http://example.com"} />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
}

export default WebsiteAdd
