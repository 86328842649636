import React from 'react'
import { Col, Row } from "antd";
import "../products.css"

function ProductSpecification({ product }) {

    return (
        <>
            <div className={product?.specifications.length !== 0 && 'product-specification-container'}>
                {product?.specifications?.length > 0 ? product?.specifications?.map((spcification, index) => (
                    <Row key={index} style={{ width: "100%", borderBottom: "1px solid #E1E1E1", }}>
                        <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6} style={{ background: "#F7F7F7", paddingTop: "24px", paddingBottom: "24px", color: "#000", borderRight: "1px solid #E1E1E1" }}>
                            <p className='product-specification-text'>
                                {spcification?.label?.name}
                            </p>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={16} xxl={16} style={{ paddingTop: "24px", paddingBottom: "24px" }}>
                            <p className='product-specification-text'>
                                {spcification?.value?.name}
                            </p>
                        </Col>
                    </Row>
                )) : <div style={{ paddingTop: "20px", textAlign: "center", fontSize: "22px" }}>
                    Product Specification not found!
                </div>}
            </div>
        </>
    )
}

export default ProductSpecification
