import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Dropdown, Menu, message, Row, Table, Tag } from 'antd'
import { PageHeader } from '@ant-design/pro-layout';
import { AddressDrawer } from './components'
import { deleteAddress, getAddress } from 'utils/customer-client'
import { getQueryParams, insertUrlParams, toastErrors } from "utils/helpers";

const Address = () => {
  const [addresses, setAddresses] = useState({
    data: null,
    meta: null
  })
  const [isLoading, setIsLoading] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [showAddressDrawer, setShowAddressDrawer] = useState(false)
  const [addressAction, setAddressAction] = useState('create')

  const fetchAddresses = useCallback(
    (page, pageSize) => {
      setIsLoading(true)
      getAddress(page, pageSize)
        .then(res => {
          if (res && res.data && res.data.data) {
            setAddresses({
              data: res.data.data,
              meta: res.data.data.meta
            })
          }
        }).catch(({ response }) => {
          if (response?.data) {
            toastErrors(response.data)
          }
        }).finally(x => {
          setIsLoading(false)
        })
    },
    [],
  )

  const handleDelete = id => {
    deleteAddress(id)
      .then(res => {
        fetchAddresses();
        message.success("Address deleted succeffully")
      }).catch(({ response }) => {
        if (response?.data) {
          toastErrors(response.data)
        }
      })
  }


  useEffect(() => {
    let queryParams = getQueryParams();
    let page = queryParams['page_index'] ? parseInt(queryParams['page_index']) : 1;
    let pageSize = queryParams['per_page'] ? parseInt(queryParams['per_page']) : 10;
    fetchAddresses(page, pageSize)
  }, [fetchAddresses])

  const handleEdit = (record) => {
    setSelectedAddress(record);
    setAddressAction('edit');
    setShowAddressDrawer(true);
  }

  const columns = [
    {
      title: 'SL',
      dataIndex: 'sl',
      key: 'sl',
      align: 'center',
      render: (text, record, index) => index + 1
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'District',
      dataIndex: 'district',
      key: 'district',
      render: (_, record) => {
        return record?.district?.name
      }
    },
    {
      title: 'Area',
      dataIndex: 'area',
      key: 'area',
      render: (_, record) => {
        return record?.area?.name
      }
    },
    {
      title: 'Default',
      dataIndex: 'id',
      key: 'id',
      render: (_, record) => {
        return (
          <>
            {
              record.is_default_billing ? <Tag color="#87d068" >Default Billing</Tag> : <></>
            }
            {
              record.is_default_shipping ? <Tag color="#108ee9">Default Shipping</Tag> : <></>
            }
          </>
        )
      }
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      align: 'center',
      render: (text, record) => {
        const menu = (
          <Menu>
            <Menu.Item onClick={() => handleEdit(record)}>Edit</Menu.Item>
            <Menu.Item onClick={() => handleDelete(record.id)}>Delete</Menu.Item>
          </Menu>

        )

        return (
          <Dropdown
            overlay={menu}
            placement="bottomCenter"
          >
            <Button>
              Actions
            </Button>
          </Dropdown>
        )
      },
    },
  ]

  const handlePaginationChange = (page, pageSize) => {
    insertUrlParams([
      {
        key: "page_index",
        value: page
      }
    ]);
    fetchAddresses(page, pageSize)
  }

  const onShowSizeChange = (current, size) => {
    insertUrlParams([
      {
        key: "per_page",
        value: size
      }
    ]);
    fetchAddresses(current, size)
  }

  const paginationConfig = addresses && addresses.meta ? {
    total: addresses.meta.total,
    current: addresses.meta.current_page,
    pageSize: addresses.meta.per_page,
    position: ['none', 'bottomLeft'],
    showSizeChanger: true,
    onChange: handlePaginationChange,
    onShowSizeChange: onShowSizeChange,
  } : false

  return (
    <>
      <Row>
        <Col span={24}>
          <PageHeader
            ghost={false}
            title="Address"
            extra={[
              <Button
                key={1}
                onClick={() => {
                  setShowAddressDrawer(true);
                  setAddressAction('create');
                }}
                type="primary">Add a new address</Button>
            ]}
          >

            <Table
              columns={columns}
              dataSource={addresses.data}
              loading={isLoading}
              style={{ width: '100%' }}
              size="middle"
              pagination={paginationConfig}
              rowKey="id"
              scroll={{
                x: true
              }}
            />
          </PageHeader>
        </Col>
        {
          setShowAddressDrawer &&
          <AddressDrawer
            show={showAddressDrawer}
            onHide={() => setShowAddressDrawer(false)}
            address={selectedAddress}
            drawerAction={addressAction}
            onAddressCreate={() => fetchAddresses()}
          />
        }
      </Row>
    </>
  )
}

export default Address
