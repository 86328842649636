import React, { useState } from 'react'
import { getBalance, getPaymentGateways, handleInvoicePay } from 'utils/payment-client'
import { toastErrors } from 'utils/helpers'

const InvoicePaymentContext = React.createContext()

const InvoicePaymentProvider = (props) => {
  const [invoiceType, setInvoiceType] = useState(null)
  const [selectedInvoices, setSelectedInvoices] = useState([])
  const [gatewayList, setGatewayList] = useState([])
  const [payableAmount, setPayableAmount] = useState(0)
  const [walletBalance, setWalletBalance] = useState(0)
  const [isWalletBalanceFetching, setIsWalletBalanceFetching] = useState(false)
  const [isGatewaysFetching, setIsGatewaysFetching] = useState(false)
  const [isInvoicePaying, setIsInvoicePaying] = useState(false)

  const fetchGatewayList = React.useCallback(
    () => {
      setIsGatewaysFetching(true)
      getPaymentGateways()
        .then(res => {
          if (res && res.data && res.data.data && res.data.data) {
            setGatewayList(res.data.data)
          }
        })
        .catch(({response}) => {
          if (response?.data) {
            toastErrors(response.data)
          }
        }).finally(() => setIsGatewaysFetching(false))
    },[])

  const fetchWalletBalance = React.useCallback(
    () => {
      setIsWalletBalanceFetching(true)
      getBalance()
        .then(res => {
          if (res && res.data) {
            setWalletBalance(res.data)
          }
        }).catch(({response}) => {
          if (response?.data) {
            toastErrors(response.data)
          }
        }).finally(() => {
          setIsWalletBalanceFetching(false)
        })
    },
    [],
  )

  const payInvoice = (data, callback) => {
    setIsInvoicePaying(true)
    handleInvoicePay(data).then(response => {
      callback(response)
    }).catch(({response}) => {
      if (response?.data) {
        toastErrors(response.data)
      }
    }).finally(x => {
      setIsInvoicePaying(false)
    })
  }

  return <InvoicePaymentContext.Provider
    value={{
      fetchGatewayList,
      payInvoice,
      isInvoicePaying,
      payableAmount,
      setPayableAmount,
      isWalletBalanceFetching,
      walletBalance,
      fetchWalletBalance,
      gatewayList,
      isGatewaysFetching,
      invoiceType,
      setInvoiceType,
      selectedInvoices,
      setSelectedInvoices
    }}
    {...props}
  />
}

const useInvoicePayment = () => {
  const context = React.useContext(InvoicePaymentContext)
  if (context === undefined) {
    throw new Error(`useInvoicePayment must be used within a InvoiceProvider`)
  }
  return context
}

export { InvoicePaymentProvider, useInvoicePayment }
