import React,{useState,useEffect} from 'react';
import {Empty, Row, Col, Button, Card, Divider, message, Modal,} from "antd";
import {
  OrderPlaceDelete,
  OrderPlaceEdit,
  OrderPlaceLocationIcon,
  OrderPlacePhoneIcon,
  OrderPlaceUserIcon, ProductDetailsButtonAdd
} from "../../assets/SvgIcons";
import {deleteAddress, getAddress} from "../../utils/customer-client";

function AllAddressesModal({
                             isLoading,
                             setIsLoading,
                             openAllAddressModal,
                             setOpenAddressModal,
                             setOpenAllAddressModal,
                             handleEdit,
                             setAddressAction,
                             selectedAddressType,
                             defaultAddress,
                             setDefaultAddress
                           }) {

  const [messageApi, contextHolder] = message.useMessage();
  const [addresses, setAddresses] = useState([])
  const [selectedAddress, setSelectedAddress] = useState([])

  useEffect(() => {
    const response = getAddress();
    response.then((res) => {
      setAddresses(res?.data?.data)
    })
      .catch(res => {
        messageApi.open({
          type: 'warning',
          content: res?.data,
          style: { marginTop: '50px' }
        });
      })
  }, [isLoading])

  const selectAddress = (id)=> {
    const getAddressById = addresses.filter((address,index)=> {
      if(id === address.id){
        return address
      }
    })
    const [getAddress] = getAddressById;
    if(selectedAddressType === "billing"){
      setDefaultAddress({...defaultAddress, billing: getAddress})
    } else if(selectedAddressType === "shipping") {
      setDefaultAddress({...defaultAddress, shipping: getAddress})
    } else if(selectedAddressType === "all") {
      setDefaultAddress({...defaultAddress, billing: getAddress , shipping: getAddress})
    }
    setSelectedAddress(getAddressById)
  }

  const handleAddressDelete = (event,id) => {
    event.stopPropagation()
    setIsLoading(true)
    deleteAddress(id)
      .then(res => {
        messageApi.open({
          type: 'success',
          content: 'Address deleted successfully!',
          style: { marginTop: '50px' }
        });
        setIsLoading(false)
      }).catch(({ response }) => {
      if (response?.data) {
        messageApi.open({
          type: 'error',
          content: response?.data?.message,
          style: {marginTop: '50px'}
        })
      }
    }).finally(x=> {
        setIsLoading(false)
      }
    )
  }

  return (
    <Modal
      style={{zIndex: 1}}
      width={640}
      closable={true}
      open={openAllAddressModal}
      onCancel={() => {
        setOpenAllAddressModal(false);
        setSelectedAddress([])
      }}
      footer={null}
    >
      {contextHolder}
      <Row style={{paddingTop: '48px'}}>
        <Row style={{width: '100%',display: 'flex',justifyContent: 'space-between'}}>
          <h2>{selectedAddressType === "billing" ? "Billing Addresses" : selectedAddressType === "shipping" ? "Shipping Addresses" : "All Addresses" }</h2>
          {addresses?.length !== 0 && <Button
            style={{
              width: '25%',
              color: "#FFF",
              padding: "12px 32px",
              display: "flex",
              alignItems: "center",
              justifyContent: 'center',
              background: "#F37B7B",
              borderRadius: "5px",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "16px",
            }}
            onClick={()=> {
              setAddressAction('create')
              setOpenAddressModal(true)
            }
            }
          >
            <ProductDetailsButtonAdd />
            <span>Add New </span>
          </Button> }
        </Row>
        <Row gutter={[0,16]} style={{marginTop: '24px',display: 'flex', justifyContent: 'start', width: "100%", maxHeight: '800px',overflowY: 'auto' }}>

          {addresses?.length > 0 ?
            addresses?.map((address,index)=> (
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{cursor: 'pointer'}} onClick={()=>selectAddress(address?.id)}>
                <Card
                  style={{
                    boxShadow: 'none',
                    minHeight: "226px",
                    border: selectedAddress?.[0]?.id === address?.id ? "1px solid #1D3148" : "1px solid #E5E7EB",
                    background: selectedAddress?.[0]?.id === address?.id ? "#F6F7F9": "#FFF"
                  }}
                >
                  <Row gutter={[0, 16]} style={{ display: 'flex', alignItems: 'center' }}>
                    <Col xs={24} sm={18} md={18} lg={18} xl={18} xxl={18} style={{display: 'flex', flexWrap: 'wrap',gap: '16px'}}>
                      {
                        address?.is_default_billing === 1 &&
                        <span style={{ background: "rgba(243, 123, 120, 0.05)", padding: "6px 20px", borderRadius: "16px", border: '1px solid #F37B78', color: "#F37B78", fontSize: "14px" }} className='edit-import-status-text'>Default Billing Address</span>
                      }
                      {
                        address?.is_default_shipping === 1 &&
                        <span style={{ background: "rgba(243, 123, 120, 0.05)", padding: "6px 20px", borderRadius: "16px", border: '1px solid #F37B78', color: "#F37B78", fontSize: "14px" }} className='edit-import-status-text'>Default Shipping Address</span>
                      }
                    </Col>
                    <Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                          <span style={{ cursor: 'pointer' }} onClick={(e)=>handleEdit(e,address)}>
                                    <OrderPlaceEdit />
                          </span>
                      <Divider type="vertical" style={{ height: "15px" }} />
                      <span style={{ cursor: 'pointer' }} onClick={(event) => handleAddressDelete(event,address?.id)}>
                           <OrderPlaceDelete />
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: '22px', display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                      <OrderPlaceUserIcon />
                      <Col style={{ paddingLeft: '8px' }}>
                        <span className='place-order-address-text'>{address?.name}</span>
                      </Col>
                    </Row>
                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                      <OrderPlacePhoneIcon />
                      <Col style={{ paddingLeft: '8px' }}>
                        <span className='place-order-address-text'>{address?.phone}</span>
                      </Col>
                    </Row>
                    <Row style={{ display: 'flex' }}>
                         <span style={{ marginTop: '4px' }}>
                          <OrderPlaceLocationIcon />
                         </span>
                      <Col style={{ paddingLeft: '8px' }}>
                        <span className='place-order-address-text'>{address?.address}</span>
                      </Col>
                    </Row>
                  </Row>
                </Card>
              </Col>
            ))
            : <Row style={{width: '100%',display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px',justifyContent: 'center',paddingBottom: "50px"}}>
              <Empty />
              <Button
                style={{
                  width: '25%',
                  color: "#FFF",
                  padding: "12px 32px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: 'center',
                  background: "#F37B7B",
                  borderRadius: "5px",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "16px",
                }}
                onClick={()=> {
                  setAddressAction('create')
                  setOpenAddressModal(true)
                }}
              >
                <ProductDetailsButtonAdd />
                <span>Add New </span>
              </Button>
            </Row>
          }
        </Row>
        {addresses?.length > 0 &&   <Row
          style={{
            width: '100%',
            paddingTop: "32px",
            paddingBottom: '32px',
            gap: "12px",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            style={{
              color: "#4D4D4D",
              padding: "12px 32px",
              display: "flex",
              alignItems: "center",
              background: "#DDD",
              borderRadius: "5px",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "16px",
            }}
            onClick={() => {
              setOpenAllAddressModal(false);
              setSelectedAddress([])
            }}
          >
            <span>Cancel</span>
          </Button>
        </Row> }
      </Row>
    </Modal>
  );
}

export default AllAddressesModal;
