import React from 'react'
import {Col, Descriptions, Modal, Row} from 'antd'

const AddressModal = Props => {
    const {show, onHide, addresses} = Props;
    return (
        <Modal
            title="Addresses"
            visible={show}
            onCancel={onHide}
            width={800}
            footer={null}
        >
            {
                addresses &&
                (
                    <Row>
                        {
                            addresses.billing &&
                            (
                                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                    <Descriptions title="Billing" column={2}>
                                        <Descriptions.Item label={<span
                                            className="font-weight-700">Name</span>}>{addresses?.billing?.name}</Descriptions.Item>
                                        <Descriptions.Item label={<span
                                            className="font-weight-700">Phone</span>}>{addresses?.billing?.phone}</Descriptions.Item>
                                        <Descriptions.Item label={<span
                                            className="font-weight-700">District</span>}>{addresses?.billing?.district?.name}</Descriptions.Item>
                                        <Descriptions.Item label={<span
                                            className="font-weight-700">Area</span>}>{addresses?.billing?.area?.name}</Descriptions.Item>
                                        <Descriptions.Item label={<span
                                            className="font-weight-700">Postal</span>}>{addresses?.billing?.postal_code}</Descriptions.Item>
                                        <Descriptions.Item label={<span
                                            className="font-weight-700">Address</span>}>{addresses?.billing?.address}</Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            )
                        }
                        {
                            addresses.shipping &&
                            (
                                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                    <Descriptions title="Shipping" column={2}>
                                        <Descriptions.Item label={<span
                                            className="font-weight-700">Name</span>}>{addresses?.shipping?.name}</Descriptions.Item>
                                        <Descriptions.Item label={<span
                                            className="font-weight-700">Phone</span>}>{addresses?.shipping?.phone}</Descriptions.Item>
                                        <Descriptions.Item label={<span
                                            className="font-weight-700">District</span>}>{addresses?.shipping?.district?.name}</Descriptions.Item>
                                        <Descriptions.Item label={<span
                                            className="font-weight-700">Area</span>}>{addresses?.shipping?.area?.name}</Descriptions.Item>
                                        <Descriptions.Item label={<span
                                            className="font-weight-700">Postal</span>}>{addresses?.shipping?.postal_code}</Descriptions.Item>
                                        <Descriptions.Item label={<span
                                            className="font-weight-700">Address</span>}>{addresses?.shipping?.address}</Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            )
                        }
                    </Row>
                )
            }
        </Modal>
    );
}

export default AddressModal