import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Input, Select, Pagination, Button, message } from "antd";
import TableData from "./TableData";
import { customArrow } from "../Custom";
import { showSavedProduct, deleteSavedProduct } from "utils/product-client";
import "./savedproducts.css";

function SavedProducts() {

    const [savedProductsData, setSavedProductsData] = useState([]);
    const [messageApi, contextHolder] = message.useMessage();
    const [product, setProduct] = useState([]);
    const [input, setInput] = useState({
        page: null
    })

    const [selected, setSelected] = useState({
        data: [],
        rows: 0
    })
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const showSavedProductList = () => {
            const response = showSavedProduct(input)
            response.then(res => {
                setProduct(res?.data)
                const mappedSavedProducts = res?.data?.data?.map((row, index) => {
                  const tags = row?.import_tags?.map((tag)=> `${tag.website_name} - ${tag.status}`)
                   return {
                  id: row.product?.id,
                  savedProductId:row.id,
                  key: index,
                  Title: row.product?.title,
                  Image: row.product?.image,
                  ProductPrice: row.product?.price?.wholesales?.length > 0 ? row.product?.price?.wholesales?.[0]?.original : row.product?.price?.original?.max,
                  YourPrice: "Not set Yet",
                  Source: row.product?.meta.vendor,
                  Tag: tags,
                  storeId: row.product?.store?.storeId,
                  totalFx: row.product?.store?.totalFx,
                  updated: row.product?.updated_at,
                }})
              setSavedProductsData(mappedSavedProducts)
            }).catch(({ response }) => {
                if (response?.data) {
                    messageApi.open({
                        type: 'error',
                        content: response?.data?.message,
                        style: {marginTop: '50px'}
                    })
                }
            })
        }
        showSavedProductList()
    }, [isLoading])

    const handlePaginatedData = (pageNumber) => {
        setInput({ ...input, page: pageNumber })
    }

    useEffect(() => {
        if (input.page) {
            const response = showSavedProduct(input)
        }
    }, [input.page])

    const deleteProduct = (id) => {
        setIsLoading(true)
        setTimeout(() => {
            const response = deleteSavedProduct(id);
            response.then(res => {
                if (res.status === 200) {
                    messageApi.open({
                        type: 'success',
                        content: 'Product deleted successfully!',
                        style: { marginTop: '50px' }
                    });
                }
                setIsLoading(false)
            }).catch(({ response }) => {
                    if (response?.data) {
                        messageApi.open({
                            type: 'error',
                            content: response?.data?.message,
                            style: {marginTop: '50px'}
                        })
                    }
                })
                .finally(x => setIsLoading(false))
        }, 1500)
    }

    return (
        <Layout
            style={{
                margin: "0 auto",
                height: "screen",
                paddingTop: "42px",
                background: "#FFF",
                borderRadius: '8px',
                boxShadow: '0px 4px 4px 0px rgba(29, 49, 72, 0.08)'
            }}
        >
            {contextHolder}
            <Row style={{ width: "95%", margin: "0 auto" }}>
                <Col className="saved-product-header">Product List</Col>
                <Col
                    className="saved-product-header"
                    style={{ color: "#1D3148", paddingLeft: "32px" }}
                >
                    Saved Products
                </Col>
            </Row>
            <div
                style={{
                    border: "1px solid #E5E7EB",
                    padding: "24px 24px",
                    width: "95%",
                    margin: "24px auto 0px",
                    borderRadius: "8px",
                    background: "#FFF",
                }}
            >
                <Row gutter={[16, 16]}>
                    <Col span={6} xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
                        <label className="saved-product-input-label">Product Title</label>
                        <Input
                            className="saved-product-input"
                            placeholder="Enter product title"
                            style={{ height: '40px' }}
                        />
                    </Col>
                    <Col span={6} xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
                        <label className="saved-product-input-label">Source From</label>
                        <Select
                            defaultValue="China"
                            style={{ width: "100%", marginTop: "12px", height: '40px' }}
                            options={[
                                { value: "china", label: "China" },
                                { value: "dubai", label: "Dubai" },
                                { value: "india", label: "India" },
                                { value: "usa", label: "USA" },
                            ]}
                        />
                    </Col>
                    <Col span={6} xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
                        <label className="saved-product-input-label">Import Tag</label>
                        <Select
                            defaultValue="All"
                            style={{ width: "100%", marginTop: "12px", height: '40px' }}
                            options={[
                                { value: "jack", label: "Jack" },
                                { value: "lucy", label: "Lucy" },
                                { value: "Yiminghe", label: "yiminghe" },
                                { value: "disabled", label: "Disabled", disabled: true },
                            ]}
                        />
                    </Col>
                </Row>
                <Row
                    gutter={[16, 16]}
                    style={{
                        marginTop: "24px",
                    }}
                >
                    <Col>
                        <Button className="saved-product-cta1">Search</Button>
                    </Col>
                    <Col>
                        <Button className="saved-product-cta2">Clear</Button>
                    </Col>
                </Row>
            </div>
            <Layout
                style={{
                    border: "1px solid #E5E7EB",
                    padding: "24px 24px",
                    width: "95%",
                    margin: "24px auto 0px",
                    borderRadius: "8px",
                    background: "#FFF",
                }}
            >
                <Row gutter={[0, 12]}>
                    <Col
                        xs={12}
                        sm={6}
                        md={6}
                        lg={5}
                        xl={4}
                        xxl={4}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            border: "1px solid #E5E7EB",
                            padding: '8px 0px'
                        }}
                    >
                        <span className="saved-product-table-cta">{selected.rows} products selected</span>
                    </Col>
                    {/*<Tooltip title={"upcoming"}>*/}
                    {/*    <Col*/}
                    {/*        xs={12}*/}
                    {/*        sm={6}*/}
                    {/*        md={6}*/}
                    {/*        lg={5}*/}
                    {/*        xl={4}*/}
                    {/*        xxl={4}*/}
                    {/*        style={{*/}
                    {/*            display: "flex",*/}
                    {/*            alignItems: "center",*/}
                    {/*            border: "1px solid #E5E7EB",*/}
                    {/*            padding: '8px 0px',*/}
                    {/*            cursor: "not-allowed"*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <span className="saved-product-table-cta">Push to my store</span>*/}
                    {/*    </Col>*/}
                    {/*</Tooltip>*/}
                    {/*<Tooltip title={"upcoming"}>*/}
                    {/*    <Col*/}
                    {/*        xs={12}*/}
                    {/*        sm={6}*/}
                    {/*        md={6}*/}
                    {/*        lg={6}*/}
                    {/*        xl={6}*/}
                    {/*        xxl={5}*/}
                    {/*        style={{*/}
                    {/*            display: "flex",*/}
                    {/*            alignItems: "center",*/}
                    {/*            border: "1px solid #E5E7EB",*/}
                    {/*            padding: '8px 0px',*/}
                    {/*            cursor: "not-allowed"*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <span className="saved-product-table-cta">*/}
                    {/*            Get latest product data*/}
                    {/*        </span>*/}
                    {/*    </Col>*/}
                    {/*</Tooltip>*/}
                    {/*<Tooltip title={"upcoming"}>*/}
                    {/*    <Col*/}
                    {/*        xs={12}*/}
                    {/*        sm={6}*/}
                    {/*        md={5}*/}
                    {/*        lg={7}*/}
                    {/*        xl={8}*/}
                    {/*        xxl={6}*/}
                    {/*        style={{*/}
                    {/*            display: "flex",*/}
                    {/*            alignItems: "center",*/}
                    {/*            border: "1px solid #E5E7EB",*/}
                    {/*            padding: '8px 0px',*/}
                    {/*            cursor: "not-allowed"*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <span className="saved-product-table-cta">*/}
                    {/*            Delete selected products(s)*/}
                    {/*        </span>*/}
                    {/*    </Col>*/}
                    {/*</Tooltip>*/}
                </Row>
                <Row style={{ paddingTop: "16px" }}>
                  <TableData savedProductsData={savedProductsData} isLoading={isLoading} setIsLoading={setIsLoading} selected={selected} setSelected={setSelected} product={product} deleteProduct={deleteProduct} />
                </Row>
            </Layout>
            <Row
                style={{ marginTop: "30px", display: "flex", justifyContent: "center" }}
            >
                <Pagination
                    onChange={handlePaginatedData}
                    total={product?.meta?.total}
                    itemRender={customArrow}
                    defaultCurrent={1}
                    style={{ paddingBottom: "40px" }}
                />
            </Row>
        </Layout>
    );
}

export default SavedProducts;
