import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Skeleton, Statistic, Tabs } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import RechargeRequest from "./RechargeRequest";
import Transactions from "./Transactions";
import { useInvoicePayment } from "context/invoice-payment-context";
import { WalletRechargeModal } from "components";
import WalletRecharge from "./WalletRecharge";
import { getQueryParams, insertUrlParams } from "utils/helpers";
import { Dashboard } from "../../assets/SvgIcons";
import { SIDENAV_BAR_COLOR } from "../../consts/app-constants";
import "./Wallet.css";
const { TabPane } = Tabs;
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Wallet = () => {
  const [currentAction, setCurrentAction] = useState("wallet-overview");
  const queryParams = getQueryParams();

  useEffect(() => {
    if (queryParams["action"]) {
      setCurrentAction(queryParams["action"]);
    }
  }, [queryParams]);

  const handlePageAction = (page) => {
    let paramData = [
      {
        key: "action",
        value: page,
      },
    ];
    setCurrentAction(page);
    insertUrlParams(paramData);
  };

  const getComponents = () => {
    switch (currentAction) {
      case "wallet-recharge":
        return <WalletRecharge handlePageAction={handlePageAction} />;
      case "wallet-overview":
        return <WalletOverview handlePageAction={handlePageAction} />;
      default:
        return <></>;
    }
  };
  return getComponents();
};

const WalletOverview = (Props) => {
  const { handlePageAction } = Props;
  const currentMonth = new Date().getMonth();
  const { walletBalance, isWalletBalanceFetching, fetchWalletBalance } =
    useInvoicePayment();
  const [showRequestCreate, setShowRequestCreate] = useState(false);
  const [currentTab, setCurrentTab] = useState("transactions");

  useEffect(() => {
    fetchWalletBalance();
  }, [fetchWalletBalance]);

  return (
    <>
      <PageHeader
        ghost={false}
        title="Wallet"
        extra={[
          <Button
            key={1}
            onClick={() => {
              setShowRequestCreate(true);
            }}
            type="primary"
          >
            New recharge request
          </Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <div className="wallet-overview">
              <div className="balance-wrap">
                <Skeleton active loading={isWalletBalanceFetching}>
                  <Row gutter={[12, 24]}>
                    <Col xs={24} md={12} lg={12} xl={6} xxl={6}>
                      <Card className="card">
                        <Statistic
                          title="Account Balance (BDT)"
                          value={walletBalance ? walletBalance.current : 0}
                          precision={2}
                        />
                      </Card>
                    </Col>
                    <Col xs={24} md={12} lg={12} xl={6} xxl={6}>
                      <Card className="card">
                        <Statistic
                          title="Hold Balance (BDT)"
                          value={walletBalance ? walletBalance.hold_balance : 0}
                          precision={2}
                        />
                      </Card>
                    </Col>
                    <Col xs={24} md={12} lg={12} xl={6} xxl={6}>
                      <Card className="card">
                        <Statistic
                          title={
                            "In Balance (" + monthNames[currentMonth] + ")"
                          }
                          value={walletBalance ? walletBalance.in_balance : 0}
                          precision={2}
                          valueStyle={{ color: "#3f8600" }}
                        />
                      </Card>
                    </Col>
                    <Col xs={24} md={12} lg={12} xl={6} xxl={6}>
                      <Card className="card">
                        <Statistic
                          title={
                            "Out Balance (" + monthNames[currentMonth] + ")"
                          }
                          value={walletBalance ? walletBalance.out_balance : 0}
                          precision={2}
                          valueStyle={{ color: "#cf1322" }}
                        />
                      </Card>
                    </Col>
                  </Row>
                </Skeleton>
              </div>
            </div>
          </Col>
        </Row>
      </PageHeader>
      <Tabs
        type="card"
        activeKey={currentTab}
        onChange={(active_key) => setCurrentTab(active_key)}
      >
        <TabPane
          tab={
            <div className="tabs-header">
              <Dashboard color={SIDENAV_BAR_COLOR} />
              <span className="tabs-header-title">Transactions</span>
            </div>
          }
          key="transactions"
        >
          <Transactions />
        </TabPane>
        <TabPane
          tab={
            <div className="tabs-header">
              <Dashboard color={SIDENAV_BAR_COLOR} />
              <span className="tabs-header-title">Recharge requests</span>
            </div>
          }
          key="recharge-requests"
        >
          <RechargeRequest />
        </TabPane>
      </Tabs>
      {showRequestCreate && (
        <WalletRechargeModal
          show={showRequestCreate}
          onHide={() => setShowRequestCreate(false)}
          handlePageAction={handlePageAction}
        />
      )}
    </>
  );
};
export default Wallet;
