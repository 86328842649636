import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Spin, Alert } from 'antd';
import {LOCAL_STORAGE_KEY, LOCAL_STORAGE_USER} from 'consts/storage'
import {actingLogin} from "utils/auth-client";
import { toastErrors } from "utils/helpers";

const ActingHandle = (Props) => {
    const {token} = useParams();
    const [isLoading, setIsLoading] = useState(false)

    useEffect(()=>{
        setIsLoading(true)
        actingLogin(token).then(res => {
            setIsLoading(false);
            window.localStorage.setItem(LOCAL_STORAGE_KEY, res.data.token)
            window.localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(res.data.user))
            window.location.href = "/";
        }).catch(({response}) => {
            if (response?.data) {
              toastErrors(response.data);
            }
        }).finally(() => setIsLoading(false));
    },[token]);

    return(
        <>
            {
                isLoading ?
                    <Spin tip="Loading..."/>
                    :
                    <Alert
                        message="Advice for you"
                        description="Never give up... Because great things take time"
                        type="info"
                    />
            }
        </>
    );
}
export default ActingHandle;