import client from 'utils/api-client'

export const getWebsites = () => {
    return client.get('/oauth/clients');
}

export const storeWebsite = data => {
    return client.post('/oauth/clients', data);
}

export const updateWebsite = (id, data) => {
    return client.put(`/oauth/clients/${id}`, data);
}

export const deleteWebsite = id => {
    return client.delete(`/oauth/clients/${id}`);
}