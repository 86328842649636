export const ORDER_STATUS_PENDING = 'pending'
export const ORDER_STATUS_ON_HOLD = 'on-hold'
export const ORDER_STATUS_FAILED = 'failed'
export const ORDER_STATUS_PROCESSING = 'processing'
export const ORDER_STATUS_COMPLETED = 'completed'
export const ORDER_STATUS_REFUNDED = 'refunded'
export const ORDER_STATUS_CANCELLED = 'cancelled'


export const getDeliveryStatuses = () => {
    return [
        {
            key: "delivery-pending",
            name: "Request Created",
            index: 0,
        },
        {
            key: "delivery-processing",
            name: "Processing",
            index: 1,
        },
        {
            key: "delivery-ready",
            name: "Ready for delivery",
            index: 2,
        },
        {
            key: "delivery-shipped",
            name: "Handover To Courier",
            index: 3,
        },
        {
            key: "delivered-to-customer",
            name: "Delivered",
            index: 4
        },

    ];
}