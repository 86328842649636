import React, { useEffect, useState } from 'react'
import DateTime from "components/DateTime"
import { Button, Col, Form, Row, Select, Table } from 'antd'
import { getRechargeRequests } from "utils/payment-client"
import { StatusTag } from "components";
import { insertUrlParams, toastErrors } from "utils/helpers";
import { useInvoicePayment } from "context/invoice-payment-context";

const { Column } = Table
const INITIAL_FILTERS = {
  gateway: undefined
}

const RechargeRequest = () => {
  const [form] = Form.useForm()
  const [response, setResponse] = useState({ data: [], meta: null })
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState(INITIAL_FILTERS)
  const [isServiceGetCalled, setIsServiceGetCalled] = useState(false)

  const {
    fetchGatewayList,
    gatewayList
  } = useInvoicePayment()

  const fetchItems = React.useCallback(
    (page, filter, perPage) => {
      setIsLoading(true)
      getRechargeRequests(page, filter, perPage)
        .then(res => {
          setResponse(res.data)
        })
        .catch(({response}) => {
          if (response?.data) {
            toastErrors(response.data);
          }
        }).finally(() => {
          setIsLoading(false)
        })
    },
    [],
  )

  useEffect(() => {
    if (!isServiceGetCalled) {
      fetchItems();
      fetchGatewayList();
      setIsServiceGetCalled(true)
    }
  }, [fetchGatewayList, isServiceGetCalled, fetchItems])

  const handleFilterChange = (field, value) => {
    let fieldValue = !value ? undefined : value
    if (field === 'date' && value[0] === '') {
      fieldValue = undefined
    }
    setFilters({
      ...filters,
      [field]: fieldValue
    })
  }

  const handleClear = () => {
    form.resetFields()
    setFilters(INITIAL_FILTERS)
  }

  const handleFilterSubmit = () => {
    let validFilters = []
    for (let [key, value] of Object.entries(filters)) {
      if (value !== undefined) {
        validFilters.push({ key: key, value: value })
      }
    }
    insertUrlParams(validFilters)
    fetchItems(1, filters)
  }

  const handlePageChange = (page, pageSize) => {
    fetchItems(page, null, pageSize)
  }

  const handleShowSizeChange = (current, size) => {
    fetchItems(current, null, size)
  }

  const paginationConfig = response && response.meta ? {
    total: response.meta.total,
    current: response.meta.current_page,
    onChange: handlePageChange,
    onShowSizeChange: handleShowSizeChange,
    pageSize: response.meta.per_page,
    showSizeChanger: true,
    position: ['none', 'bottomLeft']
  } : false

  return (
    <Row>
      <Col span={24}>
        <Form
          form={form}
          labelCol={{ span: 12 }}
          layout="vertical"
          onFinish={handleFilterSubmit}
        >
          <Row gutter={24}>
            <Col md={12} lg={12} xl={8} xxl={6}>
              <Form.Item label="Gateway" name="gateway">
                <Select
                  placeholder="Please select gateway"
                  onChange={(value) => handleFilterChange('gateway', value)}
                >
                  {
                    gatewayList && gatewayList.map(d => <Select.Option value={d.slug}
                      key={d.id}>{d.name}</Select.Option>)
                  }
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row className="custom_margin_left">
            <Col span={24}>
              <Form.Item>
                <Button type="primary" htmlType="submit">Search</Button>
                <Button onClick={handleClear} style={{ marginLeft: '15px' }}>Clear</Button>
                <Button
                  onClick={() => fetchItems()}
                  type="primary"
                  danger
                  loading={isLoading}
                  style={{
                    float: "right",
                    marginRight: "3%"
                  }}
                >Reload</Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          dataSource={response.data}
          loading={isLoading}
          pagination={paginationConfig}
          rowKey="id"
          scroll={{
            x: true
          }}
        >
          <Column
            title="#SL"
            dataIndex="key"
            key="SL"
            align={"center"}
            render={(text, record, index) => response.meta.from + index}
          />
          <Column
            title="Request"
            dataIndex="request_number"
            key="request_number"
            render={request_number => (
              `#${request_number}`
            )}
          />
          <Column
            title="Gateway"
            dataIndex="gateway"
            key="gateway"
            render={gateway => (
              gateway ? gateway.slug : ""
            )}
          />
          <Column
            title="Requested amount"
            dataIndex="requested_amount"
            key="requested_amount"
            align={"center"}
            render={requested_amount => (
              `BDT ${requested_amount}`
            )}
          />
          <Column
            title="Stored amount"
            dataIndex="store_amount"
            key="store_amount"
            align={"center"}
            render={store_amount => (
              `BDT ${store_amount}`
            )}
          />
          <Column
            title="Reference"
            dataIndex="reference"
            key="reference"
          />
          <Column
            title="Status"
            dataIndex="status"
            key="status"
            align={'center'}
            style={{ width: 200 }}
            render={(status, record) => (
              <StatusTag slug={status} text={status} />
            )}
          />
          <Column
            title="Created time"
            dataIndex="created_at"
            key="created_at"
            align={"center"}
            render={created_at => (
              <DateTime date={created_at} />
            )}
          />

        </Table>
      </Col>
    </Row>
  );
}
export default RechargeRequest
