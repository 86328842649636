import client from './api-client'
import Qs from 'qs'

export const getBuyOrders = (page = null, filter = null, perPage = 10) => {
    let data = {
        page: page ? page : 1,
        per_page: perPage
    }

    if (filter) {
        data = {...data, ...filter}
    }
    return client.get('/customer/buy-ship/orders/multiple', {
        params: data,
        paramsSerializer: function (params) {
            return Qs.stringify(params, {arrayFormat: 'brackets'})
        },
    })
}

export const getBuyShipProductDetails = (productNumber) => {
    return client.get(`/customer/buy-ship/products/${productNumber}`)
}

export const getCorrelatedOrdersData = (correlatedItemId) => {
    return client.get(`/customer/buy-ship/products/${correlatedItemId}/correlated-orders`)
}

export const takeBuyProductAction = (productId, data) => {
    return client.post(`/customer/buy-ship/products/${productId}/action`, data)
}

export const getInvoicesForBuyAndShip = (page = null, filter = null, perPage = 10) => {
    let data = {
        page: page ? page : 1,
        per_page: perPage
    }

    if (filter) {
        data = {...data, ...filter}
    }

    return client.get('/customer/buy-ship/invoices', {
        params: data,
        paramsSerializer: function (params) {
            return Qs.stringify(params, {arrayFormat: 'brackets'})
        },
    })
}

export const getBuyInvoiceDetail = (invoiceId) => {
    return client.get(`/customer/buy-ship/invoices/${invoiceId}`)
}

// Delivery Requst
export const createDeliveryRequest = (data) => {
    return client.post(`/customer/delivery/request/create`, data)
}

export const getCouriers = () => {
    return client.get(`/customer/delivery/couriers`)
}

export const getRequestedDelivery = (page = null, filter = null, perPage = 10) => {
    let data = {
        page: page ? page : 1,
        per_page: perPage
    }

    if (filter) {
        data = {...data, ...filter}
    }
    return client.get('/customer/delivery-requests/buy-and-ship', {
        params: data,
        paramsSerializer: function (params) {
            return Qs.stringify(params, {arrayFormat: 'brackets'})
        },
    })
}

export const getRequestedDeliveryProducts = (requestId) => {
    return client.get(`/customer/delivery/request/${requestId}/products`)
}

export const getRequestedDeliveryLogs = (requestId) => {
    return client.get(`/customer/delivery/request/${requestId}/status-logs`)
}

export const checkRefundAvailability = (data) => {
  return client.post('/customer/refund/dispute/check-availability', data)
}

export const createRefundRequest= (data) => {
  return client.post('/customer/refund/request', data)
}

export const getInvoiceDownloadFile = (params, invoiceID) => {
  let data = {
    type: params,
  }
  return client.get(`/customer/invoices/${invoiceID}/pdf`, {
    params: data,
    paramsSerializer: function (params) {
      return Qs.stringify(params, {arrayFormat: 'brackets'})
    },
  })
}