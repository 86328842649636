import { Layout } from "antd";
import logo from "../../assets/logo.png";
import notifications from "../../assets/images/products/notifications.png"
import avatar from "../../assets/images/products/avatar.png"
import { NavLink } from "react-router-dom";
import { TopBarDropdown, TopBarNotificationBG } from "assets/SvgIcons";
import { LOCAL_STORAGE_USER } from "consts/storage";

function TopBar() {
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER))
    return (
        <Layout style={{ position: "fixed", zIndex: 100000, display: "flex", justifyContent: "center", width: "100%", height: "80px", background: "#FFF", boxShadow: '0px 2px 4px 0px rgba(29, 49, 72, 0.05)' }}>
            <div className="topbar-wrapper">
                <div >
                    <NavLink to="/">
                        <img src={logo} alt="" style={{
                            width: '154px',
                        }} />
                    </NavLink>
                </div>
                <div style={{ position: "relative", display: "flex", alignItems: "center", justifyContent: "space-between", width: "200px" }}>
                    <div>
                        <img src={notifications} alt="" style={{ marginLeft: "2px" }} />
                    </div>
                    <div>
                        <img src={avatar} alt="" />
                    </div>
                    <span style={{ whiteSpace: "nowrap" }}>{user.name}</span>
                    <span>
                        <TopBarDropdown />
                    </span>
                    <span style={{ position: "absolute", top: 3, left: 10, fontSize: "10px", fontStyle: "normal", fontWeight: "400", lineHeight: "20px" }}>
                        <TopBarNotificationBG />
                    </span>
                    <span style={{ position: "absolute", top: 2, left: 16, color: "#FFF" }}>6</span>
                </div>
            </div>

        </Layout>
    )

}
export default TopBar;
