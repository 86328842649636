import React, {useState, useEffect} from "react";

import {
  Form,
  Input,
  InputNumber,
  Button,
  Radio,
  Select,
  Skeleton,
  Divider,
  message,
  Checkbox, Modal, Image, Alert
} from 'antd';

import {useHistory} from "react-router-dom";
import {MultipleImageUpload, StatusTag} from "components";
import './RefundForm.css'
import {MetaEdit} from "../components";
import {checkRefundAvailability, createRefundRequest} from "utils/buy-order-client";
import {updateSize} from "../../../../utils/helpers";

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 12},
    md: {span: 10}
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 12},
    md: {span: 14}
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
const RefundTypes = [
  {
    label: "Refund only",
    value: "refund-only"
  },
  {
    label: "Cancel Product",
    value: "cancel"
  }
];
const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};
const RefundReasons = [
  {
    label: "Product damaged or Broken",
    value: "Product damaged or Broken"
  },
  {
    label: "Color or Size Mismatch",
    value: "Color or Size Mismatch"
  },
  {
    label: "Quantity Shortage",
    value: "Quantity Shortage"
  },
  {
    label: "Wrong Item",
    value: "Wrong Item"
  }
]

const RefundForm = (props) => {
  const history = useHistory();
  const {selectedProductItemData, isLoading, show, onHide} = props;
  const [images, setImages] = useState(null);
  const [form] = Form.useForm();
  const [refundOptions, setRefundOptions] = useState([]);
  const [updatedPrice, setUpdatedPrice] = useState([]);
  const [selectedVariationKeys, setSelectedVariationKeys] = useState([]);
  const [submitBtnEnabled, setSubmitBtnEnabled] = useState(false);


  useEffect(() => {
    if (selectedProductItemData) {
      let data = {
        product_type: 'buy-and-ship',
        product_id: selectedProductItemData?.id
      }
      checkRefundAvailability(data).then(response => {
        let data = response.data;
        if (("dispute_type" in data)) {
          let options = RefundTypes.map(type => {
            return {
              label: type.label,
              value: type.value,
              disabled: !data['dispute_type'][type.value]
            }
          });
          setRefundOptions(options);
        }
        if (("duplicate_dispute" in data) && (data.duplicate_dispute === true)) {
          setSubmitBtnEnabled(true);
        }
      }).catch(err => {
        if (err.response) {
          if (err.response.status === 403) {
            message.error("The action is not authorized");
          } else {
            message.error(err.response.data.message ? err.response.data.message : "Unknown error");
          }
        } else {
          message.error("Unknown error");
        }
      })

      if (selectedProductItemData?.itemMetas) {
        let total = 0;
        let fields = {'amount': total};
        selectedProductItemData?.itemMetas.forEach((meta) => {
          fields[`metas_${meta.id}_qty`] = meta.qty;
          updatedPrice.push({id: meta.id, qty: meta.qty, price: meta.productPrice})
        })
        form.setFieldsValue(fields)
      }
    }

  }, [selectedProductItemData, form, updatedPrice])


  const handleSubmit = (data) => {
    const payload = {
      'product_type': 'buy-and-ship',
      'product_id': selectedProductItemData?.id,
      'refund_type': data.type,
      'item_received': data.item_received,
      'reason': data.reason,
      'amount': data.amount,
      'detail': data.detail,
      'images': images,
      'videos': null
    }

    if (selectedVariationKeys.length > 0) {
      var metas = [];
      selectedVariationKeys.forEach(key => {
        metas.push({
          id: key,
          qty: data[`metas_${key}_qty`],
        })
      })
      payload.metas = metas;
    }

    createRefundRequest(payload).then(() => {
      message.success("Refund request created successfully");
      onHide()
      history.push("/order");
    }).catch(err => {
      if (err.response) {
        if (err.response.status === 403) {
          message.error("The action is not authorized");
        } else {
          message.error(err.response.data.message ? err.response.data.message : "Unknown error");
        }
      } else {
        message.error("Unknown error");
      }
    })
  }

  const onRowSelectChange = (keys) => {
    setSelectedVariationKeys(keys)

    let total = 0;
    updatedPrice.forEach(x => {
      if (keys.indexOf(x.id) !== -1) {
        total += x.price;
      }
    })
    form.setFieldsValue({'amount': total.toFixed(2)});
  }

  const rowSelection = {
    selectedRowKeys: selectedVariationKeys,
    onChange: onRowSelectChange,
  }

  const totalPriceBdt = selectedProductItemData?.itemMetas?.reduce((acc, value) => {
    if (value.productPrice === '') {
      value = 0
    } else {
      value = parseFloat(value.productPrice * value.qty)
    }
    return acc + (value)
  }, 0)

  let totalCharges = 0
  if (selectedProductItemData?.charges) {
    totalCharges = selectedProductItemData?.charges.reduce((acc, value) => {
      return acc + (value.amount)
    }, 0)
  }
  let totalAmountWithCharge = totalPriceBdt ? ((totalCharges ? totalCharges : 0) + totalPriceBdt).toFixed(2) : 0

  const handleImageUpload = (files) => {
    let images = files.map(file => file.response?.imageURL);
    setImages(images);

    form.setFieldsValue({
      evidence: images,
    });

  }

  return (
    <Modal
      title="Orders"
      visible={show}
      onCancel={onHide}
      width={800}
      footer={null}
    >{
      !selectedProductItemData ?
        <Skeleton
          loading={isLoading}
          paragraph={50}
        />
        :
        <>
          <div style={{display: "flex",}}>
            <div className={"product-image-wrapper"}>
              <Image
                shape="square"
                width={100}
                src={updateSize(selectedProductItemData?.image, 100)}
              />
            </div>
            <div className="product-info-wrapper">
              <div className={"title"}><span
                className="font-weight-bold">Title: &nbsp;</span>{selectedProductItemData.title}</div>
              <div className={"product_number"}><span
                className="font-weight-bold">Product Number: &nbsp;</span>{selectedProductItemData.product_number}</div>
              {
                selectedProductItemData?.weight > 0 &&
                <div className={"weight"}><span
                  className="font-weight-bold">Weight: &nbsp;</span>{selectedProductItemData.weight}</div>
              }
              {
                selectedProductItemData?.quantity > 0 &&
                <div className={"quantity"}><span
                  className="font-weight-bold">Quantity: &nbsp;</span>{selectedProductItemData.quantity}</div>
              }
              <div className={"amount"}><span
                className="font-weight-bold">Total &nbsp;</span>:&nbsp;{totalPriceBdt.toFixed(2)}</div>
              <div className={"status"}><span className="font-weight-bold">Status &nbsp;</span><StatusTag
                text={selectedProductItemData.status} slug={selectedProductItemData?.status}/></div>
            </div>

          </div>
          <Alert
            style={{marginTop:"10px"}}
            message="Refund Policy"
            description="Refund requests must be made within 7 days of delivery."
            type="info"
            action={
              <Button size="large" info target={"_blank"} href={"https://www.youtube.com/watch?v=qTU6rHUjl-M"}>
                More Details
              </Button>
            }
          />
          <Divider/>
          <Form
            {...formItemLayout}
            form={form}
            name="register"
            onFinish={handleSubmit}
            scrollToFirstError
          >
            {
              selectedProductItemData?.itemMetas &&
              (
                <div style={{marginBottom: 30}}>
                  <h3>Product Meta List</h3>
                  <MetaEdit
                    metas={selectedProductItemData?.itemMetas}
                    rowSelection={rowSelection}
                    form={form}
                    updatedPrice={updatedPrice}
                    setUpdatedPrice={setUpdatedPrice}
                  />
                </div>
              )
            }
            <Form.Item
              name="type"
              label="I want to apply for:"
              rules={[
                {
                  required: true,
                  message: 'Please select a refund type',
                },
              ]}
            >
              <Radio.Group options={refundOptions} buttonStyle="solid" optionType="button"/>
            </Form.Item>
            <Form.Item
              name="item_received"
              label="Did you receive the item?"
              rules={[
                {
                  required: true,
                  message: 'Please select yes or no',
                },
              ]}
            >
              <Radio.Group>
                <Radio style={radioStyle} value={1}>
                  Yes
                </Radio>
                <Radio style={radioStyle} value={0}>
                  No
                </Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="reason"
              label="Reason for refund request"
              rules={[
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ]}
            >
              <Select style={{width: 400}}>
                {
                  RefundReasons.map((type, index) => {
                    return <Select.Option key={index} value={type.value}>{type.label}</Select.Option>
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item
              name="amount"
              label="Refund amount"
            >
              <InputNumber max={totalAmountWithCharge} min={0.01}/>
              {/*<span style={{marginLeft: 20}}>Please enter an amount between <span style={{color: "red"}}>0.01 - {totalAmountWithCharge} BDT</span></span>*/}
            </Form.Item>
            <Form.Item name="charges" label="Charges">
              <Checkbox.Group>
                {
                  selectedProductItemData?.charges && selectedProductItemData?.charges.map((charge, index) => {
                    return (
                      <Checkbox
                        value={charge.amount}
                        style={{lineHeight: '32px'}}
                        key={index}
                        onChange={(e) => {
                          let amount = form.getFieldValue(`amount`);
                          if (e.target.checked) {
                            amount += charge.amount;
                          } else {
                            amount -= charge.amount;
                          }
                          form.setFieldsValue({'amount': amount})
                        }}
                      >
                        {charge.type_label + " - " + charge.amount}
                      </Checkbox>
                    )
                  })
                }
              </Checkbox.Group>
            </Form.Item>
            <Form.Item
              name="detail"
              label="Please write your request detail with video link"
              rules={[
                {
                  required: true,
                  message: 'Please enter a refund amount',
                },
              ]}
            >
              <Input.TextArea rows={4}/>
            </Form.Item>
            <Form.Item
              name="evidence"
              label="Upload Evidence"
              rules={[
                {
                  required: true,
                  message: 'Please Upload Evidence',
                },
              ]}
            >
              <MultipleImageUpload
                handleUpload={handleImageUpload}
              />
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button disabled={submitBtnEnabled} type="primary" htmlType="submit">
                Request
              </Button>
            </Form.Item>
          </Form>
        </>
    }
    </Modal>


  );
}

export default RefundForm
