import React, { useEffect, useState } from 'react'
import { Modal, Table } from 'antd'
import { getCorrelatedOrdersData } from "utils/buy-order-client"
import {formateDateTime, toastErrors} from 'utils/helpers';

const OrdersModal = Props => {
  const { show, onHide, correlatedItemId, } = Props;
  const [correlatedOrdersInfo, setCorrelatedOrdersInfo] = useState(null)

  useEffect(() => {
    if (correlatedItemId) {
      getCorrelatedOrdersData(correlatedItemId.id)
        .then(response => {
          if (response && response.data && response.data.data) {
            setCorrelatedOrdersInfo(response.data.data)
          }
        }).catch(({response}) => {
          if (response?.data) {
            toastErrors(response.data);
          }
        })
    }
  }, [correlatedItemId])

  const columns = [
    {
      title: 'Order ID',
      dataIndex: 'source_order_id',
      key: 'source_order_id',
    },
    {
      title: 'Order Date',
      dataIndex: 'order_date',
      render: (record) => {
        return formateDateTime(record);
      }
    },
    {
      title: 'Customer Email',
      dataIndex: 'customer_email',
      key: 'customer_email',
    },
    {
      title: 'Customer Phone',
      dataIndex: 'customer_phone',
      key: 'customer_phone',
    },
    {
      title: 'quantity',
      dataIndex: 'quantity',
      key: 'qty',
    },
    {
      title: 'Sell Price',
      dataIndex: 'sell_price',
      key: 'sell_price',
    },
    {
      title: 'Meta',
      key: 'meta',
      render: (record) => {
        return (
          record?.itemMetas?.meta?.map((metadata) => {

            return (
              <div className="warehouse-address ml-lg-4"
                key={`varItem-${metadata}`}>
                <div>
                  {metadata.title}: <span
                    className="font-weight-bold"
                    style={{ color: "black" }}>{metadata.value}</span>
                </div>
              </div>
            )
          }));
      }
    },
  ];

  return (
    <Modal
      title="Orders"
      visible={show}
      onCancel={onHide}
      width={1000}
      footer={null}
    >
      <Table dataSource={correlatedOrdersInfo} columns={columns} />
    </Modal>
  );
}

export default OrdersModal
