import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
    Button,
    Card,
    Col,
    Descriptions,
    Divider,
    Form,
    Image, Input,
    message,
    Modal,
    Progress,
    Row,
    Select,
    Skeleton,
    Table,
    Tag, Typography
} from 'antd'
import DateTime from 'components/DateTime'
import { getPendingOrders, getUsers, placeGroupOrder, placeOrder } from 'utils/order-client'
import { AddressSelect, StatusTag } from 'components'
import { getDefaultAddress } from 'utils/customer-client'
import { clearQuerySearchParamsByKeys, getQueryParams, insertUrlParams, toastErrors } from 'utils/helpers'
import { PageHeader } from "@ant-design/pro-layout";
import axios from "axios";
import { getWebsites } from "../../utils/oauth-client";

const { Column } = Table
// const {RangePicker} = DatePicker
const INITIAL_FILTERS = {
    customer_id: undefined
}

const { Option } = Select

const Orders = () => {
    const [form] = Form.useForm()
    const [response, setResponse] = useState({ data: [], meta: [] })
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [filters, setFilters] = useState(INITIAL_FILTERS)
    const [isLoading, setIsLoading] = useState(false)
    const [customers, setCustomers] = useState([])
    const [showAddressModal, setShowAddressModal] = useState(false)
    const [addressType, setAddressType] = useState(null)
    const [address, setAddress] = useState({ shipping: null, billing: null, delivery: null })
    const [showActionButton, setShowActionButton] = useState(true)
    const [orderPlaceLoading, setOrderPlaceLoading] = useState(false)
    const [groupOrderPlaceLoading, setGroupOrderPlaceLoading] = useState(false)
    const [failedOrderItems, setFailedOrderItems] = useState([])
    const [showErrorsList, setShowErrorsList] = useState(false)
    const succeddedOrder = useRef(0)
    const completedOrder = useRef(0)
    const [websiteList, setWebsiteList] = useState([]);
    const [isWebsiteFetching, setIsWebsiteFetching] = useState(false);
    const [selectedWebsite, setSelectedWebsite] = useState([]);

    const fetchOrders = React.useCallback(
        (selectedWebsite) => {
            setIsLoading(true)
            axios.get(`${selectedWebsite[0]}/wp-json/moveon-drop-wp-api/order/pending?page=1&per_page=10&client_secret=${selectedWebsite[1]}`, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }).then(res => {
                if (res && res.data && res.data.data) {
                    setResponse({
                        data: res.data.data.orders,
                        meta: res.data.data.meta
                    })
                }
            }).catch(err => {
                if (err.response) {
                    if (err.response.status === 403) {
                        message.error('The action is not authorized')
                    } else {
                        message.error(err.response.data.message ? err.response.data.message : 'Unknown error')
                    }
                } else {
                    message.error('Unknown error')
                }
            }).finally(x => {
                setIsLoading(false)
            })

        },
        [selectedWebsite],
    )

    const fetchWebsites = useCallback(
        () => {
            setIsWebsiteFetching(true)
            getWebsites()
                .then(res => {
                    setWebsiteList(res.data)
                }).catch(({ response }) => {
                    if (response?.data) {
                        toastErrors(response.data);
                    }
                }).finally(() => setIsWebsiteFetching(false))
        },
        [],
    )

    const fetchDefaultAddrsses = React.useCallback(
        () => {
            getDefaultAddress()
                .then(res => {
                    if (res && res.data && res.data.data && res.data.data.data) {
                        setAddress({
                            billing: res.data.data.data.billing,
                            shipping: res.data.data.data.shipping
                        })
                    }
                }).catch(err => {
                    if (err.response) {
                        if (err.response.status === 403) {
                            message.error('The action is not authorized')
                        } else {
                            message.error(err.response.data.message ? err.response.data.message : 'Unknown error')
                        }
                    } else {
                        message.error('Unknown error')
                    }
                }).finally(x => {
                    setIsLoading(false)
                })

        },
        [],
    )

    const fetchUsers = useCallback(
        () => {
            if (selectedWebsite && selectedWebsite.length > 0) {
                setIsLoading(true)
                axios.get(`${selectedWebsite[0]}/wp-json/moveon-drop-wp-api/order/users?client_secret=${selectedWebsite[1]}`, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then(res => {
                    if (res && res.data && res.data.data) {
                        setCustomers(res.data.data)
                    }
                }).catch(err => {
                    if (err.response) {
                        if (err.response.status === 403) {
                            message.error('The action is not authorized')
                        } else {
                            message.error(err.response.data.message ? err.response.data.message : 'Unknown error')
                        }
                    } else {
                        message.error('Unknown error')
                    }
                }).finally(x => {
                    setIsLoading(false)
                })
            }
        },
        [selectedWebsite],
    )

    const fetchData = useCallback(
        () => {
            setFailedOrderItems([])
            fetchUsers()
            let queryParams = getQueryParams()
            let validFilters = {}
            for (let [key, value] in Object.entries(filters)) {
                if (queryParams[key]) {
                    validFilters[key] = value
                }
            }
            let page = queryParams['page_index'] ? parseInt(queryParams['page_index']) : 1
            let pageSize = queryParams['per_page'] ? parseInt(queryParams['per_page']) : 10
            if (selectedWebsite.length > 0) {
                fetchOrders(selectedWebsite)

            }
            fetchDefaultAddrsses()
        },
        [fetchUsers, filters, fetchOrders, fetchDefaultAddrsses, selectedWebsite],
    )

    useEffect(() => {
        fetchData(selectedWebsite)
        fetchWebsites()
    }, [selectedWebsite])

    const handlePageChange = (page, pageSize) => {
        insertUrlParams([
            {
                key: 'page_index',
                value: page
            }
        ])
        // fetchOrders(selectedWebsite)
    }

    const handlePlaceOrder = data => {
        setOrderPlaceLoading(true)
        return axios.post(`${selectedWebsite[0]}/wp-json/moveon-drop-wp-api/order/place?client_secret=${selectedWebsite[1]}`, data,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(res => {
            if (res && res.data && res.data.data && res.data.data.data && res.data.data.data.failed_items) {
                if (res.data.data.data.failed_items.length === 0) {
                    succeddedOrder.current = succeddedOrder.current + 1
                } else {
                    failedOrderItems.push(...res.data.data.data.failed_items)
                    setFailedOrderItems(failedOrderItems)
                }
            }
        }).catch(err => {
            if (err.response && err.response.status === 403) {
                message.error('The action is not authorized')
            } else {
                if (err && err.response && err.response.data && err.response.data.data && err.response.data.data.message) {
                    message.error(err.response.data.data.message)
                } else {
                    message.error('Something is wrong')
                }
            }
        }).finally(x => {
            setOrderPlaceLoading(false)
            completedOrder.current = completedOrder.current + 1
        })
    }
    const handleGroupOrderPlace = data => {
        setGroupOrderPlaceLoading(true)
        return axios.post(`${selectedWebsite[0]}/wp-json/moveon-drop-wp-api/order/group-order?client_secret=${selectedWebsite[1]}`, data,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(res => {
            if (res && res.data && res.data.data && res.data.data.data && res.data.data.data.failed_items) {
                if (res.data.data.data.failed_items.length === 0) {
                    succeddedOrder.current = succeddedOrder.current + 1
                } else {
                    failedOrderItems.push(...res.data.data.data.failed_items)
                    setFailedOrderItems(failedOrderItems)
                }
            }
        }).catch(err => {
            if (err.response && err.response.status === 403) {
                message.error('The action is not authorized')
            } else {
                if (err && err.response && err.response.data && err.response.data.data && err.response.data.data.message) {
                    message.error(err.response.data.data.message)
                } else {
                    message.error('Something is wrong')
                }
            }
        }).finally(x => {
            setGroupOrderPlaceLoading(false)
            completedOrder.current = completedOrder.current + 1
        })
    }

    async function handleOrderPlace() {
        if (selectedRowKeys.length === 0) {
            return
        }

        if (!address.billing) {
            message.error('No default billing address found');
            return;
        }
        if (!address.shipping) {
            message.error('No default shipping address found');
            return;
        }

        completedOrder.current = 0
        succeddedOrder.current = 0
        for (const item of selectedRowKeys) {
            let data = {
                'order_id': item,
                'billing_address_id': address.billing.id,
                'shipping_address_id': address.shipping.id
            }
            await handlePlaceOrder(data)
        }
        if (failedOrderItems.length > 0) {
            message.warning('There are some errors with some orders')
        } else if (succeddedOrder.current === selectedRowKeys.length) {
            message.success('Placed all the orders successfully')
        }
        setSelectedRowKeys([])
    }

    async function handleGroupOrder() {
        if (selectedRowKeys.length === 0) {
            return
        }
        if (!address.billing) {
            message.error('No default billing address found');
            return;
        }
        if (!address.shipping) {
            message.error('No default shipping address found');
            return;
        }
        let data = {
            'order_ids': selectedRowKeys,
            'billing_address_id': address.billing.id,
            'shipping_address_id': address.shipping.id
        }
        await handleGroupOrderPlace(data)

        setSelectedRowKeys([])
    }

    const handleAddressSelect = (type, selectedAddress) => {
        let updatedAddress = address
        if (type === 'shipping') {
            updatedAddress.shipping = selectedAddress
        } else if (type === 'billing') {
            updatedAddress.billing = selectedAddress
        } else if (type === 'delivery') {
            updatedAddress.delivery = selectedAddress
        }
        setAddress(updatedAddress)
    }

    const handleOrderPlaceButton = () => {
        setShowActionButton(false)
    }


    const handleFilterChange = (field, value) => {
        let fieldValue = !value ? undefined : value
        if (field === 'date' && value[0] === '') {
            fieldValue = undefined
        }
        setFilters({
            ...filters,
            [field]: fieldValue
        })
    }
    const handleWebsiteChange = (value) => {
        const values = value.split("/wp-admin/admin.php?page=moveon-drop-wp--");
        if (values.length >= 2) {
            setSelectedWebsite(values)
        }
    }
    const handleClear = () => {
        form.resetFields()
        setFilters(INITIAL_FILTERS)
        clearQuerySearchParamsByKeys(Object.keys(filters))
    }

    const handleFilterSubmit = () => {
        let validFilters = []
        for (let [key, value] of Object.entries(filters)) {
            if (value !== undefined) {
                validFilters.push({ key: key, value: value })
            }
        }
        insertUrlParams(validFilters)
        // fetchOrders(selectedWebsite)
    }

    const onShowSizeChange = (current, size) => {
        insertUrlParams([
            {
                key: 'per_page',
                value: size
            }
        ])
        // fetchOrders(selectedWebsite)
    }

    const handleShowAddressModal = type => {
        setAddressType(type)
        setShowAddressModal(true)
    }
    const paginationConfig = response && response.meta ? {
        total: response.meta.total,
        current: response.meta.current_page,
        onChange: handlePageChange,
        onShowSizeChange: onShowSizeChange,
        pageSize: response.meta.per_page,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50, 100, 500, 1000],
        position: ['none', 'bottomLeft']
    } : false

    const renderNestedTable = (record, index) => {
        const currencyCode = record.currency_code
        const items = record.items
        return (
            <Table
                dataSource={items}
                pagination={false}
                scroll={{
                    x: true
                }}
            >
                <Column
                    title="Image"
                    dataIndex="image"
                    key="image"
                    width={'10%'}
                    render={(image) => {
                        return (
                            <Image
                                shape="square"
                                width={100}
                                src={image}
                            />
                        )
                    }}
                />

                <Column
                    title="Title"
                    dataIndex="name"
                    key="name"
                    render={(title) => {
                        return (
                            <div className="buy_title">
                                <Typography.Text
                                    ellipsis={{ tooltip: title }}
                                    style={{ width: '150px' }}
                                >{title}</Typography.Text>
                            </div>
                        )
                    }}
                    width={'10%'}
                />

                <Column
                    title="Quantity"
                    dataIndex="quantity"
                    key="quantity"
                />

                <Column
                    title="Product Source"
                    dataIndex="is_moveon_product"
                    key="is_moveon_product"
                    render={(is_moveon_product) => (
                        is_moveon_product ?
                            <Tag color={'#108ee9'}>MoveOn</Tag>
                            : <></>
                    )}
                />

                <Column
                    title="Product Meta"
                    dataIndex="meta_data"
                    key="meta_data"
                    render={(meta_data, record) => (
                        meta_data && meta_data.length > 0 ?
                            <div className={'product-metas'}>
                                {
                                    meta_data.map((meta, index) => {
                                        return (
                                            <div key={'manual-order-meta-item-' + index} className={'meta-item'}>
                                                <span
                                                    style={{ fontWeight: 'bold' }}>{meta.key}</span>: &nbsp; {meta.value}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            : <></>
                    )}
                />

                <Column
                    title="Subtotal"
                    dataIndex="subtotal"
                    key="subtotal"
                    render={(subtotal, record) => (
                        subtotal ?
                            <>{subtotal} {currencyCode}</>
                            :
                            'N/A'
                    )}
                />

                <Column
                    title="Total"
                    dataIndex="total"
                    key="total"
                    render={(total, record) => (
                        total ?
                            <>{total} {currencyCode}</>
                            :
                            'N/A'
                    )}
                />

            </Table>
        )

    }

    return (
        <PageHeader
            ghost={false}
            title="Manage Orders"
        >
            <Row>
                <Col span={24}>
                    <Form
                        form={form}
                        onFinish={handleFilterSubmit}
                        labelCol={{ span: 12 }}
                        layout="vertical"
                    >
                        <Row gutter={24}>
                            <Col xs={24} md={12} lg={12} xl={8} xxl={4}>
                                <Form.Item label="Website" name="website_id">
                                    <Select
                                        placeholder="Please select website"
                                        onChange={(value) => handleWebsiteChange(value)}
                                        loading={isWebsiteFetching}
                                    >
                                        {
                                            websiteList && websiteList.map(d => <Select.Option value={d.redirect + '--' + d.secret}
                                                key={d.id}>{d.name}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={12} xl={8} xxl={6}>
                                <Form.Item label="Customer" name="customer_id">
                                    <Select
                                        allowClear
                                        showSearch
                                        placeholder="Select customer"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(value) => handleFilterChange('customer_id', value)}
                                        style={{ width: '100%' }}
                                    >
                                        {customers?.map(d => (
                                            <Option key={d.id} value={d.id}>{d.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={12} xl={8} xxl={6}>
                                <Form.Item label="Phone Number" name="phone">
                                    <Input
                                        placeholder="Enter phone number"
                                        onChange={(e) => handleFilterChange("phone", e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={12} xl={8} xxl={6}>
                                <Form.Item label="Email" name="email">
                                    <Input
                                        placeholder="Enter email"
                                        onChange={(e) => handleFilterChange("email", e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">Search</Button>
                                    <Button onClick={handleClear} style={{ marginLeft: '15px' }}>Clear</Button>
                                    <Button
                                        onClick={() => {
                                            fetchData()
                                            setShowActionButton(true)
                                        }}
                                        type="primary"
                                        danger
                                        loading={isLoading}
                                        style={{
                                            float: 'right',
                                            marginRight: '3%'
                                        }}
                                    >Reload</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    {
                        showActionButton ?
                            <Row>
                                <Col flex="81px" style={{ marginBottom: 16 }}>
                                    <Button
                                        onClick={handleOrderPlaceButton}
                                        type={'primary'}
                                    >
                                        Place Order
                                    </Button>
                                </Col>
                            </Row>
                            :
                            <Row style={{ marginTop: 10, marginBottom: 10 }}>
                                <Col span={24} className="address-view-wrapper">
                                    <Card>
                                        {isLoading ?
                                            <Skeleton active paragraph={{ rows: 3 }} />
                                            :
                                            <Row className={'address-view'}>
                                                <Col span={7}>
                                                    <Descriptions
                                                        column={2}
                                                        title={<>
                                                            <span style={{ marginRight: 10 }}>Billing Address</span>
                                                            <Button
                                                                onClick={() => handleShowAddressModal('billing')}
                                                                type="dashed"
                                                                size={64}
                                                            >Edit</Button>
                                                        </>}
                                                    >
                                                        {address.billing && (<>
                                                            <Descriptions.Item label={<span
                                                                className="font-weight-600">Name</span>}>{address?.billing?.name}</Descriptions.Item>
                                                            <Descriptions.Item label={<span
                                                                className="font-weight-600">Phone</span>}>{address?.billing?.phone}</Descriptions.Item>
                                                            <Descriptions.Item label={<span
                                                                className="font-weight-600">Country</span>}>{address?.billing?.country}</Descriptions.Item>
                                                            <Descriptions.Item label={<span
                                                                className="font-weight-600">District</span>}>{address?.billing?.district?.name}</Descriptions.Item>
                                                            <Descriptions.Item label={<span
                                                                className="font-weight-600">Area</span>}>{address?.billing?.area?.name}</Descriptions.Item>
                                                            <Descriptions.Item label={<span
                                                                className="font-weight-600">Address</span>}>{address?.billing?.address}</Descriptions.Item>
                                                            <Descriptions.Item label={<span
                                                                className="font-weight-600">Postal Code</span>}>{address?.billing?.postal_code}</Descriptions.Item>
                                                        </>)}
                                                    </Descriptions>
                                                </Col>
                                                <Divider
                                                    type={'vertical'}
                                                    orientation={'center'}
                                                    style={{
                                                        height: '200px'
                                                    }}
                                                />
                                                <Col span={7}>
                                                    <Descriptions
                                                        column={2}
                                                        title={<>
                                                            <div className="ant-descriptions-title">
                                                                <span style={{ marginRight: 10 }}>Shipping Address</span>
                                                                <Button
                                                                    onClick={() => handleShowAddressModal('shipping')}
                                                                    type="dashed"
                                                                    size={64}
                                                                >Edit</Button>
                                                            </div>
                                                        </>}
                                                    >
                                                        {address.shipping && (<>
                                                            <Descriptions.Item label={<span
                                                                className="font-weight-600">Name</span>}>{address?.shipping?.name}</Descriptions.Item>
                                                            <Descriptions.Item label={<span
                                                                className="font-weight-600">Phone</span>}>{address?.shipping?.phone}</Descriptions.Item>
                                                            <Descriptions.Item label={<span
                                                                className="font-weight-600">Country</span>}>{address?.shipping?.country}</Descriptions.Item>
                                                            <Descriptions.Item label={<span
                                                                className="font-weight-600">District</span>}>{address?.shipping?.district?.name}</Descriptions.Item>
                                                            <Descriptions.Item label={<span
                                                                className="font-weight-600">Area</span>}>{address?.shipping?.area?.name}</Descriptions.Item>
                                                            <Descriptions.Item label={<span
                                                                className="font-weight-600">Address</span>}>{address?.shipping?.address}</Descriptions.Item>
                                                            <Descriptions.Item label={<span
                                                                className="font-weight-600">Postal Code</span>}>{address?.shipping?.postal_code}</Descriptions.Item>
                                                        </>)}
                                                    </Descriptions>

                                                </Col>
                                                <Divider
                                                    type={'vertical'}
                                                    orientation={'center'}
                                                    style={{
                                                        height: '200px'
                                                    }}
                                                />
                                                <Col span={8}>
                                                    <Col span={24}>
                                                        <Progress
                                                            strokeColor={{
                                                                from: '#108ee9',
                                                                to: '#87d068',
                                                            }}
                                                            percent={(100 * completedOrder.current) / selectedRowKeys.length}
                                                            status="active"
                                                        />
                                                    </Col>
                                                    <Col span={24} style={{ marginTop: 30 }}>
                                                        <Button
                                                            type="primary"
                                                            onClick={handleOrderPlace}
                                                            loading={orderPlaceLoading}
                                                        >Place order</Button>
                                                        <Button
                                                            style={{ marginBottom: 16, marginLeft: 10 }}
                                                            type="primary"
                                                            onClick={() => handleGroupOrder()}
                                                            loading={groupOrderPlaceLoading}
                                                        >Place Group order</Button>
                                                    </Col>

                                                    {
                                                        failedOrderItems.length > 0 &&
                                                        <Col span={24} style={{ marginTop: 20 }}>
                                                            &nbsp;&nbsp;
                                                            <Button
                                                                danger
                                                                onClick={() => setShowErrorsList(true)}
                                                            >Show errors ({failedOrderItems.length})</Button>
                                                        </Col>
                                                    }

                                                </Col>
                                            </Row>
                                        }
                                    </Card>
                                </Col>
                            </Row>
                    }

                    <Table
                        dataSource={response.data}
                        loading={isLoading}
                        pagination={paginationConfig}
                        rowSelection={{
                            selectedRowKeys,
                            onChange: selectedRowKeys => setSelectedRowKeys(selectedRowKeys)
                        }}
                        rowKey="id"
                        expandedRowRender={renderNestedTable}
                        scroll={{
                            x: true
                        }}
                    >
                        <Column
                            title="Order"
                            dataIndex="id"
                            key="id"
                            align={'center'}
                            render={(id, record) => (
                                <a href={record.order_edit_link} target={"_blank"}
                                >{'#' + record.id + ' ' + record.billing_first_name + ' ' + record.billing_last_name}</a>
                            )}
                        />

                        <Column
                            title="Customer Name"
                            dataIndex="billing_first_name"
                            key="customer"
                            align={'center'}
                            render={(billing_first_name, item) => (
                                billing_first_name &&
                                <>
                                    {billing_first_name + ' ' + item.billing_last_name}
                                </>
                            )}
                        />

                        <Column
                            title="Total Items"
                            dataIndex="items"
                            key="TotalItems"
                            align={'center'}
                            render={items => (
                                items ?
                                    <>{items.length}</>
                                    : 'N/A'
                            )}
                        />

                        <Column
                            title="Total MoveOn Items"
                            dataIndex="moveon_product_count"
                            key="TotalMoveOnItems"
                            align={'center'}
                        />

                        <Column
                            title="Total"
                            dataIndex="total"
                            key="Total"
                            align={'center'}
                            render={(total, record) => (
                                total ?
                                    <>{total} {record.currency_code}</>
                                    :
                                    'N/A'
                            )}
                        />

                        <Column
                            title="Status"
                            dataIndex="status"
                            key="status"
                            align={'center'}
                            render={(status, record) => {
                                return <StatusTag text={record.status_label} slug={status} />
                            }}
                        />
                        <Column
                            title="Created At"
                            dataIndex="date_created"
                            key="created-at"
                            align={'center'}
                            responsive={['xl']}
                            render={date_created => (
                                date_created ?
                                    <DateTime date={date_created.date} />
                                    : 'N/A'
                            )}
                        />
                    </Table>
                </Col>
                {
                    showAddressModal &&
                    <AddressSelect
                        show={showAddressModal}
                        onHide={() => setShowAddressModal(false)}
                        type={addressType}
                        handleAddressSelect={handleAddressSelect}
                    />
                }

                {
                    showErrorsList &&
                    <Modal
                        title={`Error List`}
                        visible={showErrorsList}
                        onCancel={() => {
                            setShowErrorsList(false)
                        }}
                        footer={null}
                        width={'60vw'}
                    >
                        <Row>
                            <Col span={24}>
                                <Table
                                    dataSource={failedOrderItems}
                                    scroll={{
                                        x: true
                                    }}
                                >
                                    <Column
                                        title="Order"
                                        dataIndex="order_id"
                                        key="order_id"
                                        align={'center'}
                                    />

                                    <Column
                                        title="Product Id"
                                        dataIndex="id"
                                        key="id"
                                        align={'center'}
                                    />

                                    <Column
                                        title="Product Name"
                                        dataIndex="name"
                                        key="name"
                                    />

                                    <Column
                                        title="Error"
                                        dataIndex="message"
                                        key="message"
                                    />

                                </Table>
                            </Col>
                        </Row>
                    </Modal>
                }


            </Row>

        </PageHeader>
    )
}

export default Orders
