import { useInvoicePayment } from "context/invoice-payment-context"
import React, { useEffect, useState } from "react"
import { Checkbox, Col, InputNumber, message, Modal, Radio, Row } from "antd"
import { gateways, SHIP_FOR_ME } from "consts/app-constants"

const InvoicePayModal = Props => {
  const { show, onHide } = Props
  const {
    selectedInvoices,
    invoiceType,
    payInvoice,
    payableAmount,
    setPayableAmount,
    walletBalance,
    gatewayList,
    isInvoicePaying
  } = useInvoicePayment()

  const [currentOption, setCurrentOption] = useState("full-payment")
  const [walletChecked, setWalletChecked] = useState(false)

  const getPayable = React.useCallback(() => {
    if (invoiceType === SHIP_FOR_ME) {
      return selectedInvoices.map(item => (item.amount_total - item.amount_paid)).reduce((prev, next) => prev + next);
    }
    let y = selectedInvoices.map(item => {
      return (item.total - item.paid_amount);
    });
    return y.reduce((prev, next) => prev + next);
  }, [invoiceType, selectedInvoices]);

  useEffect(() => {
    if (currentOption === "full-payment") {
      setPayableAmount(getPayable())
    } else {
      setPayableAmount(1)
    }
  }, [currentOption, selectedInvoices, setPayableAmount, getPayable])

  const handleOptionSelect = (event) => {
    let value = event.target.value
    setCurrentOption(value)
  }

  const formatAmount = num => {
    if (num === null || num === undefined) {
      return null
    }
    return num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
  }

  const handleOnOk = () => {
    if (payableAmount <= 0) {
      message.warning("Payable amount must be greater than 0")
      return
    }

    if (!walletChecked) {
      Props.handlePageAction("invoice-payment");
      onHide();
      return
    }

    let walletGateway = gatewayList.filter(gateway => gateway.gateway_type === gateways.Wallet)
    if (!walletGateway.length) {
      message.warning("Gateway not found")
      return
    }

    let data = {
      gateway_id: walletGateway[0].id,
      invoices: selectedInvoices.map(item => {
        return {
          id: item.id,
          type: invoiceType
        }
      }),
      amount: payableAmount
    }

    payInvoice(data, (response) => {
      message.success("Wallet payment successfull")
    })
  }

  return (
    <Modal
      title="Choose Amount"
      style={{ top: 30 }}
      visible={show}
      onOk={handleOnOk}
      okText={"Make Payment"}
      onCancel={onHide}
      okButtonProps={{
        loading: isInvoicePaying
      }}
    >
      <div>
        <Row>
          <Col span={24}>
            <span className="font-weight-700">Total Amount:</span> BDT {formatAmount(getPayable())}
          </Col>
        </Row>
        <Row>
          <Col
            span={12}
            style={{
              marginTop: 15
            }}
            offset={6}
          >
            <Radio.Group defaultValue="full-payment" buttonStyle="solid" onChange={handleOptionSelect}>
              <Radio.Button value="full-payment">Full Payment</Radio.Button>
              <Radio.Button value="split-payment">Split Payment</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            style={{
              marginTop: 15
            }}
          >
            {
              currentOption === "split-payment" &&
              (
                <>
                  <span className="font-weight-700" style={{ display: "block" }}>Enter amount</span>
                  <InputNumber
                    addonAfter="BDT"
                    defaultValue={1}
                    value={payableAmount}
                    onChange={(value) => {
                      setPayableAmount(value)
                    }}
                  />
                </>
              )
            }
          </Col>
        </Row>

        <Row>
          <Col
            span={24}
            style={{
              marginTop: 15
            }}
          >
            <Checkbox onChange={(event) => setWalletChecked(event.target.checked)}>
              Use my balance <span>(BDT {formatAmount(walletBalance.current)} available)</span>
            </Checkbox>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

export default InvoicePayModal
