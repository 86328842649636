import React, {useEffect, useState} from "react"
import {Breadcrumb, Button, Col, message, Row, Skeleton, Tabs} from "antd"
import { PageHeader } from '@ant-design/pro-layout';
import {useWalletRecharge} from "context/wallet-recharge-context"
import {useInvoicePayment} from "context/invoice-payment-context"
import {useSettings} from "context/settings-context"
import {gateways, gatewayType} from "consts/app-constants"
import SSLCommerz from "components/Payment/SSLCommerz"
import BankDeposit from "components/Payment/BankDeposit"
import NagadPayment from "components/Payment/NagadPayment"
import RocketMerchant from "components/Payment/RocketMerchant"
import "./WalletRecharge.css"
import {useHistory} from "react-router-dom";

const WalletRecharge = Props => {
    const {handlePageAction} = Props;
    const {assetURL} = useSettings();
    const [isServiceGetCalled, setIsServiceGetCalled] = useState(false)
    const {rechargeWallet, payableAmount, isRecharging} = useWalletRecharge()
    const {isGatewaysFetching, fetchGatewayList, gatewayList} = useInvoicePayment()
    useEffect(() => {
        if (!isServiceGetCalled) {
            fetchGatewayList()
            setIsServiceGetCalled(true)
        }
    }, [fetchGatewayList, isServiceGetCalled])

    // const getTotal = (gateway) => {
    //     const amount = payableAmount
    //     let total = 0
    //     let fee = gateway.fee
    //     if (gateway.fee_type === "percentage") {
    //         total = amount + ((amount * fee) / 100.0)
    //     } else {
    //         total = amount + fee
    //     }
    //     return total
    // }

    const history = useHistory();

    const getPayable = (gateway) => {
        if (!gateway) {
            return
        }

        const amount = payableAmount
        let total = 0
        let fee = gateway.fee
        let symbol = "";
        if (gateway.fee_type === "percentage") {
            total = amount + Math.ceil((amount * fee) / 100.0)
            symbol = "%"
        } else {
            total = amount + fee
        }
        return `${payableAmount.toFixed(2)} + ${fee.toFixed(2)} ${symbol} = ${total.toFixed(2)}`
    }

    const payRegularGateway = (gateway) => {
        const data = {
            gateway_id: gateway.id,
            amount: payableAmount,
            redirect_url: window.location.href
        }

        rechargeWallet(data, (response) => {
            if (response.data && response.data.data && response.data.data.have_redirect_url === true) {
                let link = document.createElement("a")
                link.id = "redirect-link"
                link.target = "_blank"
                link.href = response.data.data.redirect_url
                link.click()
            }
        })
    }

    const payBankDeposit = (gateway, values) => {
        let data = {
            gateway_id: gateway.id,
            reference: values.reference,
            deposit_date: values.deposit_date,
            amount: payableAmount,
            images: values.images
        }

        rechargeWallet(data, (response) => {
            message.success("Bank deposit request successfull")
            setTimeout(() => {
                history.push('/wallet?action=wallet-overview');
            }, 1000);
        })
    }

    const getGatewayDetail = (gateway) => {
        switch (gateway.slug) {
            case gateways.SSLCommerz:
                return (
                    <SSLCommerz
                        isPaying={isRecharging}
                        gateway={gateway}
                        getPayable={() => getPayable(gateway)}
                        payByGateway={() => payRegularGateway(gateway)}
                    />
                )
            case gateways.Nagad:
                return (
                    <NagadPayment
                        isPaying={isRecharging}
                        gateway={gateway}
                        getPayable={() => getPayable(gateway)}
                        payByGateway={() => payRegularGateway(gateway)}
                    />
                )
            case gateways.Rocket:
                return (
                    <RocketMerchant
                        isPaying={isRecharging}
                        gateway={gateway}
                        getPayable={() => getPayable(gateway)}
                        payByGateway={(values) => payBankDeposit(gateway, values)}
                    />
                )
            default:
                return (
                    <></>
                )
        }
    }

    const getRegularGateways = () => {
        if (!gatewayList || !Array.isArray(gatewayList)) {
            return [];
        }

        let list = gatewayList.filter(gateway => {
            return gateway.gateway_type === gatewayType.RegularGateway || gateway.slug === gateways.Rocket
        });

        return list.filter(gateway => gateway.slug !== gateways.BKash);
    }

    const getBankDepositGateways = () => {
        if (!gatewayList || !Array.isArray(gatewayList)) {
            return [];
        }

        return gatewayList.filter(gateway => (gateway.gateway_type === gatewayType.BankDeposit && gateway.slug !== gateways.Rocket))
    }

    if (payableAmount <= 0) {
        handlePageAction("wallet-overview")
        return <></>
    }

    return (
        <PageHeader
            ghost={false}
            onBack={() => Props.handlePageAction("myorder")}
            breadcrumb={
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Button
                            key="1"
                            type="dashed"
                            onClick={() => Props.handlePageAction("wallet-overview")}
                        >
                            Wallet
                        </Button>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Wallet recharge</Breadcrumb.Item>
                </Breadcrumb>
            }
        >
            <Row>
                <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                    <Skeleton active loading={isGatewaysFetching}>
                        <Tabs defaultActiveKey="0" centered type="card">
                            {
                                getRegularGateways().map((gateway, index) => {
                                    return (
                                        <Tabs.TabPane
                                            tab={
                                                <div className={"gateway-selection"}>
                                                    <img
                                                        className="img-fluid"
                                                        src={gateway.logo}
                                                        width={100}
                                                        height={60}
                                                        alt={gateway.name}
                                                    />
                                                </div>
                                            }
                                            key={index}
                                        >
                                            {getGatewayDetail(gateway)}
                                        </Tabs.TabPane>
                                    )
                                })
                            }
                            <Tabs.TabPane
                                tab={
                                    <div className={"gateway-selection"}>
                                        <img
                                            src={assetURL + "/assets/images/payments/bankdeposit.svg"}
                                            alt="Bank Deposit"
                                            width={30}
                                            height={18}
                                        />
                                        <span>Bank Deposit</span>
                                    </div>
                                }
                                key={10}
                            >
                                <BankDeposit
                                    gatewayList={getBankDepositGateways()}
                                    getPayable={getPayable}
                                    isPaying={isRecharging}
                                    payByGateway={payBankDeposit}
                                />
                            </Tabs.TabPane>
                        </Tabs>
                    </Skeleton>
                </Col>
            </Row>
        </PageHeader>
    )
}

export default WalletRecharge
