import React, { useEffect, useState } from 'react'
import { Card, Col, Modal, Row, Skeleton } from 'antd'
import { getAddress } from "utils/customer-client"
import './AddressSelect.css'
import { toastErrors } from 'utils/helpers'

const AddressSelect = Props => {
  const [addresses, setAddresses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentAddress, setCurrentAddress] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getAddress(1, 1000)
      .then(res => {
        if (res && res.data && res.data.data) {
          setAddresses(res.data.data)
        }
      }).catch(({response}) => {
        if (response?.data) {
          toastErrors(response.data);
        }
      }).finally(x => {
        setIsLoading(false)
      })
    setCurrentAddress(null);
  }, [Props])

  return (
    <Modal
      title={`Select ${Props.type} address`}
      visible={Props.show}
      onCancel={() => {
        Props.onHide();
        setCurrentAddress(null);
      }}
      onOk={() => {
        Props.handleAddressSelect(Props.type, currentAddress)
        setCurrentAddress(null);
        Props.onHide();
      }}
      width={'60vw'}
    >
      <Row className={"customer-addresses"}>
        {
          isLoading ?
            <Skeleton active paragraph={{ rows: 10 }} />
            :
            addresses && addresses.length > 0 && addresses.map((address, index) => {
              return (
                <Col
                  onClick={() => {
                    setCurrentAddress(address);
                  }}
                  xxl={8} xl={8} lg={12} md={24} sm={24}
                  key={`address-item-${index}`}
                >
                  <Card
                    className={currentAddress && (address.id === currentAddress.id) ? "address-section selected" : "address-section"}>
                    <div className="address-item">
                      <span>Name:</span>
                      {address.name}
                    </div>
                    <div className="address-item">
                      <span>Phone:</span>
                      {address.phone}
                    </div>
                    <div className="address-item">
                      <span>Country:</span>
                      {address.country}
                    </div>
                    {
                      address.district && address.district.name &&
                      (
                        <div className="address-item">
                          <span>District:</span>
                          {address.district?.name}
                        </div>
                      )
                    }
                    {
                      address.area && address.area.name &&
                      (
                        <div className="address-item">
                          <span>Area:</span>
                          {address.area?.name}
                        </div>

                      )
                    }
                    {
                      <div className="address-item">
                        <span>Address:</span>
                        {address.address}
                      </div>
                    }
                    {
                      <div className="address-item">
                        <span>Postal Code:</span>
                        {address.postal_code}
                      </div>
                    }
                  </Card>
                </Col>
              );
            })
        }
      </Row>
    </Modal>

  );
}

export default AddressSelect
