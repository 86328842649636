import client from './api-client'
import decode from 'jwt-decode';
import { LOCAL_STORAGE_KEY } from 'consts/storage'
export function getUser() {
	const token = getToken()
	if (!token) {
		return Promise.resolve(null)
	}
	return client.get('/customer/profile/me').catch(error => {
		// logout()
		return Promise.reject(error)
	})
}

export function login({ username, password, remember_me }) {
	return client.post('/customer/auth/drop-login', { username, password, remember_me })
}

export const sendEmailOTP = (payload) => {
	return client.post('/customer/auth/email/request-otp', payload)
};

export const verifyEmailOTP = (data) => {
	return client.post('/customer/auth/email/verify-otp', data)
};

export const register = (data) => {
	return client.post('/customer/auth/register-drop', data);
}

export const forgetPassword = (data) => {
	return client.post('/customer/auth/forgot-password', data);
}

export const resetPassword = (data) => {
	return client.post('/customer/auth/reset-password', data);
}

export const actingLogin = (token) => {
	return client.get(`/customer/auth/acting/${token}`);
}

export function loggedIn() {
	const token = getToken()
	return !!token && !isTokenExpired(token)
}

export function isTokenExpired(token) {
	try {
		const decoded = decode(token);
		return decoded.exp < Date.now() / 1000;
	}
	catch (err) {
		return false;
	}
}

export const logout = () => {
	return client.post('customer/auth/logout')
}

export function getToken() {
	return window.localStorage.getItem(LOCAL_STORAGE_KEY)
}
