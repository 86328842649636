import React, { useState } from 'react'
import {Form, Input, Button, Alert, Typography, Divider, Row} from 'antd'
import { UserOutlined } from '@ant-design/icons'
import {forgetPassword} from "utils/auth-client";
import {Link, NavLink} from "react-router-dom";
import logo from "../../../assets/logo.png";
const {Title} = Typography;
const ForgetPassword = () => {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState(null);
    const [success, setSuccess] = useState(false);
    const onSubmit = (values) => {
        setIsLoading(true)
       forgetPassword({
           email: values.email
       }).then(res => {
            setIsLoading(false)
            setSuccess(true);
        }).catch(err => {
            setIsLoading(false)
            setErrors(err.response);
        })
    }
    return (
        <>
          <div className="login-card">
            <NavLink to={"/"}>
              <img className="brand-logo" src={logo} alt=""/>
            </NavLink>
            <Title className="mb-30" level={4}>Forget Password</Title>
            <Divider />

            <Form
              onFinish={onSubmit}
              layout="vertical"
              className="row-col"
              form={form}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Email!',
                  },
                ]}
              >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
              </Form.Item>
              <Row className="item-align-center">
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-btn"
                    loading={isLoading}
                  >
                    Reset Password
                  </Button>
                </Form.Item>
                <Form.Item>
            <span className="secondary-color">
              Don’t have an account? &nbsp;
              <Link to="/auth/login" className="text-dark primary-color">
              Login
            </Link>
            </span>
                </Form.Item>
              </Row>
            </Form>
            {
              errors && errors.data && errors.data.error &&
              <Alert message={errors.data.error} type="error" showIcon />
            }
            {
              success &&
              <Alert
                message="A reset password link is sent to your email "
                type="success"
                showIcon
              />
            }
          </div>
        </>
    );
};

export default ForgetPassword
