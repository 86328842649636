import React, { useEffect, useState } from 'react'
import { getShipmentInvoiceDetail } from 'utils/shipment-product-client'
import { message } from 'antd'
import { ShipmentInvoiceDetail } from 'components'

const ShipmentInvoice = Props => {
  const [invoice, setInvoice] = useState(Props.invoice);
  const [response, setResponse] = useState({ addresses: null, products: [] })
  const [isLoading, setIsLoading] = useState(false)
  const [isServiceGetCalled, setIsServiceGetCalled] = useState(false)

  const fetchInvoiceDetail = React.useCallback(
    (invoice) => {
      setIsLoading(true)
      getShipmentInvoiceDetail(invoice.id).then(res => {
        let data = res?.data?.data
        if (data) {
          setResponse({
            addresses: data.order?.addresses,
            products: data.items
          })
          setInvoice(data);
        }
        setIsLoading(false)
      })
        .catch(err => {
          setIsLoading(false)
          if (err.response && err.response.status === 403) {
            message.error('The action is not authorized')
          } else {
            if (err && err.response && err.response.data && err.response.data.data && err.response.data.data.message) {
              message.error(err.response.data.data.message)
            } else {
              message.error('Something is wrong')
            }
          }
        })
    },
    [],
  )

  useEffect(() => {
    if (!isServiceGetCalled && Props.invoice) {
      fetchInvoiceDetail(Props.invoice)
      setIsServiceGetCalled(true)
    }
  }, [Props, fetchInvoiceDetail, isServiceGetCalled])

  return (
    <ShipmentInvoiceDetail
      invoice={invoice}
      addresses={response.addresses}
      products={response.products}
      isLoading={isLoading}
    />
  )
}

export default ShipmentInvoice
