import client from './api-client'
import Qs from 'qs'

export const getInvoicesForShipment = (page = null, filter = null, perPage = 10) => {
    let data = {
        page: page ? page : 1,
        per_page: perPage
    }

    if (filter) {
        data = {...data, ...filter}
    }
    return client.get('/customer/invoices', {
        params: data,
        paramsSerializer: function (params) {
            return Qs.stringify(params, {arrayFormat: 'brackets'})
        },
    })
}

export const getShipmentInvoiceDetail = (invoiceId) => {
    return client.get(`/customer/invoices/${invoiceId}/detail`)
}
