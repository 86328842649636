import client from './api-client'

export function getDropInformation(host) {
	return client.get(host + '?action=get_metadata&slug=moveon-drop-wp', {
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Content-Type': 'application/json',
		},
		withCredentials: true,
		credentials: 'same-origin',
	})
}