import React from 'react'
import {
    Layout,
    Select,
    Col,
    Row,
    Button,
    Modal,
    Form,
    Checkbox,
     Spin,
  Empty,
  InputNumber
} from "antd";
import { ProductModalShipping } from "assets/SvgIcons";
import { global_shipping_instruction } from "consts/products";

function GlobalShippingModal(
    {
        product,
        openModal,
        setOpenModal,
        globalShipping,
        setGlobalShipping,
        setEstimatedWeight,
        slot,
        setSlot,
        onFinish,
        selectProductCategory,
        handleSearch,
        estimatedWeight,
        handleEstimatedWeight,
        estimatedPrice,
        showSlot,
        loadingCategory
    }
) {

    return (
        <Modal
            destroyOnClose={true}
            title="Global Shipping"
            open={openModal}
            onCancel={() => {
                setOpenModal(false);
                setGlobalShipping({
                    ...globalShipping,
                    shippingInfo: [],
                });
                setSlot(false)
                setEstimatedWeight(product?.meta?.weight)
            }}
            footer={null}
        >
            <Layout style={{ background: "#FFF" }}>
                <Form onFinish={onFinish} layout="vertical" >
                    <Row
                        gutter={[{ xs: 6, md: 12, lg: 16, xl: 16 }, {}]}
                        style={{ paddingTop: "24px" }}
                    >
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} >
                            <Form.Item label="Product Category" name={`Product Category`} rules={[
                                {
                                    required: true,
                                },
                            ]} >
                                <Select
                                    showSearch
                                    onChange={selectProductCategory}
                                    onSearch={handleSearch}
                                    style={{ height: "40px" }}
                                    placeholder="Please select category"
                                    notFoundContent={loadingCategory ? <Spin size="small" style={{display: 'flex', justifyContent: 'center',alignItems:'center',height: '200px'}}/>: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> }
                                    options={globalShipping.categories?.map((category, index) => {
                                        return {
                                            value: category.name.toLowerCase(),
                                            label: category.name,
                                            id: category.id,
                                        };
                                    })}
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <Form.Item label="Estimated Weight" name={`Estimated Weight`} rules={[
                                {
                                    type: 'number',
                                    min: 0,
                                    message: 'Please enter a non-negative number',
                                },
                            ]}>
                                <InputNumber
                                    style={{ height: "40px", width: '100%',display: 'flex', alignItems: 'center' }}
                                    placeholder="12kg"
                                    min={0}
                                    step={0.1}
                                    defaultValue={estimatedWeight}
                                    onChange={handleEstimatedWeight}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {globalShipping.shippingInfo[0] && (
                        <Layout
                            style={{
                                borderRadius: "8px",
                                border: "1px solid #DDD ",
                                width: "100%",
                            }}
                        >

                            <Row
                                style={{
                                    width: "100%",
                                    background: "rgba(136, 179, 76, 0.08)",
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: "1px",
                                    cursor: 'pointer'
                                }}
                                onClick={showSlot}
                            >
                                <Col span={3}>
                                    <Checkbox className="product-details-modal-checkbox"
                                        onChange={(e) => {
                                            e.stopPropagation();
                                        }}
                                        checked={slot}
                                    >
                                    </Checkbox>
                                </Col>
                                <Col span={16}>
                                    <li className="product-details-modal-text1">
                                        {globalShipping.shippingInfo?.[0]?.warehouse?.name}-৳
                                        {estimatedPrice ? estimatedPrice : globalShipping.shippingInfo?.[0]?.rate}/
                                        {globalShipping.shippingInfo?.[0]?.unit_type}
                                    </li>
                                    <li
                                        className="product-details-modal-text2"
                                        style={{ gap: "8px" }}
                                    >
                                        <span>
                                            <ProductModalShipping />
                                        </span>
                                        <span>
                                            Shipping time:&nbsp;
                                            {globalShipping.shippingInfo?.[0]?.warehouse?.est_sh_time}
                                        </span>
                                    </li>
                                </Col>
                                <Col span={5} className="product-details-modal-text3">
                                    ৳
                                    {estimatedWeight && estimatedPrice ?
                                        (estimatedWeight * estimatedPrice).toFixed(2) : globalShipping.shippingInfo?.[0]?.rate.toFixed(2)}
                                </Col>
                            </Row>
                        </Layout>
                    )}
                    <Layout
                        style={{
                            borderRadius: "8px",
                            border: "1px solid #DDD ",
                            marginTop: "16px",
                        }}
                    >
                        <Row style={{ width: "100%", padding: "16px 16px" }} className='product-details-card-global-shipping-modal-wrapper'>
                            <Row style={{ width: "100%" }}>
                                <Col span={10} >
                                    <span className="product-details-modal-slot-header">Shipping</span>
                                </Col>
                                <Col span={6} style={{ textAlign: "center" }}>
                                    <span className="product-details-modal-slot-header">Cost</span>
                                </Col>
                                <Col span={8} style={{ textAlign: "right" }}>
                                    <span className="product-details-modal-slot-header">Delivery time</span>
                                </Col>
                            </Row>
                            {slot && (<Row style={{ width: "100%", paddingTop: "10px" }}>
                                {globalShipping.shippingInfo?.[0]?.categoryPriceSlots.map((shipping, index) => (
                                    <Row key={index} style={{ padding: "6px 0px", width: "100%" }}>
                                        <Col span={10} >
                                            <span className="product-details-modal-slot-cell">Slot ({shipping.min_amount} - {shipping.max_amount})</span>
                                        </Col>
                                        <Col span={6} style={{ textAlign: 'center' }}>
                                            <span className="product-details-modal-slot-cell">{shipping.rate_per_unit} / {globalShipping.shippingInfo?.[0]?.unit_type}</span>
                                        </Col>
                                        <Col span={8} style={{ textAlign: 'right' }}>
                                            <span className="product-details-modal-slot-cell">{globalShipping.shippingInfo?.[0]?.warehouse?.est_sh_time}</span>
                                        </Col>
                                    </Row>
                                ))}
                            </Row>)}
                        </Row>
                    </Layout>
                    <Layout
                        style={{
                            borderRadius: "8px",
                            border: "1px solid #DDD ",
                            marginTop: "16px",
                        }}
                    >
                        <span className="product-details-modal-bottom-text">
                            {global_shipping_instruction}
                        </span>
                    </Layout>
                    <Row
                        style={{
                            paddingTop: "32px",
                            gap: "12px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Form.Item>
                            <Button
                                style={{
                                    color: "#4D4D4D",
                                    padding: "12px 32px",
                                    display: "flex",
                                    alignItems: "center",
                                    background: "#DDD",
                                    borderRadius: "5px",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    lineHeight: "16px",
                                }}
                                onClick={() => {
                                    setOpenModal(false);
                                    setGlobalShipping({
                                        ...globalShipping,
                                        shippingInfo: [],
                                    });
                                    setEstimatedWeight(null)
                                }}
                            >
                                <span>Cancel</span>
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                htmlType="submit"
                                style={{
                                    color: "#FFF",
                                    padding: "12px 33px",
                                    display: "flex",
                                    alignItems: "center",
                                    background: "#F37B7B",
                                    borderRadius: "5px",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    lineHeight: "16px",
                                }}
                            >
                                Submit
                            </Button>
                        </Form.Item>
                    </Row>
                </Form>
            </Layout>
        </Modal>
    )
}

export default GlobalShippingModal
