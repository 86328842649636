import client from './api-client'
import Qs from 'qs'

export const getAddress = (page = 1, perPage = 10) => {
    let data = {
        page,
        per_page: perPage
    }

    return client.get('/customer/profile/address', {
        params: data,
        paramsSerializer: function (params) {
            return Qs.stringify(params, { arrayFormat: 'brackets' })
        },
    })
}

export const updateAddress = (id, data) => {
    return client.put(`/customer/profile/address/${id}`, data)
}

export const storeAddress = (data) => {
    return client.post(`/customer/profile/address`, data)
}

export const deleteAddress = (id) => {
    return client.delete(`/customer/profile/address/${id}`)
}

export const getDefaultAddress = () => {
    return client.get(`/customer/profile/address/default`)
}
export const getProfile = () => {
    return client.get(`/customer/profile/me`)
}
