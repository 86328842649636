import React, { useEffect, useState } from 'react'
import { getBuyShipProductDetails } from "utils/buy-order-client"
import { StatusTag } from "components";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Descriptions,
  Image,
  Row,
  Skeleton,
  Spin,
  Table
} from "antd";
import { toastErrors } from 'utils/helpers';
import {ProgressTrack} from "../components";
import { PageHeader } from '@ant-design/pro-layout';

const { Column } = Table

const ProductDetail = Props => {
  const { productId } = Props
  const [productInfo, setProductInfo] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (productId) {
      setIsLoading(true)
      getBuyShipProductDetails(productId)
        .then(response => {
          if (response && response.data && response.data.data) {
            setProductInfo(response.data.data)
          }
        }).catch(({response}) => {
          if (response?.data) {
            toastErrors(response.data);
          }
        }).finally(() => setIsLoading(false))
    }
  }, [productId])

  let productItem = productInfo?.itemMetas?.reduce(function (acc, cur, i) {
    acc[i] = cur;
    return acc;
  })
  return (
    <PageHeader
      title={<>Product # {isLoading ? <Skeleton.Input size="small" style={{ width: 200 }} active={true} /> :
        <span>{productInfo && productInfo.product_number}</span>}</>}
      className="site-page-header"
      onBack={() => Props.handlePageAction("myorder")}
      breadcrumb={
        <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Button
              key="1"
              type="link"
              onClick={() => Props.handlePageAction("myorder")}
            >
              Order List
            </Button>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <>Product # {isLoading ? <Skeleton.Input size="small" style={{ width: 200 }} active={true} /> :
              <span>{productInfo && productInfo.product_number}</span>}</>
          </Breadcrumb.Item>
        </Breadcrumb>
      }
    >
      <Spin spinning={isLoading}>
        <ProgressTrack
          logs={productInfo && productInfo.logs}
        />

        <Row>
          <Col span={12}>
            <Card title="Buying Agent" bordered={false}>
              <Descriptions column={1} bordered={true}>
                <Descriptions.Item
                  label={<span className={"font-weight-600"}>Agent Company Name</span>}>
                  {productInfo && productInfo.agentCompany === null ? null : productInfo && productInfo.agentCompany.name}
                </Descriptions.Item>
                <Descriptions.Item label={<span className={"font-weight-600"}>Purchase Time</span>} />
                <Descriptions.Item label={<span className={"font-weight-600"}>Refund policy</span>} />
              </Descriptions>
            </Card>
          </Col>
          <Col span={12}>
            <Card title="Freight Forwarder Agent" bordered={false}>
              <Descriptions column={1} bordered={true}>
                <Descriptions.Item label={<span className={"font-weight-600"}>Freight by</span>}>
                  {productInfo && productInfo.shipping.company}
                </Descriptions.Item>
                <Descriptions.Item label={<span
                  className={"font-weight-600"}>Shipping Category</span>}>{productInfo && productInfo.shipping.category}</Descriptions.Item>
                <Descriptions.Item
                  label={<span className={"font-weight-600"}>Estimated Shipping Time</span>}>10 - 15
                  Days</Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        </Row>

        {
          productItem &&
          (
            <Table
              dataSource={[{ ...productInfo, ...productItem }]}
              rowKey="id"
              title={() => <span className="font-weight-700">Product Description</span>}
              loading={isLoading}
              scroll={{
                x: true
              }}
            >
              <Column
                title="Image"
                dataIndex="image"
                key="image"
                align="center"
                responsive={['md']}
                width={'10%'}
                render={(image, record) => (
                  record && record.image != null ?
                    <Image
                      shape="square"
                      width={100}
                      src={record.image}
                    />
                    :
                    <Image
                      shape="square"
                      size={100}
                      URL="http://placehold.jp/100x100.png"
                    />
                )}
              />

              <Column
                title="Product Details"
                dataIndex="id"
                key="product_detail"
                width={'25%'}
                render={(id, record) => {
                  return (
                    <>
                      <Row>
                        <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
                          <div className="buy_title">
                            <span>
                              <span className={"font-weight-600"}>Title </span>
                              {record.title.length > 30 ? `${record.title.substring(0, 30)}...` : record.title}
                            </span>
                          </div>
                          <div className="productNumber">
                            <span>
                              <span className={"font-weight-600"}>Product Number: </span>
                              {record.product_number}
                            </span>
                          </div>
                          {
                            (record.order && record.order.order_number) &&
                            <span>
                              <span className={"font-weight-600"}>Order ID: </span>
                              {record?.order?.order_number}
                            </span>
                          }
                        </Col>
                      </Row>
                    </>
                  )
                }}
              />
              <Column
                title="Status"
                dataIndex="status"
                key="status"
                width={'10%'}
                align={"center"}
                style={{ width: 200 }}
                render={(status, record) => (
                  <StatusTag slug={status} text={record.status_label} />
                )}
              />
              <Column
                title="Product Meta"
                dataIndex="id"
                key="detail"
                width={'30%'}
                align="center"
                render={(_, record) => {

                  const totalQuantity = record?.itemMetas?.reduce((acc, value) => {
                    if (value.qty === '') {
                      value = 0
                    } else {
                      value = parseInt(value.qty)
                    }
                    return acc + (value)
                  }, 0)

                  const totalPriceBdt = record?.itemMetas?.reduce((acc, value) => {
                    if (value.productPrice === '') {
                      value = 0
                    } else {
                      value = parseFloat(value.productPrice * value.qty)
                    }
                    return acc + (value)
                  }, 0)

                  return (
                    <>
                      <Table
                        dataSource={record.itemMetas}
                        bordered={false}
                        pagination={false}
                        size="small"
                        scroll={{ y: 150 }}
                        summary={() => {

                          let totalCharges = ''
                          if (record?.charges) {
                            totalCharges = record?.charges.reduce((acc, value) => {
                              return acc + (value.amount)
                            }, 0)
                          }
                          return (
                            <>

                              {
                                record?.charges?.map((metaCharge, index) => {
                                  return (
                                    <Table.Summary.Row key={index + "charges"}>
                                      <Table.Summary.Cell
                                        colSpan={3}>{metaCharge.type_label}</Table.Summary.Cell>
                                      <Table.Summary.Cell align={"center"}>
                                        <span>{(metaCharge.amount).toFixed(2)}</span>
                                      </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                  )
                                })
                              }

                              <Table.Summary.Row>
                                <Table.Summary.Cell
                                  align={"center"}>Total</Table.Summary.Cell>
                                <Table.Summary.Cell align={"center"}>
                                  <span>{totalQuantity}</span>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell
                                  align={"center"}>&nbsp;</Table.Summary.Cell>
                                <Table.Summary.Cell align={"center"}>
                                  <span>{totalPriceBdt ? ((totalCharges ? totalCharges : null) + totalPriceBdt).toFixed(2) : null}</span>
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                            </>
                          )
                        }}
                      >
                        <Column
                          title="Meta"
                          dataIndex="itemMetas"
                          key="meta"
                          align={"center"}
                          width={'20%'}
                          render={(meta, record) => {
                            if (!record.meta || typeof record.meta !== "object") {
                              return <></>
                            }
                            return (
                              record.meta.map((itemVariation, itemVariationID) => {
                                return (
                                  <div className="warehouse-address ml-lg-4"
                                    key={`varItem-${itemVariationID}`}>
                                    <div>
                                      {itemVariation.title}: <span
                                        className="font-weight-bold"
                                        style={{ color: "black" }}>{itemVariation.value}</span>
                                    </div>
                                  </div>
                                )
                              })
                            )
                          }}
                        />
                        <Column
                          title="Quantity"
                          dataIndex="qty"
                          key="qty"
                          align={"center"}
                          width={'10%'}
                          render={(qty, record) => (
                            record && record.qty
                          )}
                        />

                        <Column
                          title="Unit Price"
                          dataIndex="productPrice"
                          key="unit_price"
                          align={"center"}
                          width={'10%'}
                          render={productPrice => (
                            productPrice.toFixed(2)
                          )}
                        />

                        <Column
                          title="Total"
                          dataIndex="price"
                          key="price"
                          align={"center"}
                          width={'10%'}
                          render={(price, record) => {
                            return (
                              <>
                                {
                                  (record?.productPrice * record.qty).toFixed(2)
                                }
                              </>
                            )
                          }
                          }
                        />
                      </Table>
                    </>
                  )
                }}
              />
            </Table>
          )
        }
      </Spin>

    </PageHeader>
  )
}
export default ProductDetail
