import React, { useState } from "react";
import {
  Button,
  Typography,
  Form,
  Row,
  Col,
  Modal
} from "antd";
import { sendEmailOTP, verifyEmailOTP } from 'utils/auth-client'
import useCounter from "../useCounter";
import OtpInput from "react-otp-input";
import { toastErrors } from "utils/helpers";
import {Link} from "react-router-dom";

const SecondStep = Props => {
  const [form] = Form.useForm()
  const { step, data, updateStep, updateData } = Props
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [isResendLoading, setIsResendLoading] = useState(false)
  const [OTP, setOTP] = useState("")
  const { count, isOff, resetCounter } = useCounter()

  const handleSubmit = (formData) => {
    if (!data) {
      return
    }
    setIsSubmitLoading(true)
    const reqDataEmail = {
      email: data.email,
      otp: formData.otp,
    }

    verifyEmailOTP(reqDataEmail).then((response) => {
      updateData({
        email: response.data.email,
        token: response.data.token,
      })
      updateStep(step + 1)
    }).catch(({ response }) => {
      if (response?.data) {
        toastErrors(response.data);
      } else {
        Modal.error({
          title: 'Error Occurred',
          content: 'Unknown error occurred'
        });
      }
    }).finally(() => setIsSubmitLoading(false))
  }

  const handleResend = () => {
    if (!data) {
      return
    }
    setIsResendLoading(true)
    resetCounter()
    const email = data.email
    sendEmailOTP(email).then((response) => {
      updateData({
        email: response.data.email,
        expirationDuration: response.data.expirationDuration,
        token: response.data.token,
        hash: null,
      })
    }).catch(({ response }) => {
      if (response?.data) {
        toastErrors(response.data);
      } else {
        Modal.error({
          title: 'Error Occurred',
          content: 'Unknown error occurred'
        });
      }
    }).finally(() => setIsResendLoading(false))
  }

  if (step !== 2) {
    return null
  }

  return (
    <Form
      name="basic"
      onFinish={handleSubmit}
      className="row-col"
      form={form}
      layout="vertical"
    >
      <Form.Item
        name="otp"
        label="OTP"
        rules={[
          { required: true, message: "Please enter the otp!" },
        ]}
      >
        <Row>
          <Col span={24}>
            <OtpInput
              inputStyle={{
                width: "2.7rem",
                height: "3rem",
                margin: "0 10px",
                fontSize: "2rem",
                borderRadius: "4px",
                border: "1px solid rgba(0,0,0,0.3)"
              }}
              numInputs={4}
              isDisabled={false}
              hasErrored={false}
              onChange={(otp) => {
                form.setFieldsValue({ "otp": otp })
                setOTP(otp)
              }}
              separator={<span>-</span>}
              isInputNum={false}
              shouldAutoFocus
              value={OTP}
             renderInput={(OTP) => <input {...OTP} />}/>
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          {
            isOff ?
              <Col span={24}>
                <Typography.Text strong={true} type="success">Resend Code after {count} seconds</Typography.Text>
              </Col>
              :
              <Col span={4} offset={14}>
                <Button loading={isResendLoading} onClick={handleResend}>Resend Code</Button>
              </Col>
          }
        </Row>
      </Form.Item>
      <Row className="item-align-center">
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-btn"
            loading={isSubmitLoading}
          >
            Register
          </Button>
        </Form.Item>
        <Form.Item>
            <span className="secondary-color">
              Don’t have an account? &nbsp;
              <Link to="/auth/login" className="text-dark primary-color">
              Login
            </Link>
            </span>
        </Form.Item>
      </Row>
    </Form>
  );
}

export default SecondStep
