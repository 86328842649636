import { Layout, Row, Col } from "antd";
import { HeartFilled } from "@ant-design/icons";

function Footer() {
  const { Footer: AntFooter } = Layout;

  const d = new Date();
  let year = d.getFullYear();

  return (
    <AntFooter style={{ background: "#fafafa" }}>
      <Row className="just" style={{ width: "95%",paddingLeft: "30px"}}>
        <Col xs={24} md={12} lg={12} >
          <div className="copyright">
            © {year} , made with
            {<HeartFilled />} by
            <a
              href="https://moveon.com.bd"
              rel="noreferrer"
              className="font-weight-bold"
              target="_blank"
            >
              MoveOn Technologies Ltd
            </a>
          </div>
        </Col>
        <Col xs={24} md={12} lg={12} >
          <div className="footer-menu">
            <ul>
              <li className="nav-item hover:text-blue-500">
                <a
                  href="https://moveondrop.com"
                  className="nav-link text-muted"
                  target="_blank"
                  rel="noreferrer"
                >
                  MoveOn Drop
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://ali2bd.com/"
                  className="nav-link text-muted"
                  target="_blank"
                  rel="noreferrer"
                >
                  Ali2BD
                </a>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </AntFooter>
  );
}

export default Footer;
