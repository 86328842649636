import React, {useEffect, useState} from "react";
import {
    Button, Col, Row,
    Form,
    Input,
    Modal,
    Select
} from "antd";
import {login, register as registerUser} from "utils/auth-client"
import {LOCAL_STORAGE_KEY, LOCAL_STORAGE_USER} from "consts/storage";

const ThirdStep = Props => {
    const {step, data, isPhone} = Props;
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if(!isPhone){
            form.setFieldsValue({email: data?.email})
        }else{
            form.setFieldsValue({phone: data.phone})
        }
    },[isPhone, data, form])

    const onSubmit = (formData) => {
        setIsLoading(true)
        let newFields = {}
        if (isPhone) {
            newFields.phone = data.phone
            newFields.email = formData.email
        }
        const reqData = {
            ...{
                token: data.token
            }, ...formData
        }

        let loginData = {
            username: reqData.email,
            password : formData.password
        }

        registerUser(reqData)
            .then((response) => {
                login(loginData).then((res) => {
                    const date = new Date()
                    date.setDate(date.getDate() + 365)
                    window.localStorage.setItem(LOCAL_STORAGE_KEY, res.data.token)
                    window.localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(res.data.user))
                    window.location.reload()
                }).catch((error) => {
                    Modal.error({
                        title: 'Error Occurred',
                        content: 'Unknown error occurred'
                    });
                })
            }).catch(err => {
                if (err.response) {
                    if (err.response.status === 403) {
                        Modal.error({
                            title: 'Unauthorized',
                            content: 'The action is not authorized'
                        });
                    } else {
                        if(err.response.data && err.response.data.match_type){
                            switch (err.response.data.match_type){
                                case "email":
                                    form.setFields([
                                        {
                                            name: 'email',
                                            errors: ["Email already exits, please login"],
                                        },
                                    ]);
                                    break;
                                case "phone":
                                    form.setFields([
                                        {
                                            name: 'phone',
                                            errors: ["Phone number already exits, please login"],
                                        },
                                    ]);
                                    break;
                                default:
                                    Modal.error({
                                        title: 'Error Occurred',
                                        content: [err?.response?.data?.message ?? "Something went wrong"]
                                    });
                                    break;
                            }
                        }
                    }
                } else {
                    Modal.error({
                        title: 'Error Occurred',
                        content: 'Unknown error occurred'
                    });
                }
            }).finally(() => setIsLoading(false))
    }

    if (step !== 3) {
        return null
    }

    return(
        <Form
            name="basic"
            onFinish={onSubmit}
            className="row-col"
            form={form}
            layout="vertical"
        >
            <Row gutter={4}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} >
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[
                            { required: true, message: "Please input your name!" },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} >
                    <Form.Item
                        name="phone"
                        label="Phone"
                        rules={[
                            { required: true, message: "Please input your phone!" },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={4}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                            { required: true, message: "Please input your firstname" },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={4}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} >
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} >
                    <Form.Item
                        name="confirm"
                        label="Confirm password"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={4}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} >
                    <Form.Item
                        name="company_name"
                        label="Business name"
                        rules={[
                            { required: true, message: "Please input your business name!" },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={8} md={6} lg={12} xl={12} xxl={12} >
                    <Form.Item
                        name="company_type"
                        label="Business type"
                        rules={[
                            { required: true, message: "Please input your business type!" },
                        ]}
                    >
                        <Select
                            size="large"
                            options={[
                                {
                                    value: 'Retails Ecommerce',
                                    label: 'Retails Ecommerce',
                                },
                                {
                                    value: 'B2B',
                                    label: 'B2B',
                                },
                                {
                                    value: 'Shopping Agent',
                                    label: 'Shopping Agent',
                                },
                                {
                                    value: 'Others',
                                    label: 'Others',
                                },
                            ]}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={4}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} >
                    <Form.Item
                        name="company_mobile_number"
                        label="Business mobile number"
                        rules={[
                            { required: true, message: "Please input your business mobile number!" },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} >
                    <Form.Item
                        name="website_url"
                        label="Website url"
                        rules={[
                            { required: true, message: "Please input your website url!" },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={4}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                    <Form.Item
                        name="company_address"
                        label="Business address"
                        rules={[
                            { required: true, message: "Please input your business address!" },
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item>
                <Button
                    style={{ width: "100%" , backgroundColor:"#F37B78", borderColor:"#ffb2b2" }}
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                >
                    Register
                </Button>
            </Form.Item>
        </Form>
    );
}

export default ThirdStep