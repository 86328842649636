import React from 'react'
import { Col, Row, Table, Typography } from "antd";

const { Column } = Table

const ProductDetailsTable = Props => {
  const { invoice, products } = Props;

  const round = num => {
    return (Math.round(num * 100) / 100).toFixed(2);
  }
  return (
    <Table
      dataSource={products}
      rowKey="id"
      title={() => <span className="font-weight-700">Product Description</span>}
      loading={Props.isLoading}
      pagination={false}
      summary={() => {
        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell align={"center"} colSpan={4}>&nbsp;</Table.Summary.Cell>
              <Table.Summary.Cell align={"center"}>&nbsp;</Table.Summary.Cell>
              <Table.Summary.Cell align={"center"}>&nbsp;</Table.Summary.Cell>
              <Table.Summary.Cell align={"right"}>
                <Row gutter={18}>
                  <Col span={9}>
                    <span className={"font-weight-700"}>SubTotal:</span>
                  </Col>
                  <Col span={12}>
                    {round(invoice.amount_total)}
                  </Col>
                </Row>
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row style={{
              backgroundColor: "#88b34c",
              color: "#fff"
            }}>
              <Table.Summary.Cell align={"center"} colSpan={4}>&nbsp;</Table.Summary.Cell>
              <Table.Summary.Cell align={"center"}>&nbsp;</Table.Summary.Cell>
              <Table.Summary.Cell align={"center"}>&nbsp;</Table.Summary.Cell>
              <Table.Summary.Cell align={"right"}>
                <Row gutter={18}>
                  <Col span={9}>
                    <span className={"font-weight-700"}>Total:</span>
                  </Col>
                  <Col span={12}>
                    {round(invoice.amount_total)}
                  </Col>
                </Row>
              </Table.Summary.Cell>
            </Table.Summary.Row>

            <Table.Summary.Row>
              <Table.Summary.Cell align={"center"} colSpan={4}>&nbsp;</Table.Summary.Cell>
              <Table.Summary.Cell align={"center"}>&nbsp;</Table.Summary.Cell>
              <Table.Summary.Cell align={"center"}>&nbsp;</Table.Summary.Cell>
              <Table.Summary.Cell align={"right"}>
                <Row gutter={18}>
                  <Col span={9}>
                    <span className={"font-weight-700"}>Paid:</span>
                  </Col>
                  <Col span={12}>
                    {round(invoice.amount_paid)}
                  </Col>
                </Row>
              </Table.Summary.Cell>
            </Table.Summary.Row>
            {
              invoice.refunded_amount !== undefined && invoice.refunded_amount > 0.0 &&
              (
                <Table.Summary.Row>
                  <Table.Summary.Cell align={"center"} colSpan={4}>&nbsp;</Table.Summary.Cell>
                  <Table.Summary.Cell align={"center"}>&nbsp;</Table.Summary.Cell>
                  <Table.Summary.Cell align={"center"}>&nbsp;</Table.Summary.Cell>
                  <Table.Summary.Cell align={"right"}>
                    <Row gutter={18}>
                      <Col span={9}>
                        <span className={"font-weight-700"}>
                          Refunded:
                        </span>
                      </Col>
                      <Col span={12}>
                        {round(invoice.total_refunded)}
                      </Col>
                    </Row>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )
            }
            <Table.Summary.Row>
              <Table.Summary.Cell align={"center"} colSpan={4}>&nbsp;</Table.Summary.Cell>
              <Table.Summary.Cell align={"center"}>&nbsp;</Table.Summary.Cell>
              <Table.Summary.Cell align={"center"}>&nbsp;</Table.Summary.Cell>
              <Table.Summary.Cell align={"right"}>
                <Row gutter={18}>
                  <Col span={9}>
                    <span className={"font-weight-700"}>
                      {
                        invoice.payable_amount >= 0
                          ? <strong>Due</strong>
                          : <strong>Overpaid</strong>
                      }
                    </span>
                  </Col>
                  <Col span={12}>
                    {
                      invoice.payable_amount >= 0
                        ? <span>{round(invoice.payable_amount)}</span>
                        : <span>{-1 * round(invoice.payable_amount)}</span>
                    }
                  </Col>
                </Row>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        )
      }}
    >
      <Column
        title="SL"
        dataIndex="id"
        key="sl"
        align="center"
        responsive={['md']}
        width={'10%'}
        render={(image, record, index) => (
          index + 1
        )}
      />

      <Column
        title="Product Details"
        dataIndex="id"
        key="product_detail"
        width={'25%'}
        render={(id, record) => {
          return (
            <>
              <Row>
                <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
                  <div className="buy_title">
                    <span>
                      <span className={"font-weight-600"}>Title </span>
                      <Typography.Text
                        ellipsis={{ tooltip: record.title }}
                        style={{ width: '150px' }}
                      >{record.title}</Typography.Text>
                    </span>
                  </div>
                </Col>
              </Row>
            </>
          )
        }}
      />

      <Column
        title="Unit Type"
        dataIndex="unit_type"
        key="unit_type"
        align="center"
        width={'10%'}
      />
      <Column
        title="Quantity"
        dataIndex="quantity"
        key="quantity"
        align="center"
        width={'10%'}
      />
      <Column
        title="Weight"
        dataIndex="weight"
        key="weight"
        align="center"
        width={'10%'}
      />
      <Column
        title="Amount"
        dataIndex="amount"
        key="amount"
        align="center"
        width={'10%'}
      />
      <Column
        title="Charges"
        dataIndex="id"
        key="detail"
        width={'50%'}
        align="center"
        render={(_, record) => {
          return (
            <>
              <Table
                dataSource={record.charges}
                bordered={false}
                pagination={false}
                size="small"
                scroll={{ y: 150 }}
                summary={() => {
                  let totalCharges = ''
                  if (record?.charges) {
                    totalCharges = record?.charges.reduce((acc, value) => {
                      return acc + (value.amount)
                    }, 0)
                  }
                  return (
                    <>

                      <Table.Summary.Row>
                        <Table.Summary.Cell align={"center"}>Total</Table.Summary.Cell>
                        <Table.Summary.Cell align={"center"}>
                          <span>{totalCharges ? totalCharges : null}</span>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  )
                }}
              >
                <Column
                  title="Type"
                  dataIndex="type"
                  key="type"
                  align={"center"}
                />
                <Column
                  title="Amount"
                  dataIndex="amount"
                  key="amount"
                  align={"center"}
                />
              </Table>
            </>
          )
        }}
      />
    </Table>
  )
}

export default ProductDetailsTable
