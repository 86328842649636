import React from 'react'
import { Col, Image, Row, Table, Typography } from "antd";
import StatusTag from "components/StatusTag";

const { Column } = Table

const ProductDetailsTable = Props => {
  const { invoice, buyProducts, isLoading } = Props;

  const round = num => {
    return (Math.round(num * 100) / 100).toFixed(2);
  }

  return (
    <Table
      dataSource={buyProducts}
      rowKey="id"
      title={() => <span className="font-weight-700">Product Description</span>}
      loading={isLoading}
      pagination={false}
      scroll={{
        x: true
      }}
      summary={() => {
        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell align={"center"}>&nbsp;</Table.Summary.Cell>
              <Table.Summary.Cell align={"center"}>&nbsp;</Table.Summary.Cell>
              <Table.Summary.Cell align={"center"}></Table.Summary.Cell>
              <Table.Summary.Cell align={"right"}>
                <Row gutter={12}>
                  <Col span={5} offset={12}>
                    <span className={"font-weight-700"}>SubTotal:</span>
                  </Col>
                  <Col span={6}>
                    {round(invoice.total)}
                  </Col>
                </Row>
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row style={{
              backgroundColor: "#88b34c",
              color: "#fff"
            }}>
              <Table.Summary.Cell align={"center"}>&nbsp;</Table.Summary.Cell>
              <Table.Summary.Cell align={"center"}>&nbsp;</Table.Summary.Cell>
              <Table.Summary.Cell align={"center"}>&nbsp;</Table.Summary.Cell>
              <Table.Summary.Cell align={"right"}>
                <Row gutter={12}>
                  <Col span={5} offset={12}>
                    <span className={"font-weight-700"}>Total:</span>
                  </Col>
                  <Col span={6}>
                    {round(invoice.total)}
                  </Col>
                </Row>
              </Table.Summary.Cell>
            </Table.Summary.Row>

            <Table.Summary.Row>
              <Table.Summary.Cell align={"center"}>&nbsp;</Table.Summary.Cell>
              <Table.Summary.Cell align={"center"}>&nbsp;</Table.Summary.Cell>
              <Table.Summary.Cell align={"center"}>&nbsp;</Table.Summary.Cell>
              <Table.Summary.Cell align={"right"}>
                <Row gutter={12}>
                  <Col span={5} offset={12}>
                    <span className={"font-weight-700"}>Paid:</span>
                  </Col>
                  <Col span={6}>
                    {round(invoice.paid_amount)}
                  </Col>
                </Row>
              </Table.Summary.Cell>
            </Table.Summary.Row>
            {
              invoice.refunded_amount !== undefined && invoice.refunded_amount > 0.0 &&
              (
                <Table.Summary.Row>
                  <Table.Summary.Cell align={"center"}>&nbsp;</Table.Summary.Cell>
                  <Table.Summary.Cell align={"center"}>&nbsp;</Table.Summary.Cell>
                  <Table.Summary.Cell align={"center"}>&nbsp;</Table.Summary.Cell>
                  <Table.Summary.Cell align={"right"}>
                    <Row gutter={12}>
                      <Col span={5} offset={12}>
                        <span className={"font-weight-700"}>
                          Refunded:
                        </span>
                      </Col>
                      <Col span={6}>
                        {round(invoice.refunded_amount)}
                      </Col>
                    </Row>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )
            }
            <Table.Summary.Row>
              <Table.Summary.Cell align={"center"}>&nbsp;</Table.Summary.Cell>
              <Table.Summary.Cell align={"center"}>&nbsp;</Table.Summary.Cell>
              <Table.Summary.Cell align={"center"}>&nbsp;</Table.Summary.Cell>
              <Table.Summary.Cell align={"right"}>
                <Row gutter={12}>
                  <Col span={5} offset={12}>
                    <span className={"font-weight-700"}>
                      {
                        invoice.total >= invoice.paid_amount
                          ? <>Due</>
                          : <>Overpaid</>
                      }
                    </span>
                  </Col>
                  <Col span={6}>
                    {
                      invoice.total >= invoice.paid_amount
                        ? <span>{round(invoice.total - invoice.paid_amount)}</span>
                        : <span>{-1 * round(invoice.total)}</span>
                    }
                  </Col>
                </Row>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        )
      }}
    >
      <Column
        title="Image"
        dataIndex="image"
        key="image"
        align="center"
        responsive={['md']}
        width={'10%'}
        render={(image, record) => (
          record && record.image != null ?
            <Image
              shape="square"
              width={100}
              src={record.image}
            />
            :
            <Image
              shape="square"
              size={100}
              URL="http://placehold.jp/100x100.png"
            />
        )}
      />

      <Column
        title="Product Details"
        dataIndex="id"
        key="product_detail"
        width={'25%'}
        render={(id, record) => {
          return (
            <>
              <Row>
                <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
                  <div className="buy_title">
                    <span>
                      <span className={"font-weight-600"}>Title </span>
                      <Typography.Text
                          ellipsis={{tooltip: record.title}}
                          style={{width: '150px'}}
                        >{record.title}</Typography.Text>
                    </span>
                  </div>
                  <div className="productNumber">
                    <span>
                      <span className={"font-weight-600"}>Product Number: </span>
                      {record.product_number}
                    </span>
                  </div>
                  {
                    (record.order && record.order.order_number) &&
                    <span>
                      <span className={"font-weight-600"}>Order ID: </span>
                      {record.order.order_number}
                    </span>
                  }
                </Col>
              </Row>
            </>
          )
        }}
      />
      <Column
        title="Status"
        dataIndex="status"
        key="status"
        align={"center"}
        render={(status, record) => (
          <StatusTag slug={status} text={record.status_label} />
        )}
      />
      <Column
        title="Product Meta"
        dataIndex="id"
        key="detail"
        width={'50%'}
        align="center"
        render={(_, record) => {
          const totalQuantity = record?.itemMetas?.reduce((acc, value) => {
            if (value.qty === '') {
              value = 0
            } else {
              value = parseInt(value.qty)
            }
            return acc + (value)
          }, 0)

          const totalPriceBdt = record?.itemMetas?.reduce((acc, value) => {
            if (value.productPrice === '') {
              value = 0
            } else {
              value = parseFloat(value.productPrice * value.qty)
            }
            return acc + (value)
          }, 0)

          return (
            <>
              <Table
                dataSource={record.itemMetas}
                bordered={false}
                pagination={false}
                size="small"
                scroll={{ y: 150 }}
                summary={() => {
                  let totalCharges = ''
                  if (record?.charges) {
                    totalCharges = record.charges.reduce((acc, value) => {
                      return acc + (value.amount)
                    }, 0)
                  }
                  return (
                    <>
                      {
                        record?.charges?.map((metaCharge, index) => {
                          return (
                            <Table.Summary.Row key={index + "charges"}>
                              <Table.Summary.Cell
                                colSpan={2}>{metaCharge.type_label}</Table.Summary.Cell>
                              <Table.Summary.Cell align={"center"}>
                                <span>{(metaCharge.amount).toFixed(2)}</span>
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          )
                        })
                      }

                      <Table.Summary.Row>
                        <Table.Summary.Cell align={"center"}>Total</Table.Summary.Cell>
                        <Table.Summary.Cell align={"center"}>
                          <span>{totalQuantity}</span>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align={"center"}>
                          <span>{totalPriceBdt ? ((totalCharges ? totalCharges : null) + totalPriceBdt).toFixed(2) : null}</span>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  )
                }}
              >
                <Column
                  title="Meta"
                  dataIndex="itemMetas"
                  key="meta"
                  align={"center"}
                  width={'20%'}
                  render={(_, record) => {
                    if (!record.meta || !Array.isArray(record.meta)) {
                      return null;
                    }
                    return (
                      record.meta.map((itemVariation, itemVariationID) => {
                        return (
                          <div className="warehouse-address"
                            key={`varItem-${itemVariationID}`}>
                            <div>
                              {itemVariation.title}: <span
                                className="font-weight-bold"
                                style={{ color: "black" }}>{itemVariation.value}</span>
                            </div>
                          </div>
                        )
                      })
                    )
                  }}
                />
                <Column
                  title="Quantity"
                  dataIndex="qty"
                  key="qty"
                  align={"center"}
                  width={'10%'}
                  render={(qty, record) => (
                    record && record.qty
                  )}
                />
                <Column
                  title="Price (BDT)"
                  dataIndex="price"
                  key="price"
                  align={"center"}
                  width={'10%'}
                  render={(price, record) => {
                    return (
                      <>
                        {
                          (record?.productPrice * record.qty).toFixed(2)
                        }
                      </>
                    )
                  }
                  }
                />
              </Table>
            </>
          )
        }}
      />
    </Table>
  )
}

export default ProductDetailsTable
