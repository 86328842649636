import React, { useState } from 'react'
import { Table } from 'antd';
import { DateTime } from "components";
const ServiceQuotas = (props) => {
  const { serviceQuotas } = props;
  const [data] = useState(serviceQuotas);

  const columns = [
    {
      title: 'Service Type',
      dataIndex: 'service_type',
      width: '15%'
    },
    {
      title: 'Available Limit',
      dataIndex: 'available_limit',
      width: '10%'
    },
    {
      title: 'Expired Time',
      dataIndex: 'expired_at',
      width: '10%',
      editable: false,
      render: (expired_at) => <DateTime date={expired_at} />
    },
    {
      title: 'Last Renew time',
      dataIndex: 'last_avail_time',
      width: '10%',
      editable: false,
      render: (last_avail_time) => <DateTime date={last_avail_time} />
    },
  ];

  return (
    <Table
      bordered
      dataSource={data}
      columns={columns}
      pagination={false}
      scroll={{ x: true }}
    />
  );
}


export default ServiceQuotas
