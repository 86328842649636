import client from "./api-client"
import fileClient from "./file-client"

export const getStoreRates = () => {
    return client.get(`/customer/store-rates`)
}

export const fetchProducts = (filters, valuation, attrValues, priceRange) => {
    let apiUrl = `/customer/fetch-products?source=inventory`
    const { sortType, sortOrder } = filters.sort
    // if (filters.keyword === "") {
    //     return client.post(`/customer/fetch-products?shop_id=${filters.storeId}&keyword=bags`)
    // }

    if (filters.keyword) {
        apiUrl += `&shop_id=${filters.storeId}&keyword=${filters.keyword}`
        if (sortType && sortOrder) {
            apiUrl += `&sortType=${sortType}&sortOrder=${sortOrder}`
        }
        if (priceRange?.key) {
            apiUrl += `&${priceRange.key}=${priceRange.min}${priceRange.separator}${priceRange.max}`
        }
        if (valuation?.values?.length > 0) {
            let values = valuation.values.join(",").replaceAll(",", valuation.separator)
            apiUrl += `&${valuation.key}=${values}`;
        }
        if (attrValues?.values?.length > 0) {
            let values = attrValues.values.join(",").replaceAll(",", attrValues.separator)
            apiUrl += `&${attrValues.key}=${values}`;
        }
    }
    if (filters.page) {
        if (filters.keyword) {
            apiUrl += `&page=${filters.page}`;
        }
        if (filters.imageUuid) {
            apiUrl += `&image_search=${filters.imageUuid}&page=${filters.page}`
        }
    }
    if (filters.imageUuid) {
        apiUrl += `&image_search=${filters.imageUuid}$page=1`
    }
    return client.post(apiUrl)
}

// export const fetchProductsWithQuery = (searchValue, storeId) => {
//     return client.post(`customer/fetch-products?shop_id=${storeId}&keyword=${searchValue}`)
// }

export const getProductDetails = (link) => {
    return client.post(`/customer/fetch-product`, { url: link })
}

export const getSpecificDetails = (link)=> {
    return client.post(`customer/fetch-product-description`, { url: link })
}

export const getShippingCategories = () => {
    return client.get(`/customer/category-search`)
}

export const getShippingCategoriesWithQuery = (query) => {
    return client.get(`/customer/category-search?q=${query}`)
}

export const calculateShipmentCost = (id) => {
    return client.get(`/customer/calculate-shipment-cost?unit_type=kg&shipping_type=air&category_id=${id}&p_origin=CN`)
}

export const productListImageUpload = (formData) => {
    return fileClient.post(`/upload-image`, formData)
}

export const saveProduct = (product) => {
    return client.post(`/customer/saved-products`, product)
}

export const updateProduct = (id, product) => {
    return client.put(`/customer/saved-products/${id}`, product)
}

export const showSavedProduct = (input) => {
    let apiUrl = `/customer/saved-products`
    if (input.page) {
        apiUrl += `?page=${input.page}`
    }
    return client.get(apiUrl)
}

export const singleSavedProduct = (id) => {
    return client.get(`/customer/saved-products/${id}`)
}

export const deleteSavedProduct = (id) => {
    return client.delete(`/customer/saved-products/${id}`)
}


// export const productFetchWithSort = (filters) => {
//     const { sortType, sortOrder } = filters.sort
//     return client.post(`/customer/fetch-products?shop_id=${filters.storeId}&keyword=test&source=inventory&sortType=${sortType}&sortOrder=${sortOrder}`)
// }

export const productImageSearch = (filters) => {
    return client.post(`/customer/fetch-products?image_search=${filters.imageUuid}`)
}
