import React from "react"
import ProductDetailsTable from './ProductDetailsTable'
import TransactionsTable from 'components/TransactionsTable'
import './ShipmentInvoiceDetail.css'
import { useSettings } from "context/settings-context";
import { StatusTag } from "../index";


const ShipmentInvoiceDetail = Props => {
  const { assetURL } = useSettings();
  const { invoice, addresses, products } = Props;
  const billingAddress = addresses && addresses.billing;

  return (
    <div className="invoice">
      <div className={`invoice-wrap rounded mt-3`}>
        <div className={`invc`}>

          <div className="row">
            <div className="col">
              <div>
                <span className={"font-weight-700"}>
                  Shipment Invoice #{invoice.invoice_code}
                </span> &nbsp;
                {
                  invoice.status &&
                  (
                    <StatusTag slug={invoice.status.slug} text={invoice.status.name} />
                  )
                }

              </div>
              {
                billingAddress &&
                <div className={`invc__billing-address`}>
                  Billing to <br />
                  <span>{billingAddress.name}</span> <br />
                  <>{billingAddress.district.name}</>
                  <br />
                  <>{billingAddress.area.name}</>
                  <br />
                  <>{billingAddress.address}</>
                  <br />
                  {billingAddress.phone}
                </div>
              }
            </div>

            <div className="col offset-6">
              <div className={`invc__logo`}>
                <img src={assetURL + "/assets/siteLogo.png"} alt="MoveOn" />
              </div>
            </div>
          </div>
        </div>

        <div className="invoice-wrap__item">
          <ProductDetailsTable
            invoice={invoice}
            products={products}
            isLoading={Props.isLoading}
          />
        </div>

        <div className="invoice-wrap__item">
          <TransactionsTable
            paymentHistory={invoice.paymentHistories}
            isLoading={Props.isLoading}
            type="shipment-invoice"
          />
        </div>

        <div className="invoice-wrap__gretting text-center">
          Thank you for your purchase!
        </div>
      </div>
    </div>
  );
}
export default ShipmentInvoiceDetail;
