import React from 'react'
import { Layout, Card } from "antd";
import { ProductDetailsCostInfo, Edit } from "assets/SvgIcons";
import { domestic_shipping, local_delivery, } from "consts/products";

function CostEstimate({ variation, globalShipping, setOpenModal, totalFx, product}) {

    return (
        <Layout
            style={{
                margin: "4px 16px",
                borderRadius: "8px",
                width: "25%",
            }}
        >
            <Card style={{ boxShadow: "none", border: "1px solid #E5E7EB" }}>
                <p className="product-details-card-title">Cost Estimate</p>
                <p className="product-details-card-description">
                    The following costs are estimated using the lowest priced sku unit
                    price for the item, combined with estimated minimum logistics and
                    service charges for shipping to the USA.
                </p>
                <div style={{ borderBottom: "1px solid #DDD" }}>
                    <div className="product-details-card-price-wrapper">
                        <p className="product-details-card-price-header">
                            <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>Product prices <ProductDetailsCostInfo /></span>
                        </p>
                        <p className="product-details-card-price">
                            ৳
                            {variation.combinedPrice
                                ? (variation?.combinedPrice * totalFx).toFixed(2)
                                : (product?.price?.original?.max * totalFx).toFixed(2)}
                        </p>
                    </div>
                    <div className="product-details-card-price-wrapper">
                        <p className="product-details-card-price-header">
                            <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>Domestic Shipping <ProductDetailsCostInfo /></span>
                        </p>
                        <p className="product-details-card-price">৳{domestic_shipping}</p>
                    </div>
                    <div className="product-details-card-price-wrapper">
                        <p className="product-details-card-price-header">
                            <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }} >Global Shipping <ProductDetailsCostInfo /></span>
                        </p>
                        <p onClick={() => setOpenModal(true)}>
                            {globalShipping.price ? <>
                                {
                                    <span>{`৳${(globalShipping.price).toFixed(2)}`}</span>} <span className="product-details-card-editicon" onClick={() => setOpenModal(true)}> <Edit />
                                </span></> : <span
                                    style={{
                                        color: "#1D9BF0",
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                    }}
                                >Set now</span>
                            }
                        </p>
                    </div>
                    <div className="product-details-card-price-wrapper">
                        <p className="product-details-card-price-header">
                            <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>Local delivery <ProductDetailsCostInfo /></span>
                        </p>
                        <p className="product-details-card-price">৳{local_delivery}</p>
                    </div>
                    <div className="product-details-card-price-wrapper">
                        <p className="product-details-card-price-header"><span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>Platform fees <ProductDetailsCostInfo /></span></p>
                        <p className="product-details-card-price">৳0</p>
                    </div>
                </div>
                <div>
                    <div className="product-details-card-subtotal-price-wrapper">
                        <p className="product-details-card-price-header">Subtotal</p>
                        <p className="product-list-card-price-subtotal">
                            ৳
                            {variation.combinedPrice
                                ? (
                                    variation?.combinedPrice * totalFx +
                                    domestic_shipping +
                                    local_delivery + globalShipping.price
                                ).toFixed(2)
                                : (
                                    product?.price?.original?.max * totalFx +
                                    domestic_shipping +
                                    local_delivery + globalShipping.price
                                ).toFixed(2)}
                        </p>
                    </div>
                </div>
            </Card>
        </Layout>
    )
}

export default CostEstimate
