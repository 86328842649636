import React from 'react'
import RequestProgressTrack from '../RequestProgressTrack'
import {calculateShippingPrice, formatDate, formatTime, useWindowSize} from "utils/helpers";
import {Button, Image, Spin, Table, Tooltip, Typography} from "antd";
import {StatusTag} from "components";
import './RequestDetail.css'

const {Column} = Table;

const RequestDetail = Props => {
    const {currentStatus, requestStatusLogs, products, isProductsLoading, isLogLoading, setShowOrdersModal, setSelectedCorrelatedItemId,setSelectedProductItemData,setShowRefundModal} = Props
    const windowSize = useWindowSize();

    const calculateEstimatedCost =(weight, qty, cost)=> {
          const perItemWeight = weight / qty;
          return (perItemWeight * cost).toFixed(2);
      }

    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

  return (
        <Spin spinning={isLogLoading || isProductsLoading}>
            <div className={`product-wrap-inner`}>
                <div className={`delivery-request`}>
                    <div className={`delivery-request__progessess`}>
                        <RequestProgressTrack
                            currentStatus={currentStatus}
                        />

                    </div>
                    {
                        requestStatusLogs &&
                        <div className={`delivery-request__table-wrap`}>
                            <div className={`delivery-request__table-wrap__title`}>
                                {requestStatusLogs[0] ? requestStatusLogs[0].instruction : null}
                            </div>
                            {
                                windowSize.width > 991
                                    ? <div className="table-responsive">
                                        <table className={`table table`}>
                                            <tbody>
                                            {
                                                requestStatusLogs.map((log, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <th>{formatDate(log.created_at)}</th>
                                                            <th>{formatTime(log.created_at)}</th>
                                                            <th>{log.instruction}</th>
                                                        </tr>
                                                    );
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                    : <div className="tract-summery-content mt-3">
                                        {
                                            requestStatusLogs && requestStatusLogs.map((log, index) => {
                                                return (
                                                    <div className={`tract-summery-block mb-2`} key={index}>
                                                        <div className={`track-date-time`}>
                                                            <span
                                                                className="font-weight-bold">{formatDate(log.created_at)} at {formatTime(log.created_at)}</span>
                                                        </div>
                                                        <div className={`track-message ml-1`}>
                                                            {log.instruction}
                                                            {
                                                                // log.images &&
                                                                // <ul className={`${RequestDetailStyle["product-images"]}  d-flex align-items-center flex-wrap p-0 mt-2`}>
                                                                //     <LightBoxImages images={log.images} />
                                                                // </ul>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                            }
                        </div>
                    }

                    <ul className={`delivery-request-products`}>
                        <Table
                            dataSource={products}
                            pagination={false}
                            loading={isProductsLoading}
                            rowKey={'id'}
                            scroll={{
                                x: true
                            }}
                        >
                            <Column
                                title="Image"
                                dataIndex="product_number"
                                key="product_number"
                                align="center"
                                render={(product_number, record) => (
                                    <Image
                                        shape="square"
                                        width={100}
                                        height={100}
                                        src={record.image}
                                    />
                                )}
                            />

                            <Column
                                title="Product"
                                dataIndex="title"
                                key="title"
                                align={"center"}
                                render={(title, product) => (
                                    <div style={{textAlign: "left"}}>
                                      <div className="buy_title">
                                        <span className={"font-weight-600"}>Title: </span>
                                        <a href={product.link} target="_blank" rel="noreferrer">
                                          <Typography.Text
                                            ellipsis={{ tooltip: title }}
                                            style={{ width: '150px' }}
                                          >
                                            {title}
                                          </Typography.Text>
                                        </a>
                                      </div>
                                        {
                                            product.order &&
                                            (
                                                <div>
                                                    <span className={"font-weight-600"}>Order: </span>{product.order.order_number}<br/>
                                                    <span className={"font-weight-600"}>Product ID: </span>{product.product_number}<br/>
                                                    <span className={"font-weight-600"}>Shipment ID: </span>{product.shipmentProduct.product_number}<br/>
                                                </div>
                                            )
                                        }
                                        <div>
                                            <span
                                                className={"font-weight-600"}>Per item shipment cost: </span>{calculateEstimatedCost(product?.shipmentProduct?.weight, product?.shipmentProduct?.quantity, product?.shipmentProduct?.rate_per_unit)}
                                        </div>

                                        <div>
                                            <span
                                                className={"font-weight-600"}>Tracking code: </span>{product.abroad_courier_tracking}
                                        </div>

                                        <div>
                                            <span
                                                className={"font-weight-600"}>Shipping & Customs:</span> {calculateShippingPrice(product?.shipping?.rate, product?.shipping?.commission)} /-{product?.shipping?.unit_type && product.shipping.unit_type}
                                        </div>
                                        <div>
                                            <span
                                                className={"font-weight-600"}>Weight:</span> {product?.shipmentProduct?.weight+" "+product?.shipmentProduct?.unit_type}
                                        </div>
                                    </div>

                                )}
                            />
                            <Column
                                title="Product Meta"
                                dataIndex="id"
                                key="detail"
                                width={'35%'}
                                align="center"
                                render={(_, record) => {

                                    const totalQuantity = record?.itemMetas?.reduce((acc, value) => {
                                        if (value.qty === '') {
                                            value = 0
                                        } else {
                                            value = parseInt(value.qty)
                                        }
                                        return acc + (value)
                                    }, 0)

                                    const totalPriceBdt = record?.itemMetas?.reduce((acc, value) => {
                                        if (value.productPrice === '') {
                                            value = 0
                                        } else {
                                            value = parseFloat(value.productPrice * value.qty)
                                        }
                                        return acc + (value)
                                    }, 0)

                                    return (
                                        <>
                                            <Table
                                                dataSource={record.itemMetas}
                                                bordered={false}
                                                pagination={false}
                                                size="small"
                                                scroll={{y: 150}}
                                                summary={() => {

                                                    let totalCharges = ''
                                                    if (record?.charges) {
                                                        totalCharges = record?.charges.reduce((acc, value) => {
                                                            return acc + (value.amount)
                                                        }, 0)
                                                    }
                                                    return (
                                                        <>

                                                            {
                                                                record?.charges?.map((metaCharge, index) => {
                                                                    return (
                                                                        <Table.Summary.Row key={index + "charges"}>
                                                                            <Table.Summary.Cell
                                                                                colSpan={3}>{metaCharge.type_label}</Table.Summary.Cell>
                                                                            <Table.Summary.Cell align={"center"}>
                                                                                <span>{(metaCharge.amount).toFixed(2)}</span>
                                                                            </Table.Summary.Cell>
                                                                        </Table.Summary.Row>
                                                                    )
                                                                })
                                                            }

                                                            <Table.Summary.Row>
                                                                <Table.Summary.Cell align={"center"}>Buying Total</Table.Summary.Cell>
                                                                <Table.Summary.Cell align={"center"}>
                                                                    <span>{totalQuantity}</span>
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell align={"center"}>&nbsp;</Table.Summary.Cell>
                                                                <Table.Summary.Cell align={"center"}>
                                                                    <span>{totalPriceBdt ? ((totalCharges ? totalCharges : null) + totalPriceBdt).toFixed(2) : null}</span>
                                                                </Table.Summary.Cell>
                                                            </Table.Summary.Row>
                                                          <Table.Summary.Row>
                                                                <Table.Summary.Cell align={"center"}>Shipping Total</Table.Summary.Cell>
                                                                <Table.Summary.Cell align={"center"}>
                                                                    <span>{record?.shipmentProduct?.weight}</span>
                                                                </Table.Summary.Cell>
                                                                <Table.Summary.Cell align={"center"}>{record?.shipmentProduct?.rate_per_unit}</Table.Summary.Cell>
                                                                <Table.Summary.Cell align={"center"}>
                                                                    <span>{record.shipmentProduct.weight * record.shipmentProduct.rate_per_unit}</span>
                                                                </Table.Summary.Cell>
                                                            </Table.Summary.Row>
                                                          <Table.Summary.Row>
                                                                <Table.Summary.Cell align={"center"}>Total</Table.Summary.Cell>
                                                                <Table.Summary.Cell align={"center"}>&nbsp;</Table.Summary.Cell>
                                                                <Table.Summary.Cell align={"center"}>&nbsp;</Table.Summary.Cell>
                                                                <Table.Summary.Cell align={"center"}>
                                                                    <span>{(record.shipmentProduct.weight * record.shipmentProduct.rate_per_unit )+ parseInt(totalPriceBdt ? ((totalCharges ? totalCharges : null) + totalPriceBdt).toFixed(2) : null)}</span>
                                                                </Table.Summary.Cell>
                                                            </Table.Summary.Row>
                                                        </>
                                                    )
                                                }}
                                            >
                                                <Column
                                                    title="Meta"
                                                    dataIndex="itemMetas"
                                                    key="meta"
                                                    align={"center"}
                                                    width={'20%'}
                                                    render={(meta, record) => {
                                                        return (
                                                            record.meta.map((itemVariation, itemVariationID) => {
                                                                return (
                                                                    <div className="warehouse-address ml-lg-4"
                                                                         key={`varItem-${itemVariationID}`}>
                                                                        <div>
                                                                            {itemVariation.title}: <span
                                                                            className="font-weight-bold"
                                                                            style={{color: "black"}}>{itemVariation.value}</span>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        )
                                                    }}
                                                />
                                                <Column
                                                    title="Quantity"
                                                    dataIndex="qty"
                                                    key="qty"
                                                    align={"center"}
                                                    width={'10%'}
                                                    render={(qty, record) => (
                                                        record && record.qty
                                                    )}
                                                />
                                                <Column
                                                    title="Unit Price"
                                                    dataIndex="productPrice"
                                                    key="unit_price"
                                                    align={"center"}
                                                    width={'10%'}
                                                    render={productPrice => (
                                                        productPrice.toFixed(2)
                                                    )}
                                                />
                                                <Column
                                                    title="Total"
                                                    dataIndex="price"
                                                    key="price"
                                                    align={"center"}
                                                    width={'10%'}
                                                    render={(price, record) => {
                                                        return (
                                                            (record?.productPrice * record.qty).toFixed(2)
                                                        )
                                                    }
                                                    }
                                                />
                                            </Table>
                                        </>
                                    )
                                }}
                            />

                            <Column
                                title="Category"
                                dataIndex="shipping"
                                key="category"
                                align={"center"}
                                render={shipping => (
                                    shipping ?
                                        <span>{shipping.category}</span>
                                        :
                                        <></>
                                )}
                            />

                            <Column
                                title="Status"
                                dataIndex="status"
                                key="status"
                                align={"center"}
                                style={{width: 200}}
                                render={(status, product) => (
                                    <StatusTag text={product.status_label.toUpperCase()} slug={product.status}/>
                                )}
                            />
                            <Column
                                title="Actions"
                                dataIndex="id"
                                key="actions"
                                align={"center"}
                                render={(id, row) => {
                                    return <>
                                        <Tooltip title="View Details">
                                            <Button
                                                onClick={() => Props.handlePageAction("product-detail", row)}
                                                shape="round"
                                            >Detail</Button>
                                        </Tooltip>
                                        <Tooltip title="View Correlated Orders">
                                            <Button
                                                onClick={() => {
                                                    setShowOrdersModal(true)
                                                    setSelectedCorrelatedItemId(row)
                                                }}
                                                shape="round"
                                                style={{margin: "5px"}}
                                            >Orders</Button>
                                        </Tooltip>
                                      {
                                        row.status === "delivered-to-customer" &&
                                        requestStatusLogs &&
                                        requestStatusLogs[0] &&
                                        requestStatusLogs[0].created_at !== null && (
                                          <Tooltip title="Create Refund within 7 days">
                                            <Button
                                              onClick={() => {
                                                setShowRefundModal(true);
                                                setSelectedProductItemData(row);
                                              }}
                                              shape="round"
                                              disabled={
                                                new Date(requestStatusLogs[0].created_at) <= sevenDaysAgo
                                              }
                                              style={{ marginRight: "5px" }}
                                            >
                                              Refund Request
                                            </Button>
                                          </Tooltip>
                                        )
                                      }

                                      {
                                            row.order &&
                                            (
                                                <Tooltip title="View Invoice">
                                                    <Button
                                                        onClick={() => Props.handlePageAction("buy-invoice", null, row.order)}
                                                        shape="round"
                                                        type={"primary"}
                                                        style={{marginTop: 10}}
                                                    >Invoice</Button>
                                                </Tooltip>
                                            )
                                        }

                                    </>

                                }}
                            />
                        </Table>
                    </ul>
                </div>
            </div>
        </Spin>
    )
}

export default RequestDetail