
import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  Descriptions,
  Avatar,
  message,
} from "antd";

import {
  VerticalAlignTopOutlined,
} from "@ant-design/icons";

import BgProfile from "../assets/images/bg-profile.jpg";
import { getProfile } from "../utils/customer-client";
import { toastErrors } from "../utils/helpers";

function Profile() {
  const [imageURL, setImageURL] = useState(false);
  const [, setLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState(null);
  const [isProfileIsLoading, setIsProfileIsLoading] = useState(false);

  console.log(profileInfo, "profileInfo")

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const fetchProfile = React.useCallback(
    () => {
      setIsProfileIsLoading(true)
      getProfile()
        .then(res => {
          if (res && res.data && res.data.user) {
            setProfileInfo(res.data.user);
          }
        }).catch(({ response }) => {
          if (response?.data) {
            toastErrors(response.data);
          }
        }).finally(() => setIsProfileIsLoading(false))

    },
    [],
  )

  useEffect(() => {
    fetchProfile()
  }, [fetchProfile])

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(false);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setLoading(false);
        setImageURL(false);
      });
    }
  };

  const uploadButton = (
    <div className="ant-upload-text font-semibold text-dark">
      {<VerticalAlignTopOutlined style={{ width: 20, color: "#000" }} />}
      <div>Upload New Project</div>
    </div>
  );

  return (
    <>
      <div
        className="profile-nav-bg"
        style={{ backgroundImage: "url(" + BgProfile + ")", marginTop: "24px" }}
      >
      </div>
      <Card
        className="card-profile-head"
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                <Avatar size={74} shape="square" src={profileInfo?.extra_information?.avatar} />

                <div className="avatar-info">
                  <h4 className="font-semibold m-0">{profileInfo?.name}</h4>
                  <p>{profileInfo?.email}</p>
                </div>
              </Avatar.Group>
            </Col>
          </Row>
        }
      ></Card>

      <Row gutter={[24, 0]}>
        <Col span={24} md={8} className="mb-24">
          <Card
            bordered={false}
            title={<h6 className="font-semibold m-0">Profile Information</h6>}
            className="header-solid h-full card-profile-information"
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
          >
            <Descriptions title="Emergency Info">
              <Descriptions.Item label="Full Name" span={3}>
                {profileInfo?.name}
              </Descriptions.Item>
              <Descriptions.Item label="Mobile" span={3}>
                {profileInfo?.phone}
              </Descriptions.Item>
              <Descriptions.Item label="Email" span={3}>
                {profileInfo?.email}
              </Descriptions.Item>
              <Descriptions.Item label="Shipping Mark" span={3}>
                {profileInfo?.shipping_mark}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Profile;
