import React, { createContext, useCallback, useState } from 'react'
import { Form } from "antd";
import { toastErrors } from 'utils/helpers';

const SettingsContext = createContext()

const SettingsProvider = Props => {
  const [settings,] = useState({
    secret_token: null,
    available_product_status: [],
    catalog_visibility_option: [],
    categories: [],
    tags: [],
    plus_value_type: [],
    manage_stock: true,
    external_images: true,
    default_moveon_category: null,
    default_moveon_origin: null,
    default_moveon_aw_category: null
  });
  const [isSettingsFetching,] = useState(false);
  const [websiteList, setWebsiteList] = useState(false);
  const [isSettingsSaving,] = useState(false);
  const [saleAction, setSaleAction] = useState("fixed");
  const [regularAction, setRegularAction] = useState("fixed");
  const [showSyncIntervalInput, setShowSyncIntervalInput] = useState(false);
  const [form] = Form.useForm();
  const [assetURL,] = useState(window.location.protocol + "//" + window.location.host)
  const [isWebsiteLoading, setIsWebsiteLoading] = useState(false);


  const getWebsites = useCallback(
    () => {
      setIsWebsiteLoading(true)
      getWebsites()
        .then(res => {
          setWebsiteList(res.data)
        }).catch(({response}) => {
          if (response?.data) {
            toastErrors(response.data)
          }
        }).finally(() => setIsWebsiteLoading(false))
    },
    [],
  )

  const getAction = action_type => {
    let value_label_left = "";
    let value_label_right = "";
    switch (action_type) {
      case 'fixed':
        value_label_left = '+';
        value_label_right = '৳';
        break;
      case 'percent':
        value_label_left = '+';
        value_label_right = '%';
        break;
      case 'multiply':
        value_label_left = 'x';
        value_label_right = '';
        break;
      default:
        value_label_left = '=';
        value_label_right = '৳';
    }
    return {
      value_label_left: value_label_left,
      value_label_right: value_label_right
    }
  };

  return (
    <SettingsContext.Provider
      value={{
        settings,
        isSettingsFetching,
        isSettingsSaving,
        form,
        saleAction,
        setSaleAction,
        regularAction,
        setRegularAction,
        getAction,
        showSyncIntervalInput,
        setShowSyncIntervalInput,
        assetURL,
        isWebsiteLoading,
        websiteList,
        getWebsites
      }}
    >
      {Props.children}
    </SettingsContext.Provider>
  );
}

const useSettings = () => {
  const context = React.useContext(SettingsContext)
  if (context === undefined) {
    throw new Error(`useSettings must be used within a SettingsProvider`)
  }
  return context
}

export { SettingsProvider, useSettings }
