import React from "react"
import {Route, Switch} from "react-router-dom"
import {PageNotFound} from "../Generic"
import Login from './Login'
import {RouteWithOutAuthLayout} from "components";
import AuthLayout from "components/layout/AuthLayout";
import ForgetPassword from "./ForgetPassword";
import ResetPassword from "./ResetPassword";
import ActingHandle from "./ActingHandle";
import Register from "./Register";

const AuthRoutes = () => {
    return (
        <Switch>
            <RouteWithOutAuthLayout
                component={Login}
                layout={AuthLayout}
                path="/auth/login"
            />
            <RouteWithOutAuthLayout
                component={ForgetPassword}
                layout={AuthLayout}
                path="/auth/forget-password"
            />
            <RouteWithOutAuthLayout
                component={ActingHandle}
                layout={AuthLayout}
                path="/auth/acting/:token"
            />
            <RouteWithOutAuthLayout
                component={ResetPassword}
                layout={AuthLayout}
                path="/auth/reset-password"
            />
            <RouteWithOutAuthLayout
                component={Register}
                layout={AuthLayout}
                path="/auth/register"
            />
            <Route component={PageNotFound} />
        </Switch>
    );
}
export default AuthRoutes