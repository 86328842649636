import React from "react"
import './RequestProgressTrack.css'
import {getDeliveryStatuses} from 'consts/statuses'

const RequestProgressTrack = Props => {
    const {currentStatus} = Props
    const statuses = getDeliveryStatuses();
    const whereToStop = status => {
        let pos = 0;
        if (status === 'delivery-attempt-failed') {
            pos = 3;
        } else {
            statuses.forEach(item => {
                if (item.key === status) {
                    pos = item.index;
                }
            })
        }
        return pos;
    }
    const isChecked = (index) => {
        let stopAt = whereToStop(currentStatus);
        return index <= stopAt;
    }
    return (
        <div className={`product-progress`}>
            <ul className={`progress-wrap d-flex flex-column flex-lg-row align-items-lg-center text-lg-center justify-content-lg-between`}>
                {
                    statuses && statuses.map((status, index) => {
                        return (
                            <li className={`progress-wrap__item`} key={index}>
                                <input type="checkbox" id="ReqCreated" name="ReqCreated" value="ReqCreated"
                                       checked={isChecked(index)} onChange={() => {
                                }}/>
                                <label htmlFor="ReqCreated">{status.name}</label>
                            </li>
                        );
                    })
                }
            </ul>
        </div>
    )
}
export default RequestProgressTrack
