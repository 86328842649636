import { getUser } from './auth-client'
import { LOCAL_STORAGE_USER } from 'consts/storage'

async function bootstrapAppData() {
	const user = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_USER))
	if (user) {
		return { user }
	}
	const data = await getUser()
	if (!data) {
		return { user: null }
	}
	return {
		user: data.data
	}
}

export { bootstrapAppData }
