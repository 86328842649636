export const stores = [
    {
        id: 1,
        name: "Aliexpress",
    }
    ,
    {
        id: 4,
        name: "Taobao",
    },
    {
        id: 10,
        name: "1688",
    },
    {
        id: 11,
        name: "Alibaba",
    },

]