export const FALLBACK_IMAGE_URL =
  "https://i.ibb.co/VDvYS53/output-onlinepngtools.png";

export const SUPER_ADMIN_SLUG = "super-admin";
export const ORDER_HANDLER_SLUG = "Order Handler";
export const SIDENAV_BAR_COLOR = "transparent";
export const OFFICE_PICK_UP = {
  id: 106,
  name: "Ali2BD",
  phone: "09666783333",
  country: "Bangladesh",
  district: {
    id: 13,
    parent_id: null,
    slug: "dhaka",
    name: "Dhaka",
    postal_code: null,
    type: "district",
  },
  area: {
    id: 307,
    parent_id: 13,
    slug: "mirpur-dohs",
    name: "Mirpur DOHS",
    postal_code: 1216,
    type: "area",
  },
  address: "Plot 1020, Road, 9 Avenue 9",
  postal_code: 1216,
  is_default_shipping: 1,
  is_default_billing: 1,
};

export const LOCAL_STORAGE_AUTH_PARAMS = "moveon_drop_wp_auth_params";
export const LOCAL_STORAGE_APP_SECRET = "moveon_drop_wp_app_secret";
export const LOCAL_STORAGE_NONCE_VALUE = "moveon_drop_wp_nonce_value";

export const gateways = {
  Wallet: "wallet",
  SSLCommerz: "ssl-commerz",
  Nagad: "nagad",
  BKash: "bkash",
  DBBL: "dbbl",
  Rocket: "rocket-merchant",
};

export const gatewayType = {
  RegularGateway: "regular-gateway",
  BankDeposit: "bank-deposit",
};

export const settingsProductNotAvailableOptions = [
  {
    label: "Do nothing",
    value: "",
  },
  {
    label: "Set product out-of-stock",
    value: "outofstock",
  },
  {
    label: "Change product status to Draft",
    value: "draft",
  },
  {
    label: "Change product status to Pending",
    value: "pending",
  },
  {
    label: "Change product status to Private",
    value: "private",
  },
  {
    label: "Trash product",
    value: "trash",
  },
];

export const settingsVariationIsNotAvailableOptions = [
  {
    label: "Do nothing",
    value: "",
  },
  {
    label: "Set variation out-of-stock",
    value: "outofstock",
  },
  {
    label: "Disable",
    value: "disable",
  },
];

export const settingsSendEmailOptions = [
  {
    label: "A product is no longer available",
    value: "is_offline",
  },
  {
    label: "A product is out of stock",
    value: "is_out_of_stock",
  },
  {
    label: "A product's price changes",
    value: "price_changes",
  },
  {
    label: "Disable",
    value: "disable",
  },
];

export const BUY_AND_SHIP = "buy-and-ship";
export const SHIP_FOR_ME = "ship-for-me";
