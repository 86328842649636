import React, { useCallback, useEffect, useState } from 'react'
import { OFFICE_PICK_UP } from "../../consts/app-constants";
import { Button, Card, Col, Drawer, message, Radio, Row, Spin, } from "antd";
import { createDeliveryRequest, getCouriers } from 'utils/buy-order-client'
import { getDefaultAddress } from "utils/customer-client";
import './NewDeliveryRequest.css'
import { AddressSelect } from "../index";
import { toastErrors } from 'utils/helpers';

const NewDeliveryModal = Props => {
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedCourier, setSelectedCourier] = useState(null);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [couriers, setCouriers] = useState([]);
  const [isCourierLoading, setIsCourierLoading] = useState(false);
  const [isDefaultAddressLoading, setIsDefaultAddressLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const fetchCouriers = useCallback(
    () => {
      setIsCourierLoading(true);
      getCouriers().then(res => {
        if (res && res.data && res.data.data) {
          setCouriers(res.data.data.map(item => {
            return { label: item.name, value: item.id }
          }))
        }
      }).catch(({ response }) => {
        if (response?.data) {
          toastErrors(response.data);
        }
      }).finally(() => setIsCourierLoading(false))
    },
    [],
  )

  const fetchDefaultAddrsses = React.useCallback(
    () => {
      setIsDefaultAddressLoading(true)
      getDefaultAddress()
        .then(res => {
          if (res && res.data && res.data.data) {
            setSelectedAddress(res.data.data.shipping);
          }
        }).catch(({ response }) => {
          if (response?.data) {
            toastErrors(response.data);
          }
        }).finally(() => setIsDefaultAddressLoading(false))

    },
    [],
  )

  useEffect(() => {
    fetchCouriers()
    fetchDefaultAddrsses()
  }, [fetchCouriers, fetchDefaultAddrsses])


  const handleSelectedAddress = (type, address) => {
    if (selectedCourier === 1) {
      setSelectedAddress(OFFICE_PICK_UP)
    } else {
      setSelectedAddress(address);
    }
  }

  const handleSubmit = () => {
    if (!selectedAddress || !selectedCourier) {
      if (!selectedAddress) {
        message.warning('Please select an address!')
      }
      if (!selectedCourier) {
        message.warning('Please select a courier!')
      }
      return;
    }

    let products = Props.products
    let address = selectedAddress
    setIsSubmitLoading(true)

    let data = {
      type: "buy-and-ship",
      deliver_to: address.name,
      primary_phone: address.phone,
      deliver_by: selectedCourier,
      delivery_country: "Bangladesh",
      delivery_district: address.district.id,
      delivery_area: address.area.id,
      delivery_address: address.address,
      delivery_postal_code: address.postal_code,
      products: products
    }

    createDeliveryRequest(data)
      .then(res => {
        message.success('New Request is created', 'Delivery Request');
        window.location.reload();
      }).catch(({ response }) => {
        if (response?.data) {
          toastErrors(response.data);
        }
      }).finally(() => setIsSubmitLoading(false))

  }

  const handleClear = () => {
    setSelectedAddress(null);
    setSelectedCourier(false);
  }

  return (
    <Drawer
      width={640}
      placement="right"
      title={"New delivery request"}
      closable={false}
      onClose={Props.onHide}
      visible={Props.show}
      footer={
        <div
          style={{
            textAlign: 'left',
          }}
        >
          <Button onClick={Props.onHide} type={"primary"} style={{ marginRight: 8 }}>
            Close
          </Button>
        </div>
      }
    >
      <h3>Couriers</h3>
      <Row style={{ marginBottom: 20 }}>
        <Col span={24}>
          <Spin tip="Loading..." spinning={isCourierLoading}>
            <Radio.Group
              options={couriers}
              onChange={(event) => {
                setSelectedCourier(event.target.value)
              }}
              optionType="button"
              buttonStyle="solid"
            />
          </Spin>
        </Col>
      </Row>
      <h3>Addresses</h3>
      <Row>
        <Col span={12}>
          <Spin tip="Loading..." spinning={isDefaultAddressLoading}>
            {
              selectedAddress ?
                (
                  <Card className="moveon-address address-card">
                    <div className="address-item">
                      {selectedAddress.name}
                    </div>
                    <div className="address-item">
                      {selectedAddress.phone}
                    </div>
                    {
                      selectedAddress.district && selectedAddress.district.name &&
                      (
                        <div className="address-item">
                          <span>District:</span>
                          {selectedAddress.district?.name}
                        </div>
                      )
                    }
                    {
                      selectedAddress.area && selectedAddress.area.name &&
                      (
                        <div className="address-item">
                          <span>Area:</span>
                          {selectedAddress.area?.name}
                        </div>

                      )
                    }
                    {
                      <div className="address-item">
                        <span>Address:</span>
                        {selectedAddress.address}
                      </div>
                    }
                    {
                      <div className="address-item">
                        <span>Postal Code:</span>
                        {selectedAddress.postal_code}
                      </div>
                    }
                  </Card>
                ) :
                <span>Shipping address not found</span>
            }
          </Spin>
        </Col>
        <Col span={12}>
          <Card style={{ minHeight: 203 }} className={"address-card"}>
            <Button type="primary" onClick={() => setShowAddressModal(true)}>
              {selectedAddress ? "Change Address" : "Add address"}
            </Button>
          </Card>
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col span={24}>
          <div className="expected-time">
            <div className="days">
              {
                selectedCourier === 1
                  ?
                  <>
                    <span>Please collect your product from our office location,</span> <br />
                    <span>When delivery status is <span style={{ color: 'red', fontWeight: 'bold' }}>Ready For Delivery </span></span>
                  </>
                  :
                  <span>Expected Delivery Time: 24 to 48 hours</span>
              }
            </div>

            <div className="info">
              <span>?</span>Conditions Apply
            </div>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col span={24}>
          <Button
            type="primary"
            onClick={handleSubmit}
            style={{ marginRight: 10 }}
            loading={isSubmitLoading}
          >
            Submit
          </Button>
          <Button onClick={handleClear}>Clear</Button>
        </Col>
      </Row>

      {
        showAddressModal &&
        <AddressSelect
          show={showAddressModal}
          onHide={() => setShowAddressModal(false)}
          type={"Shipping"}
          handleAddressSelect={handleSelectedAddress}
        />
      }

    </Drawer>
  )
}

export default NewDeliveryModal
