import client from './api-client'
import Qs from 'qs'

export const getUsers = (filter = null) => {
    let data = {};
    if (filter) {
        data = {...data, ...filter}
    }
    return client.get('/order/users', {
        params: data,
        paramsSerializer: function (params) {
            return Qs.stringify(params, {arrayFormat: 'brackets'})
        },
    })
}
export const placeOrder = (data) => {
    return client.post(`order/place`, data)
}

export const placeGroupOrder = (data) => {
    return client.post(`order/group-order`, data)
}

export const getIssues = (page = null, filter, perPage = 10) => {
    let data = {
        page: page ? page : 1,
        per_page: perPage
    }

  if (filter) {
    data = {...data, ...filter}
  }

    return client.get(`/customer/issues`,{
        params: data,
        paramsSerializer: function (params) {
            return Qs.stringify(params, {arrayFormat: 'brackets'})
        },
    })
}

export const manualOrderPlace = (payload)=> {
    return client.post(`/customer/buy-ship/orders-by-products`,payload)
}