import React, { useState } from "react";
import {Link, NavLink} from "react-router-dom";
import {
  Button,
  Typography,
  Form,
  Input,
  Switch,
  Modal,
  Row,
  Col, Divider
} from "antd";
import { useAuth } from "context/auth-context";
import { LOCAL_STORAGE_KEY, LOCAL_STORAGE_USER } from "consts/storage";
import logo from "../../../assets/logo.png";
const { Text } = Typography;


const { Title } = Typography;

const Login = () => {
  const { login } = useAuth();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = (values) => {
    setIsLoading(true)
    login(values)
      .then((res) => {
        setIsLoading(false)
        window.localStorage.setItem(LOCAL_STORAGE_KEY, res.data.token)
        window.localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(res.data.user))
        window.location.reload()
      }).catch(err => {
        setIsLoading(false)
        if (err.response) {
          if (err.response.status === 403) {
            Modal.error({
              title: 'Unauthorized',
              content: 'The action is not authorized'
            });
          } else {
            if (err.response.data && err.response.data.type) {
              switch (err.response.data.type) {
                case "user":
                  form.setFields([
                    {
                      name: 'username',
                      errors: [err.response.data.message ?? "Something is wrong with username"],
                    },
                  ]);
                  break;
                case "password":
                  form.setFields([
                    {
                      name: 'password',
                      errors: [err.response.data.message ?? "Something is wrong with password"],
                    }
                  ]);
                  break;
                default:
                  form.setFields([
                    {
                      name: 'phone',
                      errors: [err.response.data.message ?? "Something is wrong with password"],
                    }
                  ]);
                  break;
              }
            }
          }
        } else {
          Modal.error({
            title: 'Error Occurred',
            content: 'Unknown error occurred'
          });
        }
      })
  }

  return (
    <>
      <div className="login-card">
      <NavLink to={"/"}>
        <img className="brand-logo" src={logo} alt=""/>
      </NavLink>
      <Title className="mb-30" level={4}>Login</Title>
      <Text style={{whiteSpace:"normal"}} type="secondary">Welcome back! Login with your registered email address</Text>
      <Divider />

      <Form
        onFinish={onSubmit}
        layout="vertical"
        className="row-col"
        form={form}
      >
        <Form.Item
          className="username custom-label-color"
          label="Email Address"
          name="username"
          rules={[
            {
              required: true,
              message: 'Please input your Email!',
            },
          ]}
        >
          <Input placeholder="Email"/>
        </Form.Item>

        <Form.Item
          className="password custom-label-color"
          label="Password"
          name="password"
          style={{color:"#787E87",fontWeight:500}}
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>


        <Row justify="center">
          <Col span={12}>
            <Form.Item
              name="remember_me"
              className="aligin-center"
              valuePropName="checked"
            >
              <Switch
                style={{ backgroundColor: "#F37B78" }}
                defaultChecked />
              <span style={{ color: '#787E87' }}>Remember me</span>
            </Form.Item>
          </Col>
          <Col span={12} style={{textAlign:"right"}}>
            <p className="">
              <Link to="/auth/forget-password" className="text-dark secondary-color">
                Forget password ?
              </Link>
            </p>
          </Col>
        </Row>
        <Row className="item-align-center">
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-btn"
              loading={isLoading}
            >
              LOGIN
            </Button>
          </Form.Item>

          <Form.Item>
            <span className="secondary-color">
              Don’t have an account? &nbsp;
              <Link to="/auth/register" className="text-dark primary-color">
              Register
            </Link>
            </span>
          </Form.Item>
        </Row>

      </Form>
      </div>
    </>
  )
}

export default Login
