import React from "react";
import { Switch, Route } from "react-router-dom";
import { RouteWithLayout } from "./components";
import { MainLayout } from "./components/layout";
import AuthRoutes from "./pages/Auth";
import { PageNotFound } from "./pages/Generic";
import Download from "./pages/Download";
import Tutorial from "./pages/Tutorial"
import Website from "./pages/Website";
import Products from "pages/Products/ProductList/Products";
import SavedProducts from "pages/Products/SavedProduct/SavedProduct";
import EditAndImport from "pages/Products/Edit&Import/Edit&Import";
import ProductsDetails from "pages/Products/ProductDetails/ProductsDetails";
import Dashboard from "./pages/Dashboard";
import Address from "./pages/Address";
import { BuyForwardingParcelProvider } from "./context/buy-forwarding-context";
import Invoice from "./pages/Invoice";
import { InvoicePaymentProvider } from "./context/invoice-payment-context";
import { SettingsProvider } from "./context/settings-context";
import BuyAndShip from "./pages/BuyAndShip";
import Wallet from "./pages/Wallet";
import { WalletRechargeProvider } from "./context/wallet-recharge-context";
import ActionNeeded from "./pages/ActionNeeded";
import Profile from "./pages/Profile";
import PlaceOrder from "pages/PlaceOrder/PlaceOrder";
import Orders from "./pages/Orders";
import DeliveryTracker from "./pages/DeliveryTracker";

const Routes = () => {
  return (
    <Switch>
      <SettingsProvider>
        <RouteWithLayout
          component={Dashboard}
          exact
          layout={MainLayout}
          path="/"
        />
        <RouteWithLayout
          component={Dashboard}
          exact
          layout={MainLayout}
          path="/dashboard"
        />
        <RouteWithLayout
          component={Website}
          exact
          layout={MainLayout}
          path="/websites"
        />
        <RouteWithLayout
          component={Products}
          exact
          layout={MainLayout}
          path="/products"
        />
        <RouteWithLayout
          component={Products}
          exact
          layout={MainLayout}
          path="/products/:slug"
        />
        <RouteWithLayout
          component={SavedProducts}
          exact
          layout={MainLayout}
          path="/saved-products"
        />
        <RouteWithLayout
          component={EditAndImport}
          exact
          layout={MainLayout}
          path="/saved-products/edit-import/:id"
        />
        <RouteWithLayout
          component={ProductsDetails}
          exact
          layout={MainLayout}
          path="/productDetails/:slug"
        />
        <RouteWithLayout
          component={Address}
          exact
          layout={MainLayout}
          path="/address"
        /><RouteWithLayout
          component={DeliveryTracker}
          exact
          layout={MainLayout}
          path="/delivery-trackeer"
        />
        <BuyForwardingParcelProvider>
          <RouteWithLayout
            component={BuyAndShip}
            exact
            layout={MainLayout}
            path="/order"
          />
        </BuyForwardingParcelProvider>
        <RouteWithLayout
          component={PlaceOrder}
          exact
          layout={MainLayout}
          path="/place-order"
        />
        <InvoicePaymentProvider>
          <RouteWithLayout
            component={Invoice}
            exact
            layout={MainLayout}
            path="/invoice"
          />
          <WalletRechargeProvider>
            <RouteWithLayout
              component={Wallet}
              exact
              layout={MainLayout}
              path="/wallet"
            />
            <RouteWithLayout
              component={ActionNeeded}
              exact
              layout={MainLayout}
              path="/action-needed"
            />
          </WalletRechargeProvider>
          <RouteWithLayout
            component={Download}
            exact
            layout={MainLayout}
            path="/download"
          />
          <RouteWithLayout
            component={Orders}
            exact
            layout={MainLayout}
            path="/shop-orders"
          />
          <RouteWithLayout
            component={Tutorial}
            exact
            layout={MainLayout}
            path="/tutorial"
          />
          <RouteWithLayout
            component={Profile}
            exact
            layout={MainLayout}
            path="/profile"
          />
        </InvoicePaymentProvider>
        <Route path={"/auth"} component={AuthRoutes} />
      </SettingsProvider>
      <RouteWithLayout layout={MainLayout} component={PageNotFound} />
    </Switch>
  );
};

export default Routes;
