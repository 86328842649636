import { useEffect, useState } from 'react'

const initialValue = 60;
const useCounter = () => {
    const [count, setCount] = useState(initialValue)
    const [isOff, setIsOff] = useState(true)


    useEffect(() => {
        const interval = setInterval(() => {
            if (count > 0) {
                setCount(count => count - 1)
            } else {
                setIsOff(false)
            }
        }, 1000)
        return () => clearInterval(interval)
    }, [count])

    const resetCounter = () => {
        setCount(initialValue)
        setIsOff(true)
    }

    return { count, isOff, resetCounter}
}

export default useCounter
