import React from "react"
import {Breadcrumb, Button, Col, message, Row, Tabs, Skeleton} from "antd"
import { PageHeader } from '@ant-design/pro-layout';
import {useInvoicePayment} from "context/invoice-payment-context"
import {useSettings} from "context/settings-context"
import {gateways, gatewayType} from "consts/app-constants"
// import {getQueryParams} from "utils/helpers"
import SSLCommerz from "components/Payment/SSLCommerz"
import BankDeposit from "components/Payment/BankDeposit"
import NagadPayment from "components/Payment/NagadPayment"
import RocketMerchant from "components/Payment/RocketMerchant"
import "./InvoicePayment.css"

// const {Column} = Table

const InvoicePayment = Props => {
    const {assetURL} = useSettings()
    const {
        payInvoice,
        payableAmount,
        isInvoicePaying,
        invoiceType,
        isGatewaysFetching,
        selectedInvoices,
        gatewayList
    } = useInvoicePayment()

    // const queryParams = getQueryParams()

    // const getTotal = (gateway) => {
    //     const amount = payableAmount
    //     let total = 0
    //     let fee = gateway.fee
    //     if (gateway.fee_type === "percentage") {
    //         total = amount + ((amount * fee) / 100.0)
    //     } else {
    //         total = amount + fee
    //     }
    //     return total
    // }

    const getPayable = (gateway) => {
        if (!gateway) {
            return
        }

        const amount = payableAmount
        let total = 0
        let fee = gateway.fee
        let symbol = "";
        if (gateway.fee_type === "percentage") {
            total = amount + Math.ceil((amount * fee) / 100.0)
            symbol = "%"
        } else {
            total = amount + fee
        }
        return `${payableAmount.toFixed(2)} + ${fee.toFixed(2)} ${symbol} = ${total.toFixed(2)}`
    }

    const payRegularGateway = (gateway) => {
        if (!window.location.origin) {
            window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
        }
        let redirectURL = window.location.origin
        // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'production') {
        // 	redirectURL += "/wordpress";
        // }
        redirectURL += "/wp-admin/admin.php?page=moveon-drop-wp&tab=invoice&action=invoice-overview";
        const data = {
            gateway_id: gateway.id,
            invoices: selectedInvoices.map(item => {
                return {
                    id: item.id,
                    type: invoiceType
                }
            }),
            amount: payableAmount,
            redirect_url: encodeURI(redirectURL)
        }

        payInvoice(data, (response) => {
            if (response.data && response.data.data && response.data.data.have_redirect_url === true) {
                let link = document.createElement("a")
                link.id = "redirect-link"
                link.target = "_blank"
                link.href = response.data.data.redirect_url
                link.click()
            }
        })
    }

    const payBankDeposit = (gateway, values) => {
        let data = {
            gateway_id: gateway.id,
            invoices: selectedInvoices.map(item => {
                return {
                    id: item.id,
                    type: invoiceType
                }
            }),
            reference: values.reference,
            deposit_date: values.deposit_date,
            amount: payableAmount,
            images: values.images
        }

        payInvoice(data, (response) => {
            message.success("Bank deposit request successfull")
        })
    }

    const getGatewayDetail = (gateway) => {
        switch (gateway.slug) {
            case gateways.SSLCommerz:
                return (
                    <SSLCommerz
                        isPaying={isInvoicePaying}
                        gateway={gateway}
                        getPayable={() => getPayable(gateway)}
                        payByGateway={() => payRegularGateway(gateway)}
                    />
                )
            case gateways.Nagad:
                return (
                    <NagadPayment
                        isPaying={isInvoicePaying}
                        gateway={gateway}
                        getPayable={() => getPayable(gateway)}
                        payByGateway={() => payRegularGateway(gateway)}
                    />
                )
            case gateways.Rocket:
                return (
                    <RocketMerchant
                        isPaying={isInvoicePaying}
                        gateway={gateway}
                        getPayable={() => getPayable(gateway)}
                        payByGateway={(values) => payBankDeposit(gateway, values)}
                    />
                )
            default:
                return (
                    <></>
                )
        }
    }


    const getRegularGateways = () => {
        if (!gatewayList || !Array.isArray(gatewayList)) {
            return [];
        }

        let list = gatewayList.filter(gateway => {
            return gateway.gateway_type === gatewayType.RegularGateway || gateway.slug === gateways.Rocket
        });

        return list.filter(gateway => gateway.slug !== gateways.BKash);
    }

    const getBankDepositGateways = () => {
        if (!gatewayList || !Array.isArray(gatewayList)) {
            return [];
        }

        return gatewayList.filter(gateway => (gateway.gateway_type === gatewayType.BankDeposit && gateway.slug !== gateways.Rocket))
    }

    if (payableAmount <= 0 || gatewayList.length === 0 || selectedInvoices.length === 0 || !invoiceType) {
        Props.handlePageAction("invoice-overview")
        return <></>
    }

    return (
        <PageHeader
            ghost={false}
            onBack={() => Props.handlePageAction("invoice-overview")}
            breadcrumb={
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Button
                            key="1"
                            type="link"
                            onClick={() => Props.handlePageAction("invoice-overview")}
                        >
                            Invoice List
                        </Button>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Invoice payment</Breadcrumb.Item>
                </Breadcrumb>
            }
        >
            <Row>
                <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                    <Skeleton active loading={isGatewaysFetching}>
                        <Tabs defaultActiveKey="1" centered type="card">
                            {
                                getRegularGateways().map((gateway, index) => {
                                        return (
                                            <Tabs.TabPane
                                                tab={
                                                    <div className={"gateway-selection"}>
                                                        <img
                                                            className="img-fluid"
                                                            src={gateway.logo}
                                                            width={100}
                                                            height={60}
                                                            alt={gateway.name}
                                                        />
                                                    </div>
                                                }
                                                key={gateway.id}
                                            >
                                                {getGatewayDetail(gateway)}
                                            </Tabs.TabPane>
                                        )
                                    })
                            }
                            <Tabs.TabPane
                                tab={
                                    <div className={"gateway-selection"}>
                                        <img
                                            src={assetURL + "/assets/images/payments/bankdeposit.svg"}
                                            alt="Bank Deposit"
                                            width={30}
                                            height={18}
                                        />
                                        <span>Bank Deposit</span>
                                    </div>
                                }
                                key={10}
                            >
                                <BankDeposit
                                    gatewayList={getBankDepositGateways()}
                                    getPayable={getPayable}
                                    isPaying={isInvoicePaying}
                                    payByGateway={payBankDeposit}
                                />
                            </Tabs.TabPane>
                        </Tabs>
                    </Skeleton>
                </Col>
            </Row>
        </PageHeader>
    )
}

export default InvoicePayment
