import React, { useEffect, useState } from 'react';
import { getLocation } from 'utils/generic-client';
import { storeAddress, updateAddress } from 'utils/customer-client';
import { Button, Checkbox, Form, Input, message, Modal, Select } from "antd";
import { toastErrors } from 'utils/helpers';

const formItemLayout = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 18 },
  },
}

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const LocationSelect = (Props) => {
  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getLocation(Props.parent).then(res => {
      let locationData = [];
      if (res && res.data && res.data.data) {
        res.data.data.forEach(item => {
          locationData.push({
            value: item.id,
            label: item.name,
            postal_code: item.postal_code
          });
        });
      }

      setLocations(locationData);
      setIsLoading(false);
    }).catch(({ response }) => {
      if (response?.data) {
        toastErrors(response.data)
      }
    }).finally(x => {
      setIsLoading(false);
    })
  }, [Props.parent]);

  return (
    <>
      {
        !isLoading &&
        <Select
          showSearch={true}
          onChange={val => {
            Props.onChange(Props.type, val)
          }}
          className="mvn-input--select"
          placeholder={Props.placeholder}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          filterSort={(optionA, optionB) =>
            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          }
        >
          {
            locations.map(((item, index) => {
              return <Select.Option value={item.value} key={index}>{item.label}</Select.Option>
            }))
          }
        </Select>
      }
    </>
  );
}

const AddressDrawer = Props => {
  const [form] = Form.useForm();
  const { drawerAction, address } = Props;
  const [isLoading, setIsLoading] = useState(false);
  const [district, setDistrict] = useState(null);
  const [area, setArea] = useState(null);
  const [, setPostalCode] = useState(null);

  useEffect(() => {
    form.resetFields();
    if (drawerAction === "edit") {
      let data = {
        name: address.name,
        phone: address.phone,
        country: address.country,
        district: address.district?.id,
        area: address.area?.id,
        address: address.address,
        postal_code: address.postal_code,
        is_default_shipping: address.is_default_shipping,
        is_default_billing: address.is_default_billing,
      }
      form.setFieldsValue(data);
    } else {
      form.setFieldsValue({ is_active: true })
    }
    // eslint-disable-next-line
  }, [drawerAction, address]);


  const handleSubmit = data => {
    setIsLoading(true);

    let apiClient = null;
    if (drawerAction === "edit") {
      apiClient = updateAddress(address.id, data);
    } else {
      apiClient = storeAddress(data);
    }

    apiClient.then(res => {
      setIsLoading(false);
      Props.onAddressCreate(res.data.address);
      message.success(drawerAction === "edit" ? "Address updated successfully" : "Address added successfully");
      Props.onHide();
    }).catch(({ response }) => {
      if (response?.data) {
        toastErrors(response.data)
      }
    }).finally(() => setIsLoading(false));
  }

  const handleAddressSelect = (type, data) => {
    if (type === 'district') {
      setDistrict(data);
      form.setFieldsValue({ 'district': data, 'area': null, 'postal_code': null });
      setArea(null);
      setPostalCode(null);
    } else if (type === 'area') {
      setArea(data);
      setPostalCode(data.postal_code);
      form.setFieldsValue({ 'area': data, 'postal_code': data.postal_code });
    }
  }

  const options = [
    { value: 'Bangladesh', label: 'Bangladesh' },
  ]

  const getTitle = () => {
    if (drawerAction === 'edit') {
      return 'Edit address';
    } else {
      return 'Add a address'
    }
  }

  return (
    <Modal
      width={640}
      title={getTitle()}
      closable={true}
      onCancel={Props.onHide}
      visible={Props.show}
      footer={null}
    >
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={handleSubmit}
        scrollToFirstError
      >
        <Form.Item
          name="name"
          label="Fullname"
          rules={[{ required: true, message: 'Input fullname!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="phone"
          label="Mobile"
          rules={[{ required: true, message: 'Input mobile number!' }]}
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name="country"
          label="Country"
          rules={[{ required: true, message: 'Please select country' }]}
        >
          <Select options={options} />
        </Form.Item>
        <Form.Item
          name="district"
          label="District"
          rules={[{ required: true, message: 'Please select district' }]}
        >
          <LocationSelect
            parent={null}
            type="district"
            value={district}
            onChange={handleAddressSelect}
            placeholder={"Please enter your district"}
          />
        </Form.Item>
        <Form.Item
          name="area"
          label="Area"
          rules={[{ required: true, message: 'Please select area' }]}
        >
          {
            district ?
              <LocationSelect
                parent={district}
                type="area"
                value={area}
                onChange={handleAddressSelect}
                placeholder={"Please enter your area"}
              />
              : <input
                type="text"
                className="form-control"
                name="postal_code"
                placeholder="Please choose district first"
                disabled
                style={{ width: "100%" }}
              />
          }
        </Form.Item>
        <Form.Item
          name="address"
          label="Address"
          rules={[{ required: true, message: 'Address must be needed' }]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          name="postal_code"
          label="Postal Code"
          rules={[{ required: true, message: 'Postal code must be needed' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="is_default_shipping"
          label="Default Shipping"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="is_default_billing"
          label="Default Billing"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {drawerAction === "edit" ? "Update" : "Create"}
          </Button>
        </Form.Item>
      </Form>

    </Modal>
  );
}
export default AddressDrawer;
