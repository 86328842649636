import { BrowserRouter } from "react-router-dom";
import Routes from "Routes";
import "antd/dist/reset.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import "./assets/styles/custom.css";
import React from "react";
import ReactDOM from "react-dom";
import AppProviders from "./context";
import { Toaster } from 'react-hot-toast';

ReactDOM.render(
  <AppProviders>
    <Toaster />
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </AppProviders>,
  document.getElementById("root")
);
