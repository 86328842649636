import client from "./api-client";
import Qs from "qs"

export const getPaymentGateways = () => {
    return client.get(`/payment/gateways`)
}

export const handleInvoicePay = (data) => {
    return client.post(`/payment/${data.gateway_id}/pay`, data);
}

export const getBalance = () => {
    return client.get("/customer/wallet/balance");
};

export const getRechargeRequests = (page = null, filter = null, perPage = 10) => {
    let data = {
        page: page ? page : 1,
        per_page: perPage
    }

    if (filter) {
        data = {...data, ...filter}
    }
    return client.get("/customer/recharge-request", {
        params: data,
        paramsSerializer: function (params) {
            return Qs.stringify(params, {arrayFormat: "brackets"})
        },
    })
}

export const getRechargeRequestDetails = (requestId) => {
    return client.get(`/payment/recharge-request/${requestId}/details`)
}

export const handleWalletRecharge = (data) => {
    return client.post(`/payment/${data.gateway_id}/recharge`, data);
}

export const handlePaymentSuccess = (token, data) => {
    return client.post(`/payment/success/${token}`, data);
}

export const getTransactionHistory = (page = null, filter = null, perPage = 10) => {
    let data = {
        page: page ? page : 1,
        per_page: perPage
    }

    if (filter) {
        data = {...data, ...filter}
    }
    return client.get("/customer/wallet/transaction-history", {
        params: data,
        paramsSerializer: function (params) {
            return Qs.stringify(params, {arrayFormat: "brackets"})
        },
    })
}
