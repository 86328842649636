import React, { useState } from 'react'
import {Form, Input, Button, Result, Alert, Typography} from 'antd'
import {Link} from "react-router-dom";
import {resetPassword} from "utils/auth-client";
import {useQuery} from "../../../utils/use_query";
const {Title} = Typography;
const ResetPassword = () => {
    let query = useQuery();
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState(null);
    const [page, setPage] = useState('reset-form');
    const onSubmit = (values) => {
        setIsLoading(true);
        let data = {
            email: query.get("email"),
            token: query.get("token"),
            password_confirmation: values.confirm,
            password: values.password
        }
        resetPassword(data).then(res => {
            setIsLoading(false)
            setPage('reset-success');
        }).catch(err => {
            setIsLoading(false)
            setErrors(err.response);
        })
    }
    return (
        <>
            <Title className="font-regular text-muted" level={5}>
                Reset Password
            </Title>
            {
                errors && errors.data && errors.data.error &&
                <Alert message={errors.data.error} type="error" showIcon />
            }
            {
                page === 'reset-form' ?
                    <Form
                        onFinish={onSubmit}
                        layout="vertical"
                        className="row-col"
                        scrollToFirstError
                    >
                        <Form.Item
                            name="password"
                            label="Password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                                {min: 6, message: 'Password must be minimum 6 characters.'},
                            ]}
                            hasFeedback
                        >
                            <Input.Password/>
                        </Form.Item>

                        <Form.Item
                            name="confirm"
                            label="Confirm Password"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                ({getFieldValue}) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('The two passwords that you entered do not match!');
                                    },
                                }),
                            ]}
                        >
                            <Input.Password/>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{width: "100%"}}
                                loading={isLoading}
                            >
                                Reset
                            </Button>
                        </Form.Item>
                    </Form>

                    :
                    <Success/>
            }
        </>
    );
};

const Success = () => {
    return(
        <Result
            status="success"
            title="Your password reset is successful"
            extra={[
                <Link to={"/auth/login"}>
                    <Button type="primary" key="console">
                        Login
                    </Button>
                </Link>
            ]}
        />
    );
}
export default ResetPassword
