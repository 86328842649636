import React from 'react';
import {
	Route,
	Redirect
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from 'context/auth-context'

const RouteWithLayout = props => {
	const { layout: Layout, component: Component, ...rest } = props;
	const loggedIn = useAuth().loggedIn()
	return (
		<Route
			{...rest}
			render={matchProps => {

				const { location } = matchProps
				if (!loggedIn) {
					return (
						<Redirect
							to={{
								pathname: "/auth/login",
								state: { from: location }
							}}
						/>
					)
				}
				return (
					<Layout>
						<Component {...matchProps} />
					</Layout>
				)
			}}
		/>
	);
};

RouteWithLayout.propTypes = {
	component: PropTypes.any.isRequired,
	layout: PropTypes.any.isRequired,
	path: PropTypes.string
};

export default RouteWithLayout;