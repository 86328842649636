import React from 'react'
import {
    Layout,
    Row,
    Card,
    Button,
    Spin,
    Empty,
    Tooltip,
    List,
} from "antd";
import {
    ProductListDelivery,
    ProductListShippingAdd
} from "assets/SvgIcons";

function ListContainer({ products, priceInfo, detailsPage, loading }) {

    return (
        <Row
            gutter={[16, 16]}
            style={{ padding: "32px 32px" }}
            className="product-list-card-container"
        >
            {products?.data?.length > 0 ?
            <List
                grid={{
                    gutter: 16,
                    column: 5,
                    xl: 4,
                    lg:3,
                    md: 3,
                    sm: 2,
                    xs: 1
                }}
                dataSource={products?.data}
                renderItem={(product) => (
                    <List.Item style={{ position: 'relative', width: '100%', height: '100%',cursor: 'pointer' }} onClick={() => detailsPage(product.link)}
                    >
                        <Card
                            size="small"
                            style={{width: "100%" }}
                            cover={
                                <img
                                    alt="example"
                                    src={product.image}
                                    className="product-list-card-image"
                                />
                            }
                        >
                            <div className="product-list-delivery-wrapper">
                                <span className="product-list-delivery-icon">
                                    <ProductListDelivery />
                                </span>
                                <span className="product-list-delivery-text">10-15 Days</span>
                            </div>
                            <div>
                                <img
                                    className="product-list-card-country"
                                    alt="logo"
                                    src={product?.shop_id === 10 || product?.shop_id === 4 || product?.shop_id === 1 || product?.shop_id === 11 || product?.shop_id === 12 ? "../assets/images/china.png" :
                                      product?.shop_id === 13 || product?.shop_id === 21 || product?.shop_id === 5 || product?.shop_id === 22 ? "../assets/images/india.png" :
                                        product?.shop_id === 25 || product?.shop_id === 26 ? "../assets/images/uae.png" :
                                          product?.shop_id === 7 || product?.shop_id === 16 ? "../assets/images/usa.png" : ""
                                    }
                                />
                            </div>
                            <div className="product-list-card-body-wrapper">
                                <span
                                    className="product-list-card-title"
                                    title={product.title}
                                >
                                    {product.title.length > 24
                                        ? product.title.substring(0, 20) + "..."
                                        : product.title}
                                </span>
                                <span className="product-list-card-price">
                                    ৳{" "}
                                    {product.wholesales_price?.length > 0
                                        ? (
                                            product.wholesales_price?.[0].price *
                                            priceInfo?.totalFx
                                        ).toFixed(2)
                                        : (product.price * priceInfo?.totalFx).toFixed(2)}
                                </span>
                                <div className="product-list-card-shipping-wrapper">
                                    <ProductListShippingAdd />
                                    <span className="product-list-card-shipping">MoveOn Shipping Charge</span>
                                </div>
                                {product?.total_sales  ? <span className="product-list-card-sold">
                                    Sold: {product.total_sales}
                                </span> : ""}
                            </div>
                            <div className="product-list-card-profit-text-wrapper">
                                <span className="product-list-card-profit-text">
                                    {`Estd. Profit: ৳${product.wholesales_price?.length > 0
                                        ? (
                                            product.wholesales_price?.[0].price *
                                            priceInfo?.totalFx *
                                            0.83
                                        ).toFixed(2)
                                        : (product.price * priceInfo?.totalFx * 0.83).toFixed(2)
                                    }
                    -৳${product.wholesales_price?.length > 0
                                        ? (
                                            product.wholesales_price?.[0].price *
                                            priceInfo?.totalFx *
                                            2
                                        ).toFixed(2)
                                        : (product.price * priceInfo?.totalFx * 2).toFixed(2)
                                    }`}
                                </span>
                            </div>
                            <div className="product-list-card-cta-wrapper">
                                <Button
                                    style={{
                                        width: "50%",
                                        height: "32px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        boxShadow: "none",
                                    }}
                                    onClick={() => detailsPage(product.link)}
                                >
                                    <span className="product-card-btn-text">View</span>
                                </Button>
                                <Tooltip placement="top" title={"upcoming"} arrow={false}>
                                    <Button
                                        style={{
                                            width: "50%",
                                            height: "32px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            boxShadow: "none",
                                            cursor: "not-allowed",
                                        }}
                                    >
                                        <span className="product-card-btn-text">Import</span>
                                    </Button>
                                </Tooltip>
                            </div>
                        </Card>
                    </List.Item>
                )}
            /> : <Layout
            style={{
                position: "relative",
                margin: "24px auto",
                padding: 24,
                minHeight: "screen",
                background: "white",
                borderRadius: "8px",
                width: "100%",
                display: "flex",
                alignItems: "center",
            }}
        >
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            {loading && (
                <div
                    style={{
                        position: "absolute",
                        height: "screen",
                        width: "100%",
                        zIndex: 1000,
                        display: "flex",
                        justifyContent: "center",
                        top: "10%",
                    }}
                >
                    <Spin size="large"/>
                </div>
            )
            }
        </Layout>
            }
        </Row>
    )
}

export default ListContainer
