import React from 'react'
import {
    Select,
    Col,
    Input,
    Form,
    Space
} from "antd";
function FormBlock({ handleStoreChange, stores, Option, handleSubmit, input, setInput }) {
    const [form] = Form.useForm();

    return (
        <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={12}
            xxl={14}
            style={{ position: "relative" }}
            className="product-list-filters-second"
        >
            <Form style={{marginTop: '-6.5px'}} form={form}>
               <Form.Item rules={[{ required: true }]}>
                 <Space.Compact style={{width: "100%"}}>
                   <Select
                       defaultValue="1688"
                       style={{
                           position: "absolute",
                           height: "39px",
                           width: "100px",
                           zIndex: 1000,
                       }}
                       onChange={handleStoreChange}
                   >
                       {stores.map((store, index) => (
                           <Option key={index} value={store.id}>
                               {store.name}
                           </Option>
                       ))}
                   </Select>
                   <Input.Search style={{ marginLeft: '100px' }} placeholder="Paste link or search from million of products" size="large" enterButton
                                 onSearch={handleSubmit}
                                 onChange={(e) =>
                                    setInput({ ...input, keyword: e.target.value, errors: "" })
                                }
                   />
                 </Space.Compact>
               </Form.Item>
            </Form>
        </Col>
    )
}

export default FormBlock
