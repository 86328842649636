import React, { useState } from "react";
import { Table, Tooltip, message, Spin,Card,Row,Col } from "antd";
import { useHistory } from "react-router-dom";
import { WordPressIcon, RedIcon, GreenIcon,YellowIcon,FrontArrow } from "assets/SvgIcons";

const TableData = ({ savedProductsData, selected, setSelected, product, deleteProduct, isLoading }) => {

    const history = useHistory();
    const [messageApi, contextHolder] = message.useMessage();
    const [selectionType, setSelectionType] = useState("checkbox");
    const [tooltipVisible, setTooltipVisible] = useState(false);


    const goToEditAndImport = (productId) => {
        history.push({
            pathname: `/saved-products/edit-import/${productId}`,
        });
    }

    // const goToPlaceOrder = () => {
    //   history.push({
    //     pathname: `/place-order`,
    //   });
    // }

    const columns = [
        {
            title: "Product Title",
            dataIndex: "Title",
            key: "Title",
            render: (_, record) => (
                <div style={{ display: "flex", gap: "13px", alignItems: "center" }}>
                    <img
                        style={{ width: "83px", height: "83px", borderRadius: "8px" }}
                        src={record.Image || `https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png`}
                        alt="product"
                    />
                    <span className="saved-product-table-row-text">
                        {record.Title}
                    </span>
                </div>
            ),
        },
        {
            title: "Product Price",
            dataIndex: "ProductPrice",
            key: "ProductPrice",
            render: (_, record) => <span className="saved-product-table-row-text">
                {(record.totalFx * record.ProductPrice).toFixed(2)}
            </span>
        },
        {
            title: "Your Price",
            dataIndex: "YourPrice",
            render: (_, record) => <span className="saved-product-table-row-text">{record.YourPrice}</span>

        },
        {
            title: "Source From",
            dataIndex: "Source",
            key: "Source",
            render: (_, record) => <span className="saved-product-table-row-text">{record.Source}</span>

        },
        {
            title: "Import Tag",
            dataIndex: "Tag",
            key: "Tag",
            render: (text, record, index) => {
              const customContent = (
                <Card style={{ width: 300 ,background: "#FFF",marginBottom: "40px !important"}}>
                  <p style={{borderBottom: '1px solid #E5E7EB', padding: "12px 0px"}}>Store</p>
                  <div>
                  {record?.Tag?.map((tag)=> (
                    <Row style={{display: 'flex', alignItems: 'center',justifyContent: 'space-between',whiteSpace: 'wrap',marginTop: '16px'}}>
                       <Col style={{width: '90%',display: 'flex', alignItems: 'center'}}><WordPressIcon/><span style={{paddingLeft: '8px'}}>{tag.split(" - ")[0]}</span></Col>
                      <Col style={{width: '10%',display: 'flex', justifyContent: 'end'}}>
                        {
                            tag.split(" - ")[1] === "import-pending" ? <YellowIcon/>
                             : tag.split(" - ")[1] === "import-succeed" ? <GreenIcon/>
                              : tag.split(" - ")[1] === "import-failed" ? <RedIcon/> : ""
                        }
                      </Col>
                    </Row>
                  ))}
                  </div>
                </Card>
              );
                if (record?.Tag?.length > 0) {
                    return (
                        <Tooltip
                            title={customContent}
                            arrow={false}
                            placement="top"
                            trigger="click"
                            onOpenChange={(open)=> {
                                setTooltipVisible(open);
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "4px",
                                    cursor: "pointer",
                                }}
                            >
                                <GreenIcon/>
                                <span>Imported</span>
                                <span ><FrontArrow/></span>
                            </div>
                        </Tooltip>
                    );
                } else  {
                    return <span>Not Imported</span>;
                }
            },
        },
        {
            title: "Latest Update",
            dataIndex: "updatedAt",
            key: "updatedAt"
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (_, record) => (
                <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
                    <span className="saved-product-table-action-btn1" onClick={() => goToEditAndImport(record.id)}>Edit & Import</span>
                    {/*<span className="saved-product-table-action-btn2"  onClick={()=>goToPlaceOrder()}>Place Order</span>*/}
                    <span className="saved-product-table-action-btn3" onClick={() => deleteProduct(record.savedProductId)}>Delete</span>
                </div>
            ),
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelected({ ...selected, rows: selectedRows.length, data: selectedRows })
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === "Disabled User",
            name: record.name,
        }),
    };

    return (
        <div style={{ position: 'relative', width: "100%" }}>
            {contextHolder}
            <Table
                rowSelection={{
                    type: selectionType,
                    ...rowSelection,
                }}
                scroll={{ x: 950 }}
                columns={columns}
                dataSource={savedProductsData}
                style={{ width: "100%", opacity: isLoading ? '0.5' : "1" }}
                pagination={false}
            />
            {isLoading && <Spin style={{ position: 'absolute', left: '50%', top: '50%' }} />}
        </div>
    );
};
export default TableData;
