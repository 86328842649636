import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Row, Select, Table } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { getIssues } from "utils/order-client";
import {
  clearQuerySearchParamsByKeys,
  getQueryParams,
  insertUrlParams,
  toastErrors,
} from "utils/helpers";
import DateTime from "components/DateTime";
import { StatusTag } from "components";
import { getWebsites } from "utils/oauth-client";
import { useHistory } from "react-router-dom";
import { Typography } from "antd";

const INITIAL_FILTERS = {
  website_id: undefined,
};
const ActionNeeded = () => {
  const [form] = Form.useForm();
  const [actions, setActions] = useState({
    data: [],
    meta: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const [websiteList, setWebsiteList] = useState([]);
  const [isWebsiteFetching, setIsWebsiteFetching] = useState(false);
  const history = useHistory();
  const queryParams = getQueryParams();
  const fetchActionNeeded = useCallback(
    (page = null, filters = null, pageSize = 10) => {
      setIsLoading(true);
      getIssues(page, filters, pageSize)
        .then((res) => {
          if (res && res.data && res.data.data) {
            setActions({
              data: res.data.data,
              meta: res.data.meta,
            });
          }
        })
        .catch(({ response }) => {
          if (response?.data) {
            toastErrors(response.data);
          }
        })
        .finally((x) => {
          setIsLoading(false);
        });
    },
    []
  );

  const fetchWebsites = useCallback(() => {
    setIsWebsiteFetching(true);
    getWebsites()
      .then((res) => {
        setWebsiteList(res.data);
      })
      .catch(({ response }) => {
        if (response?.data) {
          toastErrors(response.data);
        }
      })
      .finally(() => setIsWebsiteFetching(false));
  }, []);

  useEffect(() => {
    fetchActionNeeded();
    fetchWebsites();
  }, [fetchActionNeeded, fetchWebsites]);

  useEffect(() => {
    if (queryParams["website_id"]) {
      form.setFieldsValue({ website_id: queryParams["website_id"] });
    }
  }, [queryParams, form]);

  const handleFilterChange = (field, value) => {
    clearQuerySearchParamsByKeys([field]);
    let fieldValue = !value ? undefined : value;
    if (field === "date" && value[0] === "") {
      fieldValue = undefined;
    }
    setFilters({
      ...filters,
      [field]: fieldValue,
    });
  };

  const handleClear = () => {
    form.resetFields();
    setFilters(INITIAL_FILTERS);
    clearQuerySearchParamsByKeys(["website_id"]);
  };

  const handleFilterSubmit = () => {
    let validFilters = [];
    for (let [key, value] of Object.entries(filters)) {
      if (value !== undefined) {
        validFilters.push({ key: key, value: value });
      }
    }
    insertUrlParams(validFilters);
    fetchActionNeeded(1, filters);
  };

  const handlePageChange = (page, pageSize) => {
    insertUrlParams([
      {
        key: "page_index",
        value: page,
      },
    ]);
    fetchActionNeeded(page, filters, pageSize);
  };

  const onShowSizeChange = (current, size) => {
    insertUrlParams([
      {
        key: "per_page",
        value: size,
      },
    ]);
    fetchActionNeeded(current, filters, size);
  };

  const handleIssueAction = (row) => {
    history.push(`/order?action=product-action&product_id=${row.issueable_id}`);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Product number",
      dataIndex: "product",
      key: "product",
      render: (_, record) => {
        return record?.issueable?.product_number;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, row) => {
        return <StatusTag slug={row.status} text={row.status_label} />;
      },
    },
    {
      title: "Type",
      dataIndex: "type_label",
      key: "type_label",
    },
    {
      title: "Customer note",
      dataIndex: "customer_note",
      key: "customer_note",
      render: (text, record) => (
        <div className="buy_title">
          <a href={record.link} target="_blank" rel="noreferrer">
            <Typography.Text
              ellipsis={{ tooltip: text }}
              style={{ width: "150px" }}
            >
              {text}
            </Typography.Text>
          </a>
        </div>
      ),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        return <DateTime date={created_at} />;
      },
    },
    {
      title: "Details",
      dataIndex: "details",
      align: "center",
      render: (id, row) => {
        return (
          <Button
            onClick={() => {
              handleIssueAction(row);
            }}
            shape="round"
          >
            Detail
          </Button>
        );
      },
    },
  ];

  const paginationConfig =
    actions && actions?.meta
      ? {
        total: actions.meta.total,
        current: actions.meta.current_page,
        onChange: handlePageChange,
        onShowSizeChange: onShowSizeChange,
        pageSize: actions.meta.per_page,
        showSizeChanger: true,
        position: ["none", "bottomLeft"],
      }
      : false;

  return (
    <Row>
      <Col span={24}>
        <PageHeader title="Action Needed" ghost={false}>
          <Form
            form={form}
            labelCol={{ span: 12 }}
            layout="vertical"
            onFinish={handleFilterSubmit}
          >
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item label="Website" name="website_id">
                  <Select
                    placeholder="Please select website"
                    onChange={(value) =>
                      handleFilterChange("website_id", value)
                    }
                    loading={isWebsiteFetching}
                  >
                    {websiteList &&
                      websiteList.map((d) => (
                        <Select.Option value={d.id} key={d.id}>
                          {d.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Search
                  </Button>
                  <Button onClick={handleClear} style={{ marginLeft: "15px" }}>
                    Clear
                  </Button>
                  <Button
                    onClick={() => fetchActionNeeded()}
                    type="primary"
                    danger
                    loading={isLoading}
                    style={{
                      float: "right",
                      marginRight: "3%",
                    }}
                  >
                    Reload
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Table
            columns={columns}
            dataSource={actions.data}
            loading={isLoading}
            pagination={paginationConfig}
            style={{ width: "100%" }}
            size="middle"
            rowKey="id"
            scroll={{
              x: true,
            }}
          />
        </PageHeader>
      </Col>
    </Row>
  );
};

export default ActionNeeded;
