import client from './api-client'
import Qs from 'qs'

export const getStores = (page = 1, filter = null, perPage = 10) => {
    let data = {
        page,
        per_page: perPage
    }

    if (filter) {
        data = {...data, ...filter}
    }
    return client.get('/generic/stores', {
        params: data,
        paramsSerializer: function (params) {
            return Qs.stringify(params, {arrayFormat: 'brackets'})
        },
    })
}

export const getLocation = (parent) => {
    return client.get(`/location`, {
        params: {
            'parent': parent,
        },
    })
}

export const login = data => {
    return client.post(`generic/login`, data);
}

export const deliveryTracker = data => {
    return client.post(`/customer/fraud-checker`, data);
}

export const getHomepage = () => {
    return client.get('/customer/settings',{
        params: {
            'group': 'moveon-drop',
        },
    })
}

export const getServiceQuota = () => {
    return client.get('/customer/service-quota')
}
