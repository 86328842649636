import React from "react"
import {Tag} from "antd"
import {
    ORDER_STATUS_CANCELLED,
    ORDER_STATUS_COMPLETED,
    ORDER_STATUS_PENDING,
    ORDER_STATUS_PROCESSING,
    ORDER_STATUS_REFUNDED
} from '../../consts/statuses'

const StatusTag = props => {
    const {text, slug} = props
    let color = 'default'
    switch (slug) {
        case ORDER_STATUS_PROCESSING:
        case "delivery-processing":
            color = "#108ee9";
            break;
        case 'buy-not-purchased':
        case 'buy-reject':
        case 'unpaid':
        case 'invoice-payment-unpaid':
        case 'request_rejected':
        case 'delivery-cancelled':
        case 'delivery-attempt-failed':
        case 'delivery-postponed':
        case 'rejected':
        case ORDER_STATUS_CANCELLED:
            color = '#f50'
            break;

        case 'handover-to-shipping':
        case 'handover-to-airline':
        case 'arrived-at-destination-airport':
        case 'customs-released':
        case 'agent-processed':
        case 'delivery-shipped':
        case ORDER_STATUS_REFUNDED:
            color = '#544179'
            break;

        case 'buy-processed':
        case 'received-by-moveon':
        case 'arrived-at-warehouse':
        case 'buy-purchased':
        case 'buy-approved':
        case 'paid':
        case 'invoice-payment-paid' :
        case 'request_approved':
        case 'approved':
        case 'delivered-to-customer':
        case ORDER_STATUS_COMPLETED:
            color = '#87d068'
            break;

        case 'preparing-for-transport':
        case 'buy-pending':
        case 'buy-processing':
        case 'partial-paid':
        case 'request_pending':
        case 'invoice-payment-partial-paid':
        case 'pending':
        case 'delivery-pending':
        case ORDER_STATUS_PENDING:
        case "on-hold":
        case "delivery-ready":
        case "delivery-hold":
            color = '#FFCE45'
            break;
        case "initial":
            color = '#17a2b8'
            break;
        case "resolved":
            color = '#007bff'
            break;
        default:
            break
    }
    return <Tag color={color}>{text}</Tag>
}
export default StatusTag
