import { Prev,Next } from "assets/SvgIcons";

export const customArrow = (_, type, originalElement) => {
    if (type === "prev") {
        return <a><Prev /></a>;
    }
    if (type === "next") {
        return <a><Next /></a>;
    }
    return originalElement;
};