import { useEffect } from "react";

import {
  Row,
  Col,
  Breadcrumb,
  Button,
} from "antd";

import { NavLink } from "react-router-dom";
import { Toggler } from "../../assets/SvgIcons";

function Header({
  name, onPress
}) {
  useEffect(() => window.scrollTo(0, 0));
  console.log()
  return (
    <>
      <Row gutter={[24, 0]} style={{ margin: "0 auto", display: 'flex', alignItems: 'center' }}>
        <Col span={24} xs={22} sm={22} md={22} lg={22} xl={22} xxl={22} style={{
          color: 'rgba(29, 49, 72, 0.50)',
          fontFamily: 'Roboto',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: 'normal',
        }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/" style={{
                textTransform: "capitalize", color: 'rgba(29, 49, 72, 0.50)',
                fontSize: '14px',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal'
              }}>Pages</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item >
              <span style={{
                textTransform: "capitalize", color: 'rgba(29, 49, 72, 0.50)',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal'
              }}>{name.split("/")[0].replace("/", " / ")}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={2} xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} className="header-control">
          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => onPress()}
          >
            <Toggler />
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default Header;
