import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Input,
  Row,
  Tabs,
} from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import { getValueFromConfig } from "utils/helpers";
import SingleImageUpload from "../../SingleImageUpload";

const BankDeposit = (Props) => {
  const { gatewayList, getPayable, isPaying, payByGateway } = Props;
  const [selectedGateway, setSelectedGateway] = useState(null);
  const [form] = Form.useForm();
  
  useEffect(() => {
    if (gatewayList.length > 0) {
      setSelectedGateway(gatewayList[0]);
    }
  }, [gatewayList]);

  useEffect(() => {
    if (selectedGateway) {
      form.setFieldsValue({ bank: selectedGateway.name });
    }
  }, [selectedGateway, form]);

  return (
    <PageHeader className="site-page-header">
      {selectedGateway ? (
        <Row>
          <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
            <span className="font-weight-700">Payable (approximate): </span>
            {getPayable(selectedGateway)}
          </Col>
        </Row>
      ) : (
        <></>
      )}

      <Row>
        <Col span={24}>
          <Tabs
            centered
            onTabClick={(key) =>
              setSelectedGateway(
                gatewayList.filter(
                  (item) => item.id.toString() === key.toString()
                )[0]
              )
            }
          >
            {gatewayList.map((gateway, index) => {
              return (
                <Tabs.TabPane
                  tab={
                    <div className={"gateway-selection"}>
                      <img
                        className="img-fluid"
                        src={gateway.logo}
                        width={100}
                        height={60}
                        alt={gateway.name}
                      />
                    </div>
                  }
                  key={gateway.id}
                />
              );
            })}
          </Tabs>
        </Col>
      </Row>
      {selectedGateway && (
        <>
          <Row>
            <Col sm={24} xs={24} md={24} lg={12} xl={8} xxl={8}>
              <Descriptions
                title={
                  <span className="font-weight-700">Account Information</span>
                }
                column={1}
              >
                <Descriptions.Item
                  label={<span className="font-weight-600">Bank Name</span>}
                >
                  {selectedGateway.name}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<span className="font-weight-600">Payee</span>}
                >
                  {getValueFromConfig(selectedGateway.config, "payee")}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <span className="font-weight-600">Account Number</span>
                  }
                >
                  {getValueFromConfig(selectedGateway.config, "account_number")}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<span className="font-weight-600">Branch</span>}
                >
                  {getValueFromConfig(selectedGateway.config, "branch")}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<span className="font-weight-600">Routing</span>}
                >
                  {getValueFromConfig(selectedGateway.config, "routing")}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          <Card>
            <Row>
              <Col offset={4} span={16}>
                <Form
                  name="bank-deposit-form"
                  onFinish={(values) =>
                    payByGateway(selectedGateway, values)
                  }
                  form={form}
                  labelCol={{ span: 6 }}
                  labelAlign={"left"}
                >
                  <Row>
                    <Col span={24}>
                      <Form.Item name="bank" label="Gateway Name" hasFeedback>
                        <Input disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="reference"
                        label="Referece"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please enter referece number",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="deposit_date"
                        label="Deposit Date"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please enter deposit date",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}

                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item name="images" label="Attach a Image">
                    <SingleImageUpload
                      handleUploadedImage={(image) => {
                        form.setFieldsValue({
                          images: [image.imageURL],
                        });
                      }}
                      imageURL={"https://placehold.jp/150x150.png"}
                    />
                  </Form.Item>
                  <Form.Item wrapperCol={{ span: 18, offset: 6 }}>
                    <Button type="primary" htmlType="submit" loading={isPaying}>
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Card>
        </>
      )}
    </PageHeader>
  );
};

export default BankDeposit;
