import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  Row
} from "antd";
import { sendEmailOTP } from 'utils/auth-client'
import { toastErrors } from "utils/helpers";
import {Link} from "react-router-dom";

const FirstStep = Props => {
  const [form] = Form.useForm()
  const { step, updateStep, updateData } = Props
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = (data) => {
    setIsLoading(true);
    let payload = {
      "email": data.email
    }
    sendEmailOTP(payload).then((response) => {
      updateData({
        email: response.data.email,
      });
      updateStep(step + 1);
    }).catch(({ response }) => {
      if (response?.data) {
        toastErrors(response.data)
      } else {
        Modal.error({
          title: 'Error Occurred',
          content: 'Unknown error occurred'
        });
      }
    }).finally(() => setIsLoading(false));
  };
  if (step !== 1) {
    return null;
  }
  return (
    <Form
      name="basic"
      onFinish={onSubmit}
      className="row-col"
      form={form}
      layout="vertical"
    >
      <Form.Item
        name="email"
        label="Email"
        rules={[
          { required: true, message: "Please input your Email!" },
        ]}
      >
        <Input placeholder="Enter your Email" />
      </Form.Item>
      <Row className="item-align-center">
        <Form.Item>
          <Button
            className="login-btn"
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            Continue
          </Button>
        </Form.Item>
        <Form.Item>
            <span className="secondary-color">
              Don’t have an account? &nbsp;
              <Link to="/auth/login" className="text-dark primary-color">
              Login
            </Link>
            </span>
        </Form.Item>
      </Row>
    </Form>
  );
}

export default FirstStep