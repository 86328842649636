import React, { useState } from 'react'
import { Table, Input, Button, Popover, Select,InputNumber } from "antd";
import { ArrowDownIcon } from 'assets/SvgIcons';

function TableData({
                       variation,
                       selectedRowKeys,
                       setSelectedRowKeys,
                       setSelectedVariants,
                       totalFx,
                   }) {

    const [selectedOption, setSelectedOption] = useState("Increase by fixed amounts");
    const [increasePriceInputField, setIncreasePriceInputField] = useState(null)
    const [_, setPriceInputFieldLabel] = useState("")

    const rowData = variation?.skus?.map((sku, index) => {
        const propValues = sku.props.split(',');
        const variations = propValues.map(propValue => {
            const matchingProp = variation?.props?.find(prop => prop.values.some(value => value.id.toString() === propValue.trim()));
            return matchingProp
                ? {
                    propId: matchingProp.id,
                    propName: matchingProp.name,
                    propValue: propValue.trim(),
                    propInnerValue: matchingProp.values.find(value => value.id.toString() === propValue.trim())?.name || null,
                    innerImage: matchingProp.values.find(value => value.id.toString() === propValue.trim())?.image || null,
                }
                : null;
        });

        return {
            ...sku,
            key: index,
            sku: sku.id.toString(),
            moveon_regular_price: (sku.price.actual * totalFx).toFixed(2),
            stock: sku.stock.available,
            min_stock: sku.stock.min,
            variation: variations,
        };
    });

    //to find dynamic columns based on variation

    const uniqueVariations = Array.from(
        new Set(rowData?.flatMap(sku => sku.variation?.map(variation => variation?.propName)))
    );

    const priceIncreaseItems = [
        { value: "1", label: "Increase by fixed amounts" },
        { value: "2", label: "Increase by percentage(%)" },
        { value: "3", label: "Multiply with" },
        { value: "4", label: "Set to" }
    ];

    const handleChange = (value,label) => {
        let dynamicNumberField;
        setSelectedOption(value);
        if(label.label === "Increase by fixed amounts"){
            dynamicNumberField = <InputNumber addonBefore="+" addonAfter="৳" defaultValue={100} style={{
                width: 240,
            }}/>
            setPriceInputFieldLabel(label.label)
            setIncreasePriceInputField(dynamicNumberField)
        }
        else if(label.label === "Increase by percentage(%)"){
            dynamicNumberField = <InputNumber addonBefore="+" addonAfter="%" defaultValue={100} style={{
                width: 240,
            }}/>
            setPriceInputFieldLabel(label.label)
            setIncreasePriceInputField(dynamicNumberField)
        }
        else if(label.label === "Multiply with"){
            dynamicNumberField = <InputNumber addonBefore="x" defaultValue={100} style={{
                width: 240,
            }}/>
            setPriceInputFieldLabel(label.label)
            setIncreasePriceInputField(dynamicNumberField)
        }
        else if(label.label === "Set to"){
            dynamicNumberField = <InputNumber addonBefore="=" addonAfter="৳" defaultValue={100} style={{
                width: 240,
            }}/>
            setPriceInputFieldLabel(label.label)
            setIncreasePriceInputField(dynamicNumberField)
        }
    };

    const content = (
        <div style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
            <Select
                defaultValue={priceIncreaseItems[0].label}
                value={selectedOption}
                style={{
                    width: 240,
                }}
                onChange={handleChange}
                options={priceIncreaseItems}
            />
            {increasePriceInputField}
        </div>
    );

    let columns = [];

    if (uniqueVariations.length === 1 && uniqueVariations[0] === undefined)
    {
        columns = [
            {
                title: <div style={{ width: '100px', whiteSpace: 'wrap' }}>MoveOn Regular Price</div>,
                dataIndex: "moveon_regular_price",
                width: 150,
                render: (_, record) => (
                    <div>
                        <span className='edit-import-table-cell-text'>{record?.moveon_regular_price}</span>
                    </div>
                )
            },
            {
                title: <div style={{ width: '80px', whiteSpace: 'wrap' }}>MoveOn Sale Price</div>,
                width: 150,
                render: (_, record) => (
                    <div>
                        <span className='edit-import-table-cell-text'>{record?.price?.offer ? ((record?.price?.offer * totalFx).toFixed(2)) : ((record?.price?.actual * totalFx).toFixed(2))}</span>
                    </div>
                )
            },
            {
                title:
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                        <div >Your Regular Price</div>
                        <Popover content={content} trigger="click" placement="bottom" arrow={false}>
                            <Button style={{ display: 'flex', alignItems: 'center' }} className='edit-import-price-increase-btn'>Increase Price<span style={{ paddingTop: '3px', paddingLeft: '9px' }}><ArrowDownIcon /></span> </Button>
                        </Popover>
                    </div>
                ,
                width: 150,
                render: (_,record) => (
                    <div>
                        <Input style={{ width: "130px",textAlign: "center", border: "1px solid #E5E7EB" }} value={record?.moveon_regular_price} className='edit-import-table-cell-text'/>
                    </div>
                )
            },
            {
                title: <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <div>Your Sale Price</div>
                    <div>
                        <Popover content={content} trigger="click" placement="bottom" arrow={false}>
                            <Button style={{ display: 'flex', alignItems: 'center' }} className='edit-import-price-increase-btn'>Increase Price<span style={{ paddingTop: '3px', paddingLeft: '9px' }}><ArrowDownIcon /></span> </Button>
                        </Popover>
                    </div>
                </div>,
                width: 150,
                render: (_,record) => (
                    <div style={{ position: "relative" }}>
                        <Input style={{ width: "130px",textAlign: "center", border: "1px solid #E5E7EB" }} value={record?.moveon_regular_price} className='edit-import-table-cell-text'/>
                    </div>
                )
            },
        ]
    } else {
        columns = [
            ...uniqueVariations.map(variation => ({
                title: variation,
                dataIndex: 'variation',
                key: variation,
                width: 150,
                render: (_, record) => {
                    const matchingVariation = record?.variation?.find(v => v?.propName === variation);
                    return matchingVariation ? <div>
                        <span style={{ whiteSpace: "wrap" }} className='edit-import-table-cell-text'>{matchingVariation.propInnerValue}</span>
                    </div> : '-';
                },
            })),
            {
                title: <div style={{ width: '100px', whiteSpace: 'wrap' }}>MoveOn Regular Price</div>,
                dataIndex: "moveon_regular_price",
                width: 150,
                render: (_, record) => (
                    <div>
                        <span className='edit-import-table-cell-text'>{record?.moveon_regular_price}</span>
                    </div>
                )
            },
            {
                title: <div style={{ width: '80px', whiteSpace: 'wrap' }}>MoveOn Sale Price</div>,
                width: 150,
                render: (_, record) => (
                    <div>
                        <span className='edit-import-table-cell-text'>{record?.price?.offer ? ((record?.price?.offer * totalFx).toFixed(2)) : ((record?.price?.actual * totalFx).toFixed(2))}</span>
                    </div>
                )
            },
            {
                title:
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                        <div >Your Regular Price</div>
                        <Popover content={content} trigger="click" placement="bottom" arrow={false}>
                            <Button style={{ display: 'flex', alignItems: 'center' }} className='edit-import-price-increase-btn'>Increase Price<span style={{ paddingTop: '3px', paddingLeft: '9px' }}><ArrowDownIcon /></span> </Button>
                        </Popover>
                    </div>
                ,
                width: 150,
                render: (_,record) => (
                    <div>
                        <Input style={{ width: "130px",textAlign: "center", border: "1px solid #E5E7EB" }} value={record?.moveon_regular_price} className='edit-import-table-cell-text'/>
                    </div>
                )
            },
            {
                title: <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <div>Your Sale Price</div>
                    <div>
                        <Popover content={content} trigger="click" placement="bottom" arrow={false}>
                            <Button style={{ display: 'flex', alignItems: 'center' }} className='edit-import-price-increase-btn'>Increase Price<span style={{ paddingTop: '3px', paddingLeft: '9px' }}><ArrowDownIcon /></span> </Button>
                        </Popover>
                    </div>
                </div>,
                width: 150,
                render: (_,record) => (
                    <div style={{ position: "relative" }}>
                        <Input style={{ width: "130px",textAlign: "center", border: "1px solid #E5E7EB" }} value={record?.moveon_regular_price} className='edit-import-table-cell-text'/>
                    </div>
                )
            }
        ]
    }

    function hasImage(objects) {
        return objects?.some(obj => obj.values.some(value => value.image !== null));
    }

    const hasImageInProps = hasImage(variation?.props);
    if (hasImageInProps) {
        let dynamicImageColumn = [{
            title: "Image",
            dataIndex: "image",
            width: 150,
            render:
                (_, record) =>
                    <div style={{ display: "flex", gap: "13px", alignItems: "center" }} >
                        <img
                            style={{ width: "63px", height: "63px", borderRadius: "8px" }}
                            src={record?.variation?.filter((item) => item.innerImage !== null)?.find((image) => image.innerImage)?.innerImage || `https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png`}
                            alt="product"
                        />
                    </div>
        }]
        columns.splice(0, 0, ...dynamicImageColumn)
    }

    if (variation?.skus?.length > 0) {
        let dynamicSkuColumn = [
            {
                title: 'SKU',
                dataIndex: "sku",
                width: 150,
                render: (_, record) => (
                    <div style={{ whiteSpace: 'pre-line' }}>
                        <span className='edit-import-table-cell-text'>{record?.sku.replace(/(.{12})/g, '$1\n')}</span>
                    </div>
                )
            }
        ]

        let DynamicStockColumn = [
            {
                title: "Stock",
                dataIndex: "stock",
                width: 150,
                render: (_, record) => (
                    <div>
                        <span className='edit-import-table-cell-text'>{record?.stock}</span>
                    </div>
                )
            }
        ]
        const hasImageInProps = hasImage(variation?.props);
        if(!hasImageInProps){
            columns.splice(0, 0, ...dynamicSkuColumn)
        }
        else {
            columns.splice(1, 0, ...dynamicSkuColumn)
        }
        columns.splice(columns.length, 0, ...DynamicStockColumn)
    }

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
        const findVariants = rowData.filter((variants) => (
            newSelectedRowKeys.includes(variants.id)
        ))
        setSelectedVariants(findVariants)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <Table
            rowSelection={rowSelection}
            scroll={{ x: 980 }}
            columns={columns}
            dataSource={rowData}
            rowKey={(record) => record.id}
            style={{ width: "100%", paddingTop: "24px" }}
        />
    )
}

export default TableData
