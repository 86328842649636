import React, { useEffect, useState } from 'react'
import DateTime from "components/DateTime"
import { Button, Col, Form, message, Row, Table, Tag } from "antd"
import { getTransactionHistory } from "utils/payment-client";

const { Column } = Table

const Transactions = () => {
  const [response, setResponse] = useState({ data: [], meta: null });
  const [isLoading, setIsLoading] = useState(false);
  const [isServiceGetCalled, setIsServiceGetCalled] = useState(false);

  const fetchItems = React.useCallback(
    (page, filter, perPage) => {
      setIsLoading(true)
      getTransactionHistory(page, filter, perPage)
        .then(res => {
          setResponse(res.data)
        })
        .catch(err => {
          if (err.response && err.response.status === 403) {
            message.error("The action is not authorized")
          } else {
            if (err && err.response && err.response.data && err.response.data.data && err.response.data.data?.data?.message) {
              message.error(err.response.data.data.data.message)
            } else {
              message.error("Something is wrong")
            }
          }
        }).finally(() => {
          setIsLoading(false)
        })
    }, [])

  useEffect(() => {
    if (!isServiceGetCalled) {
      fetchItems();
      setIsServiceGetCalled(true)
    }
  }, [fetchItems, isServiceGetCalled])

  const handlePageChange = (page, pageSize) => {
    fetchItems(page, null, pageSize)
  }

  const handleShowSizeChange = (current, size) => {
    fetchItems(current, null, size)
  }

  const paginationConfig = response && response.meta ? {
    total: response.meta.total,
    current: response.meta.current_page,
    onChange: handlePageChange,
    onShowSizeChange: handleShowSizeChange,
    pageSize: response.meta.per_page,
    showSizeChanger: true,
    position: ['none', 'bottomLeft']
  } : false

  return (
    <Row>
      <Col span={24}>
        <Form
          labelCol={{ span: 12 }}
          layout="vertical"
        >
          <Row  className="custom_margin" style={{display:"flex", justifyContent:"flex-end"}} >
              <Form.Item >
                <Button
                  onClick={() => fetchItems()}
                  type="primary"
                  danger
                  loading={isLoading}
                >Reload</Button>
              </Form.Item>

          </Row>
        </Form>
        <Table
          dataSource={response.data}
          loading={isLoading}
          pagination={paginationConfig}
          rowKey="id"
          scroll={{
            x: true
          }}
        >
          <Column
            title="#SL"
            dataIndex="key"
            key="SL"
            align={"center"}
            render={(text, record, index) => response.meta.from + index}
          />
          <Column
            title="Transaction Type"
            dataIndex="type"
            key="type"
            render={type => (
              type === "credit (+)" ?
                <Tag color="#87d068">
                  credit (+)
                </Tag>
                :
                <Tag color="#d92027">
                  debit (-)
                </Tag>
            )}
          />
          <Column
            title="Amount"
            dataIndex="amount"
            key="amount"
            align={"center"}
            render={(amount, row) => (
              row.type === "credit (+)" ?
                <span>+ {amount} </span>
                :
                <span>- {amount} </span>
            )}
          />
          <Column title="Balance" dataIndex="balance" key="balance" align={"center"} />
          <Column
            title="Note"
            dataIndex="note"
            key="note"
          />
          <Column
            title="Created time"
            dataIndex="created_at"
            key="created_at"
            align={"center"}
            render={created_at => (
              <DateTime date={created_at} />
            )}
          />
        </Table>
      </Col>
    </Row>
  );
}
export default Transactions
