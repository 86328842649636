import React from 'react'
import { Col, Row, Spin } from 'antd'
import { PageHeader } from '@ant-design/pro-layout';
import './SSLCommerz.css'
import { useSettings } from 'context/settings-context'

const SSLCommerz = (Props) => {
  const { assetURL } = useSettings()
  const { gateway, getPayable, payByGateway, isPaying } = Props

  return (
    <PageHeader
      className="site-page-header"
    >
      <Row>
        <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
          <span className="font-weight-700">Payable (approximate): </span>
          {getPayable(gateway)}
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
          <Spin tip="paying..." spinning={isPaying}>
            <button onClick={payByGateway} className={`ssl-button`}>
              <div
                className={`ssl-button-ico`}>
                <img
                  src={assetURL + '/assets/images/payments/cardBtn.svg'}
                  alt="Card Payment"
                  width={32}
                  height={32}
                />
              </div>
              PAY WITH SSLCOMMERZ
            </button>
          </Spin>
        </Col>
      </Row>
      <Row style={{ marginTop: 40 }}>
        <Col sm={6} xs={6} md={24} lg={24} xl={24} xxl={24}>
          <img
            src={assetURL + '/assets/images/payments/sslColor.png'}
            alt="Logo"
            className="img-fluid"
          />
        </Col>
      </Row>
    </PageHeader>
  )
}

export default SSLCommerz
