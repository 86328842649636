import React, { useState, useEffect } from "react";
import "../products.css";
import { useHistory, useLocation } from "react-router-dom";
import {
  Layout,
  Tabs,
  Col,
  Row,
  Button,
  Image,
  Form,
  Tooltip,
  Menu,
  Dropdown,
  message,
  Spin
} from "antd";
import {
  ProductDetailsButtonAdd,
  TickIcon,
} from "assets/SvgIcons";
import { ProductDetailsRating } from "assets/SvgIcons";
import ProductSpecification from "./ProductSpecification";
import {
  store_rates,
  domestic_shipping,
} from "consts/products";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import {
  getShippingCategories,
  calculateShipmentCost,
  getShippingCategoriesWithQuery,
  saveProduct,
  getSpecificDetails
} from "utils/product-client";
import CostEstimate from "./CostEstimate";
import GlobalShippingModal from "./GlobalShippingModal";
import { ExportToZip } from "./ExportToZip";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { handleImageDownload } from "./ImageDownload";
import { CopyOutlined, DownloadOutlined } from '@ant-design/icons';
function Details(product) {
  const [details, setDetails] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(()=> {
    const response = getSpecificDetails(product?.product?.link)
    response.then(res=>
    {
      setDetails(res?.data?.data)
    }).catch(({ response }) => {
      if (response?.data) {
        messageApi.open({
          type: 'error',
          content: response?.data?.message,
          style: {marginTop: '50px'}
        })
      }
    })

  },[])

  return (
    <div style={{ paddingTop: "20px", textAlign: "center", fontSize: "22px" }}>
      <div dangerouslySetInnerHTML={{ __html: details }}/>
    </div>
  )
}

function ProductReviews() {
  return (
    <div style={{ paddingTop: "20px", textAlign: "center", fontSize: "22px" }}>
      Reviews not found!
    </div>
  )
}

function ProductsDetails() {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const location = useLocation();
  const history = useHistory();
  const product = location.state;
  const [openModal, setOpenModal] = useState(false);
  const [wholeSaleSlots, setWholeSaleSlots] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [variation, setVariation] = useState({
    data: [],
    combinedPrice: "",
    stock: "",
  });
  const [globalShipping, setGlobalShipping] = useState({
    categories: [],
    shippingInfo: [],
    price: null
  });
  const [estimatedWeight, setEstimatedWeight] = useState(product?.meta?.weight);
  const [estimatedPrice, setEstimatedPrice] = useState(null);
  const [visibleItems, setVisibleItems] = useState(18);
  const [slot, setSlot] = useState(false);
  const [awCategoryId, setAwCategoryId] = useState(0);
  const [clipboard, setClipboard] = useState({
    isCopied: false,
    isHovered: false,
    currentImage: null,
    titleText: "",
    imageUrlText: "",
    specificationText: ""
  });
  const [selected, setSelected] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const [loadingCategory, setLoadingCategory] = useState(false)
  const totalFx = store_rates.find(
    (item) => item.storeId === product.shop_id
  ).totalFx;

  const items = [
    {
      key: "1",
      label: "Product Specification",
      children: <ProductSpecification product={product} />,
    },
    {
      key: "2",
      label: "Product Details",
      children: <Details product={product}/>,
    },
    {
      key: "3",
      label: "Reviews",
      children: <ProductReviews />,
    },
  ];

  useEffect(() => {
    if (product?.price?.wholesales) {
      let wholeSalePrices = product.price.wholesales;
      wholeSalePrices.sort((first, second) => first.qty - second.qty);
      let slots = [];
      for (let i = 0; i < wholeSalePrices.length; i++) {
        const element = wholeSalePrices[i];
        let begin = element.qty;
        if (slots[i - 1]) {
          let end = element.qty - 1;
          slots[i - 1]["end"] = end;
        }
        slots.push({
          begin: begin,
          price: element.discount
            ? element.discount
            : element.original
              ? element.original
              : null,
          end: null,
        });
      }
      setWholeSaleSlots(slots);
    }
  }, [product]);


  const handleVisibleItems = () => {
    const totalLength = product?.variation?.props?.find(
      (item) => item.name === "Color"
    )?.values?.length;
    setVisibleItems(totalLength);
  };

  useEffect(() => {
    const response = getShippingCategories();
    response
      .then((res) => {
        setGlobalShipping({ ...globalShipping, categories: res?.data?.data });
      }).catch(({ response }) => {
          if (response?.data) {
            messageApi.open({
              type: 'error',
              content: response?.data?.message,
              style: {marginTop: '50px'}
            })
          }
        });
  }, []);

  const selectProductCategory = (_, label) => {
    const { id } = label;
    if (id) {
      setSelected(id)
    }
  };

  useEffect(() => {
    if (selected) {
      const response = calculateShipmentCost(selected);
      response
        .then((res) => {
          if (res?.data?.data) {
            setGlobalShipping(prevState => ({
              ...prevState,
              shippingInfo: res?.data?.data
            }));
          }
        }).catch(({ response }) => {
            if (response?.data) {
              messageApi.open({
                type: 'error',
                content: response?.data?.message,
                style: {marginTop: '50px'}
              })
            }
          });
    }
  }, [selected])

  useEffect(() => {
    if (globalShipping?.shippingInfo?.length > 0) {
      let foundElement = null;
      let priceSlots = globalShipping?.shippingInfo?.[0]?.categoryPriceSlots;
      let result = priceSlots.filter((item) => {
        const minAmount = parseFloat(item.min_amount);
        const maxAmount = parseFloat(item.max_amount);
        if (estimatedWeight >= minAmount && estimatedWeight <= maxAmount) {
          foundElement = item;
          return foundElement;
        } else if (estimatedWeight >= 0 && estimatedWeight <= 1) {
          foundElement = item;
          return foundElement;
        }
      })
      if (foundElement) {
        const { aw_category_id, rate_per_unit } = foundElement;
        setAwCategoryId(aw_category_id)
        setEstimatedPrice(rate_per_unit);
      }
    }
  }, [globalShipping])

  const handleSearch = (value) => {
    const response = getShippingCategoriesWithQuery(value);
    setLoadingCategory(true)
    response.then(res => setGlobalShipping({ ...globalShipping, categories: res?.data?.data }))
        .catch(({ response }) => {
          if (response?.data) {
            messageApi.open({
              type: 'error',
              content: response?.data?.message,
              style: {marginTop: '50px'}
            })
          }
        }).finally(x=> setLoadingCategory(false))
  };

  const handleEstimatedWeight = (value) => {
    const newWeight = value;
    setEstimatedWeight(newWeight);
  };

  useEffect(() => {
    if (estimatedWeight) {
      if (globalShipping?.shippingInfo?.length > 0) {
        let foundElement = null;
        let priceSlots = globalShipping?.shippingInfo?.[0]?.categoryPriceSlots;
        let result = priceSlots.filter((item) => {
          const minAmount = parseFloat(item.min_amount);
          const maxAmount = parseFloat(item.max_amount);
          if (estimatedWeight >= minAmount && estimatedWeight <= maxAmount) {
            foundElement = item;
            return foundElement;
          }
        })
        if (foundElement) {
          const { aw_category_id, rate_per_unit } = foundElement;
          setAwCategoryId(aw_category_id)
          setEstimatedPrice(rate_per_unit);
        }
      }
    }
  }, [estimatedWeight])

  const onFinish = () => {
    form.resetFields();
    setOpenModal(false);
    setSlot(false)
    const totalPrice = estimatedWeight * estimatedPrice
    setGlobalShipping({
      ...globalShipping,
      shippingInfo: [],
      price: totalPrice
    });
    messageApi.open({
      type: 'success',
      content: 'Price added successfully!',
      style: { marginTop: '50px' }
    });

  };

  const showSlot = () => {
    setSlot((prevSlot) => !prevSlot);
  }
  const SaveProduct = () => {
    if (!awCategoryId) {
      setOpenModal(true)
    } else {
      setOpenModal(false)
      const payload = {
        product: {
          store_id: product.shop_id,
          aw_category_id: awCategoryId,
          vid: product.vid,
          title: product.title,
          description: product.description,
          link: product.link,
          image: product.image,
          ratings_average: product.ratings_average,
          price: product.price,
          sales: product.sales,
          meta: {
            weight: estimatedWeight,
            vendor: product?.meta?.vendor
          },
          gallery: product.gallery,
          variation: product.variation,
          specifications: product.specifications
        }
      }
      setIsLoading(true)
      const response = saveProduct(payload)
      response.then(res => {
        if (res?.status === 200) {
          messageApi.open({
            type: 'success',
            content: 'Product saved successfully!',
            style: { marginTop: '50px' }
          });
          setGlobalShipping(prevState => ({
            ...prevState,
            price: null
          }));
        }
        setIsLoading(false)
        setEstimatedPrice(null)
        setEstimatedWeight(null)
        messageApi.open({
          type: 'loading',
          content: 'Redirecting..',
          duration: 0,
        });
        // Dismiss manually and asynchronously
        setTimeout(messageApi.destroy, 2500);

        setTimeout(()=> {
          history.push({
            pathname: `/saved-products`,
          });
        },1500)

      }).catch(({ response }) => {
            if (response?.data) {
              messageApi.open({
                type: 'error',
                content: response?.data?.message,
                style: {marginTop: '50px'}
              })
            }
          })
        .finally(x => setIsLoading(false))
    }
  }

  const buyProduct = ()=> {
    const queryParams = {
      productUrl: product.link,
    };

    const queryString = new URLSearchParams(queryParams).toString();
    const placeOrder = `/place-order?${queryString}`;

    history.push(placeOrder);
  }

  const downloadZip = () => {
    ExportToZip(product)
  };

  const handleDownload = (imageUrl) => {
    handleImageDownload(imageUrl)
  };

  const menu = (
    <Menu >
      <CopyToClipboard text={clipboard.currentImage} >
        <Menu.Item key="copy" icon={<CopyOutlined />} onClick={() => {
          setClipboard({ ...clipboard, imageUrlText: "Url copied to clipboard" })
          setTimeout(() => {
            setClipboard({ ...clipboard, isCopied: false, isHovered: false, imageUrlText: "" })
          }, 4000)
        }}>
          Copy Url
        </Menu.Item>
      </CopyToClipboard>
      <Menu.Item key="download" icon={<DownloadOutlined />} onClick={() => handleDownload(clipboard.currentImage)}>
        Download
      </Menu.Item>
    </Menu>
  );

  const clipBoard = (
    <Menu style={{ width: "200px" }}>
      <CopyToClipboard text={product.title} onCopy={() => {
        setClipboard({ ...clipboard, isCopied: true, isHovered: true, titleText: "Copied to clipboard" })
        setTimeout(() => {
          setClipboard({ ...clipboard, isCopied: false, isHovered: false, titleText: "" })
        }, 4000)
      }}>
        <Menu.Item key="copy" icon={<CopyOutlined />}>
          Copy title
        </Menu.Item>
      </CopyToClipboard>
    </Menu>
  )

  useEffect(() => {
    if (variation.data.length > 0) {
      const propsArray = product?.variation?.skus;
      const variationArray = variation.data
      const findValue = (propsArray, variationArray) => {
        for (const variation of variationArray) {
          const variationValue = variation.value;
          const matchingObject = propsArray.find(prop => {
            const propValues = prop.props.split(',');
            return propValues.includes(variationValue);
          });
          if (matchingObject) {
            return matchingObject;
          }
        }
        return null;
      };
      const matchingSku = findValue(propsArray, variationArray);
      setVariation({
        ...variation,
        combinedPrice: matchingSku?.price?.actual
      })
    }
  }, [variation.data]);

  const handleVariationChange = (label, value,image,thumb) => {
    const labelIndex = variation.data.findIndex(item => item.label === label);
    if (labelIndex === -1) {
      setVariation(prevVariation => ({
        ...prevVariation,
        data: [...prevVariation.data, { label, value,image,thumb }],
      }));
    } else {
      setVariation(prevVariation => {
        const newData = [...prevVariation.data];
        newData[labelIndex] = { label, value,image,thumb };
        return { ...prevVariation, data: newData };
      });
    }
  };

  return (
    <div className="product-details-wrapper" style={{ opacity: loading ? '0.5' : '1' }}>
      {contextHolder}
      <Layout
        style={{
          margin: "4px 0px",
          padding: 24,
          minHeight: "screen",
          background: "white",
          borderRadius: "8px",
          width: "75%",
          gap: "24px",
          border: "1px solid #E5E7EB",
          position: 'relative'
        }}
      >
        {loading && <Spin style={{ position: 'absolute', left: '50%', top: '15%' }} size="large" />}

        <Row
          style={{
            width: "95%",
            paddingTop: "42px",
            margin: "0 auto",
            display: "flex",
            alignItems: "center",
            whiteSpace: "wrap",
          }}
        >
          <Col
            xs={12}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            xxl={12}
            style={{ display: "flex" }}
          >
            <p className="product-details-top-text1">Product List</p>
            <p className="product-details-top-text2">Imported Products</p>
          </Col>
        </Row>
        <Row
          gutter={[{ lg: 24, xxl: 24 }, { xs: 24 }]}
          className="product-details-product-section"
        >
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
            <Row style={{ width: '100%' }}>
              <Row style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Image
                  style={{ maxWidth: '100%', borderRadius: "8px", height: 'auto' }}
                  // height={513}
                  src={imagePreview ? imagePreview : product.image}
                />
              </Row>
              <Row style={{ width: "100%" }}>
                <Swiper
                  slidesPerView={5}
                  spaceBetween={16}
                  style={{ margin: '12px auto' }}
                  navigation={{ nextEl: ".arrow-left", prevEl: ".arrow-right" }}
                  breakpoints={{
                    390: {
                      slidesPerView: 3
                    },
                    600: {
                      slidesPerView: 4
                    },
                    700: {
                      slidesPerView: 5
                    },
                    850: {
                      slidesPerView: 6
                    },
                    1200: {
                      slidesPerView: 3
                    },
                    1350: {
                      slidesPerView: 4
                    },
                    1500: {
                      slidesPerView: 5

                    }
                  }}
                >
                  {/* product gallery */}
                  {product?.gallery?.map((image, index) => (
                    <SwiperSlide key={index} style={{ position: 'relative' }}
                      onMouseEnter={() => {
                        const result = product?.gallery?.find((item, idx) => idx === index)
                        if (result.url === image.url) {
                          setClipboard({ ...clipboard, isHovered: true, currentImage: image.url })
                        }
                      }}
                      onMouseLeave={() => setClipboard({ ...clipboard, isHovered: false, currentImage: null })}
                    >
                      <Dropdown overlay={menu} placement="bottomLeft">
                        <Image
                          onClick={() => setImagePreview(image.url)}
                          preview={false}
                          style={{
                            borderRadius: "8px",
                            height: "100px",
                            width: "100px",
                            cursor: "pointer",
                          }}
                          className="darken-on-hover"
                          src={image.thumb}
                        />
                      </Dropdown>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Row>
            </Row>
            {clipboard.imageUrlText && <Row>
              <div style={{ display: 'flex', alignItems: 'center', paddingTop: "10px" }}>
                <TickIcon />
                <span className="product-details-clipboard-text">{clipboard.imageUrlText}</span>
              </div>
            </Row>}
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
            <div style={{ display: 'flex', alignItems: 'center' }}
            >
              <div className="title-container">
                <Dropdown overlay={clipBoard} placement="bottomLeft">
                  <span className="product-details-title" >
                    {product.title}
                  </span>
                </Dropdown>
              </div>
            </div>
            {clipboard.isCopied && (
              <Row>
                <div style={{ display: 'flex', alignItems: 'center', paddingTop: "5px" }}>
                  <TickIcon />
                  <span className="product-details-clipboard-text">Copied to clipboard</span>
                </div>
              </Row>
            )}
            <Row
              gutter={[16, 16]}
              style={{
                display: "flex",
                alignItems: "center",
                paddingTop: "12px",
              }}
            >
              <Col>
                <span>
                  {[1, 2, 3, 4, 5]
                    .filter((elm) => elm <= parseInt(product?.ratings_average))
                    .map((rating, index) => {
                      return (
                        <span key={index} style={{ paddingRight: "4px" }}>
                          <ProductDetailsRating />
                        </span>
                      );
                    })}
                </span>
              </Col>
              {product?.ratings && (
                <Col>
                  <span className="product-details-total-review">
                    {product.ratings_average &&
                      parseInt(product.ratings_average, 10)}
                    ({product.ratings_count ? product.ratings_count : "0"})
                  </span>
                </Col>
              )}
              {product?.sales && (
                <Col>
                  <span className="product-details-total-sale">
                    {product.sales} Monthly Sales
                  </span>
                </Col>
              )}
              <Col style={{ display: 'flex', justifyContent: 'end' }} >
                <span>
                  <a href={product.link} target="_blank" rel="noreferrer" className="product-details-item-link">
                    View Original Link
                  </a>
                </span>
              </Col>
            </Row>

            {/* wholesale price quantities */}

            {product?.price?.wholesales && (
              <Row gutter={[12, 16]} className="product-details-wholesale-wrapper">
                {wholeSaleSlots?.map((item, index) => (
                  <Col key={index} xs={24} sm={12} md={12} lg={8} xl={12} xxl={8}>
                    <div className="product-details-price-wrapper">
                      <span className="product-details-price">
                        ৳{(item.price * totalFx).toFixed(2)}
                      </span>
                      <span className="product-details-price-quantity">
                        {item.begin && item.end
                          ? `${item.begin} - ${item.end} Pcs`
                          : `≥ ${item.begin} Pcs`}
                      </span>
                    </div>
                  </Col>
                ))}
              </Row>
            )}
            <Row style={{ paddingTop: "16px" }}>
              <p className="product-details-domestic-shipping">
                Estimated domestic shipping:
                <span className="product-details-domestic-shipping-amount">
                  &nbsp;৳{domestic_shipping}
                </span>
              </p>
            </Row>

            {/* variation layout */}

            {product?.variation?.props?.length > 0 ? product?.variation?.props?.map((prop) => (
              <div style={{ marginTop: '16px' }}>
                <Row>
                  <p className="product-details-color-title">{prop.name}</p>
                </Row>
                <Row
                  style={{
                    gap: "16px",
                    marginTop: "6px",
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {prop.values.map((value, index) => (
                    <Col
                      key={index}
                      xs={4}
                      sm={3}
                      md={2}
                      lg={2}
                      xl={3}
                      xxl={2}
                    >
                      {value?.image ? (
                        <Tooltip placement="bottom" title={value.name}>
                          <Image
                            onClick={() => {
                              setImagePreview(value?.image);
                              handleVariationChange(prop.name, value?.id, value?.image , value?.thumb);
                            }}
                            preview={false}
                            style={{
                              cursor: "pointer",
                              objectFit: "cover",
                              width: "100%",
                              height: "100%",
                              borderRadius: "8px",
                              border:
                                value?.id === variation?.data?.find((item) => item.value === value?.id)?.value
                                  ? "2px solid #F37B78"
                                  : "1px solid #E5E7EB",
                            }}
                            src={value.image}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip placement="bottom" title={value.name}>
                          <div
                            onClick={() => {
                              handleVariationChange(prop.name, value?.id,value?.image , value?.thumb);
                            }}
                            style={{
                              cursor: "pointer",
                              whiteSpace: "nowrap",
                              borderRadius: "8px",
                              border:
                                value?.id === variation?.data?.find((item) => item.value === value?.id)?.value
                                  ? "2px solid #F37B78"
                                  : "1px solid #E5E7EB",
                              padding: "10px 12px",
                              textAlign: "center",
                              overflow: "hidden",
                              textOverflow: 'ellipsis'
                            }}
                          >
                            {value.name}
                          </div>
                        </Tooltip>
                      )}
                    </Col>
                  ))}
                </Row>
              </div>
            )) :
              (product?.variation?.skus?.map((sku)=> (
                <>
                <Row style={{ paddingTop: "32px" }}>
                  <p className="product-details-domestic-shipping">
                    Price: &nbsp;৳
                    <span className="product-details-domestic-shipping-amount">
                  {(sku.price.actual*totalFx).toFixed(2)}
                </span>
                  </p>
                </Row>
                  <Row style={{ paddingTop: "12px" }}>
                    <p className="product-details-domestic-shipping">
                      Stock: &nbsp;
                      <span className="product-details-domestic-shipping-amount">
                  {sku.stock.available}
                </span>
                    </p>
                  </Row>
                </>
              )))
            }

            {/* product details cta section */}

            <Row
              gutter={[12, 12]}
              className="product-details-cta"
              style={{ paddingTop: '32px' }}
            >
              <Col xs={24} xl={8} xxl={10}>
                <Button
                  style={{
                    width: '100%',
                    color: "#FFF",
                    padding: "12px 32px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: 'center',
                    background: "#F37B7B",
                    borderRadius: "5px",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "16px",
                  }}
                  onClick={SaveProduct}
                >
                  <ProductDetailsButtonAdd />
                  <span>Save Products </span>
                </Button>
              </Col>
              <Col xs={24} xl={8} xxl={6}>
                  <Button
                    style={{
                      width: '100%',
                      color: "#FFF",
                      padding: "12px 32px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: 'center',
                      background: "#F37B7B",
                      borderRadius: "5px",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "16px",
                    }}
                    onClick={buyProduct}
                  >
                    Buy Now
                  </Button>
              </Col>
              <Col xs={24} xl={8} xxl={8}>
                <Tooltip placement="top" >
                  <Button
                    style={{
                      width: '100%',
                      color: "#FFF",
                      padding: "12px 42px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: 'center',
                      background: "#F37B7B",
                      borderRadius: "5px",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "16px",
                    }}
                    onClick={downloadZip}
                  >
                    Export as zip
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* product specification section */}
        <Layout
          style={{
            border: "1px solid #E5E7EB",
            width: "100%",
            margin: "0 auto",
            borderRadius: "8px",
            position: 'relative'
          }}
          className="specification-container"
        >
          <Tabs
            defaultActiveKey="1"
            items={items}
            style={{
              paddingLeft: "24px",
              paddingTop: "28px",
              fontSize: "16px",
            }}
            className="product-specification-tab-btn"
          />
        </Layout>
      </Layout>
      <CostEstimate variation={variation} globalShipping={globalShipping} setOpenModal={setOpenModal} totalFx={totalFx} product={product} />
      <GlobalShippingModal
        product={product}
        openModal={openModal}
        setOpenModal={setOpenModal}
        globalShipping={globalShipping}
        setGlobalShipping={setGlobalShipping}
        setEstimatedWeight={setEstimatedWeight}
        slot={slot}
        setSlot={setSlot}
        onFinish={onFinish}
        selectProductCategory={selectProductCategory}
        handleSearch={handleSearch}
        estimatedWeight={estimatedWeight}
        handleEstimatedWeight={handleEstimatedWeight}
        estimatedPrice={estimatedPrice}
        showSlot={showSlot}
        loadingCategory={loadingCategory}
      />
    </div>
  );
}

export default ProductsDetails;
