import React, { useCallback, useEffect, useState } from "react";
import { Alert, Card, Col, message, Row, Skeleton } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { ServiceQuotas } from "components";
import { getHomepage, getServiceQuota } from "utils/generic-client";

const Dashboard = () => {
  const [homeData, setHomeData] = useState({
    notice: null,
  });
  const [serviceQuota, setServiceQuota] = useState([]);
  const [isHomeFetching, setIsHomeFetching] = useState(false);
  const [isServiceQuotaFetching, setIsServiceQuotaFetching] = useState(false);

  const fetchHomepage = useCallback(() => {
    setIsHomeFetching(true);
    getHomepage()
      .then((res) => {
        if (res && res.data && res.data.data) {
          let homepageData = res.data.data;
          setHomeData(homepageData);
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 403) {
            message.error("The action is not authorized");
          } else {
            message.error(
              err.response?.data?.data
                ? err.response.data.data.message
                : "Unknown error"
            );
          }
        } else {
          message.error("Unknown error");
        }
      })
      .finally((x) => {
        setIsHomeFetching(false);
      });
  }, []);

  const fetchServiceQuota = useCallback(() => {
    setIsServiceQuotaFetching(true);
    getServiceQuota()
      .then((res) => {
        if (res && res.data && res.data.data) {
          let serviceQuota = res.data.data;
          setServiceQuota(serviceQuota);
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 403) {
            message.error("The action is not authorized");
          } else {
            message.error(
              err.response?.data?.data?.data
                ? err.response.data.data.data.message
                : "Unknown error"
            );
          }
        } else {
          message.error("Unknown error");
        }
      })
      .finally((x) => {
        setIsServiceQuotaFetching(false);
      });
  }, []);

  useEffect(() => {
    fetchHomepage();
    fetchServiceQuota();
  }, [fetchHomepage, fetchServiceQuota]);

  const notice =
    homeData && homeData.length > 0
      ? homeData.find((item) => item.key === "moveon-drop-home-notice")
      : null;

  return (
    <Row>
      <Col span={24}>
        <PageHeader ghost={false}>
          <Skeleton loading={isHomeFetching}>
            {notice && notice.value && notice.value.text && (
              <Row style={{ marginBottom: 20 }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Alert
                    description={notice.value.text}
                    type="success"
                    showIcon
                  />
                </Col>
              </Row>
            )}
          </Skeleton>

          <Skeleton loading={isServiceQuotaFetching}>
            {serviceQuota && serviceQuota.length > 0 && (
              <Row style={{ marginBottom: 20 }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Card
                    title={
                      <span style={{ display: "flex" }}>
                        Service Quota Limit
                      </span>
                    }
                  >
                    <ServiceQuotas serviceQuotas={serviceQuota} />
                  </Card>
                </Col>
              </Row>
            )}
          </Skeleton>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Card
                title={
                  <span style={{ display: "flex" }}>A Quick Overview</span>
                }
              >
                <Row style={{ marginTop: 20 }}>
                  <Col
                    xs={24}
                    sm={24}
                    md={16}
                    lg={16}
                    xl={16}
                    xxl={16}
                    style={{ margin: "0 auto" }}
                  >
                    <iframe
                      src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fmoevondrop%2Fvideos%2F651605729494600%2F&show_text=true&width=560&t=0"
                      width="100%"
                      height="429"
                      scrolling="no"
                      frameBorder="0"
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                      allowFullScreen="true"
                      title="Facebook video player"
                      style={{
                        display: "flex",
                        border: "none",
                        overflow: "hidden",
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </PageHeader>
      </Col>
    </Row>
  );
};

export default Dashboard;
