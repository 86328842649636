import React, { useState, useEffect, useRef } from 'react'
import {
    Form,
    Tooltip,
    List,
    Table,
    Layout,
    Row,
    Col,
    Spin,
    Checkbox,
    Input,
    Select,
    Button,
    Radio,
    Image,
    message,
    Empty
} from "antd";
import TableData from './TableData';
import "./editandimport.css"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getShippingCategories, getShippingCategoriesWithQuery, singleSavedProduct, calculateShipmentCost, updateProduct } from 'utils/product-client';
import {getWebsites} from "../../../utils/oauth-client";
import { useLocation } from "react-router-dom"
import { store_rates } from 'consts/products';
import { getProductDetails } from 'utils/product-client';
import { useHistory } from "react-router-dom";
import moment from 'moment'

function EditAndImport() {

    const [form] = Form.useForm();
    const mySectionRef = useRef(null);
    const imageSectionRef = useRef(null)
    const [messageApi, contextHolder] = message.useMessage();
    const location = useLocation();
    const url = location.pathname;
    const pathSegments = url.split('/');
    const productId = pathSegments[pathSegments.length - 1];
    const [payload, setPayload] = useState({
        title: ""
    })
    const [websites, setWebsites] = useState([]);
    const [editorHtml, setEditorHtml] = useState('');
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [shipment, setShipment] = useState([]);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [wholeSaleSlots, setWholeSaleSlots] = useState(null);
    const [slots, setSlots] = useState([])
    const [shippingInfo, setShippingInfo] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedVariants, setSelectedVariants] = useState([]);
    const [fetchLatestProduct, setFetchLatestProduct] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingCategory, setLoadingCategory] = useState(false);
    const [totalFx, setTotalFx] = useState(null);
    const [awCategoryId, setAwCategoryId] = useState(null);
    const history = useHistory();

    useEffect(() => {
        if (productId) {
            const response = singleSavedProduct(productId)
            response.then(res => {
                const totalFx = store_rates.find(
                    (item) => item.storeId === res?.data?.product?.store_id
                )?.totalFx;
                setTotalFx(totalFx)
                setCurrentProduct(res?.data?.product)
                }
            ).catch(({ response }) => {
                if (response?.data) {
                    messageApi.open({
                        type: 'error',
                        content: response?.data?.message,
                        style: {marginTop: '50px'}
                    })
                }
            })
        }
    }, [productId, loading])

    useEffect(() => {
        form.setFieldsValue({
            'Product Title': currentProduct?.title,
        });
    }, [currentProduct])

    // to show wholesale quantity
    useEffect(() => {
        if (currentProduct?.price?.wholesales) {
            let wholeSalePrices = currentProduct.price.wholesales;
            wholeSalePrices.sort((first, second) => first.qty - second.qty);
            let slots = [];
            for (let i = 0; i < wholeSalePrices.length; i++) {
                const element = wholeSalePrices[i];
                let begin = element.qty;
                if (slots[i - 1]) {
                    let prevBegin = slots[i - 1]["begin"];
                    let end = element.qty - 1;
                    slots[i - 1]["end"] = end;
                }
                slots.push({
                    begin: begin,
                    price: element.discount
                        ? element.discount
                        : element.original
                            ? element.original
                            : null,
                    end: null,
                });
            }
            setWholeSaleSlots(slots);
        }
    }, [currentProduct]);

    const columns = [
        {
            title: "Shipping",
            dataIndex: 'shipping',
            render: (_, record) => (
                <>
                    <span className='edit-import-shipping-table-cell-text'> <Radio value={1}></Radio>{record.shipping}</span>
                </>
            )
        },
        {
            title: "Cost",
            dataIndex: 'cost',
            render: (_, record) => (
                <>
                    <span className='edit-import-shipping-table-cell-text' >{record.cost}/{record.unit}</span>
                </>
            )
        },
        {
            title: "Delivery",
            dataIndex: 'delivery',
            render: (_, record) => (
                <>
                    <span className='edit-import-shipping-table-cell-text'>{record.delivery}</span>
                </>
            )
        }
    ]

    //get websites list
    useEffect(() => {
        const response = getWebsites();
        response.then((res) => {
            if(res?.data?.length > 0 ) {
                const mappedData = res?.data?.map((website)=> ({
                    id: website.id,
                    value: website.name.toLowerCase(),
                    label: website.name
                }))
                setWebsites(mappedData);
            }
        }).catch(({ response }) => {
            if (response?.data) {
                messageApi.open({
                    type: 'error',
                    content: response?.data?.message,
                    style: {marginTop: '50px'}
                })
            }
        })
    }, [])

    const handleWebsiteCheckboxChange = (websiteId) => {
        const updatedWebsites = websites.map((website) =>
            website.id === websiteId ? { ...website, checked: !website.checked } : website
        );
        setWebsites(updatedWebsites);
    };

    const handleAllCheckboxChange = (e) => {
        const updatedWebsites = websites.map((website) => ({
            ...website,
            checked: e.target.checked,
        }));
        setWebsites(updatedWebsites);
    };

    const importNow = ()=> {
        messageApi.open({
            type: 'loading',
            content: 'Import now is processing',
            duration: 0,
            style: {marginTop: '50px'}
        })
        setTimeout(messageApi.destroy, 2500);
    }

    const websitesList = (
        <div style={{ background: "#FFF" }}>
            <div style={{ padding: "24px 24px 0px 24px" }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <span style={{ whiteSpace: 'nowrap' }} className='edit-import-tooltip-text1'>Your websites</span>
                    </div>
                    <div>
                        <Checkbox onChange={handleAllCheckboxChange}>
                            <span className='edit-import-tooltip-checkbox-text'>All</span>
                        </Checkbox>
                    </div>
                </div>
                <div style={{ marginTop: '7px' }} >
                    <p style={{ whiteSpace: 'wrap' }} className='edit-import-tooltip-text2'>Import this item into your websites</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', marginTop: '26px' }}>
                    {websites?.map((website)=> (
                        <li key={website.id} >
                            <Checkbox
                                onChange={()=> handleWebsiteCheckboxChange(website.id)}
                                checked={website.checked}
                            >
                                <span className='edit-import-tooltip-list-text'>{website.label}</span>
                            </Checkbox>
                        </li>
                    ))}
                </div>
                <div style={{ paddingTop: '24px', paddingBottom: '24px' }}>
                    <Button className='edit-import-tooltip-btn' onClick={()=>importNow()}>
                        <span className='edit-import-tooltip-btn-text'>Import now</span>
                    </Button>
                </div>
            </div>
        </div>
    )

    const handleCheckboxChange = (e, item) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setSelectedImages((prevSelected) => [...prevSelected, item]);
        } else {
            setSelectedImages((prevSelected) => prevSelected.filter((image) => image.id !== item.id));
        }
        setSelectAll(selectedImages.length === currentProduct?.gallery.length);
    };

    const handleSelectAllChange = (e) => {
        const isChecked = e.target.checked;
        setSelectAll(isChecked);
        if (isChecked) {
            setSelectedImages(currentProduct?.gallery.map((image, index) => {
                return {
                    id: index,
                    url: image.url,
                    thumb: image.thumb,
                    title: image.title
                }
            }))
        } else {
            setSelectedImages([]);
        }
    };

    useEffect(() => {
        const response = getShippingCategories();
        response
            .then((res) => {
                setShipment(res?.data?.data);
            }).catch(({ response }) => {
            if (response?.data) {
                messageApi.open({
                    type: 'error',
                    content: response?.data?.message,
                    style: {marginTop: '50px'}
                })
            }
        })
    }, []);

    const selectProductCategory = (_, label) => {
        if (label === undefined) {
            setShippingInfo([])
            return
        }
        const { id } = label;
        const response = calculateShipmentCost(id);
        response.then(res => {
            setAwCategoryId(res?.data?.data?.[0]?.id)
            setSlots(res?.data?.data)
            const shipping = res?.data?.data?.map((item) => {
                return {
                    shipping: item.warehouse.name,
                    cost: item.rate,
                    unit: item.unit_type,
                    delivery: item.warehouse.est_sh_time
                }
            })
            setShippingInfo(shipping)
        }).catch(({ response }) => {
            if (response?.data) {
                messageApi.open({
                    type: 'error',
                    content: response?.data?.message,
                    style: {marginTop: '50px'}
                })
            }
        })
    };

    const handleSearch = (value) => {
      setLoadingCategory(true)
        const response = getShippingCategoriesWithQuery(value)
        response.then(res => setShipment(res?.data?.data))
            .catch(({ response }) => {
                if (response?.data) {
                    messageApi.open({
                        type: 'error',
                        content: response?.data?.message,
                        style: {marginTop: '50px'}
                    })
                }
            }).finally(x=> setLoadingCategory(false))
    };

    const editProduct = (values) => {
        if (selectedVariants?.length === 0) {
            messageApi.open({
                type: 'warning',
                content: 'Please select a variant',
                style: { marginTop: '50px' }
            });
            scrollToSection()
        } else if (selectedImages?.length === 0) {
            messageApi.open({
                type: 'warning',
                content: 'Please select at least one image!',
                style: { marginTop: '50px' }
            });
            scrollToImageSection()
        }
        else {
            const payload = {
                product: {
                    id: currentProduct?.id,
                    store_id: currentProduct?.store_id ? currentProduct?.store_id : currentProduct?.shop_id,
                    aw_category_id: awCategoryId,
                    vid: currentProduct?.vid,
                    title: values['Product Title'],
                    description: null,
                    link: currentProduct?.link,
                    image: currentProduct?.image,
                    ratings_average: currentProduct?.ratings_average,
                    price: currentProduct?.price,
                    sales: currentProduct?.sales,
                    meta: currentProduct?.meta,
                    gallery: selectedImages?.map((image) => {
                        return {
                            url: image.url,
                            thumb: image.thumb,
                            title: image.title
                        }
                    }),
                    variation: {
                        skus:
                            selectedVariants?.map((variation) => {
                                return {
                                    id: variation.id,
                                    price: {
                                        offer: variation.price.offer,
                                        actual: variation.price.actual,
                                        preorder: variation.price.preorder
                                    },
                                    props: variation.props,
                                    stock: {
                                        min: variation.min_stock,
                                        available: variation.stock
                                    }
                                }
                            }),
                        props: currentProduct?.variation?.props
                    },
                    specifications: currentProduct?.specifications
                }
            }
            setLoading(true)
            const response = updateProduct(currentProduct?.id, payload)
            response.then(res => {
                setLoading(false)
                messageApi.open({
                    type: 'success',
                    content: 'Product Updated Successfully!',
                    style: { marginTop: '50px' }
                });
            }).catch(({ response }) => {
                if (response?.data) {
                    messageApi.open({
                        type: 'error',
                        content: response?.data?.message,
                        style: {marginTop: '50px'}
                    })
                }
            }).finally(x => {
                setLoading(false)
                setSelectedImages([]);
                setSelectedVariants([]);
                setSelectedRowKeys([])
            })
        }
    }

    const scrollToSection = () => {
        if (mySectionRef.current) {
            mySectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToImageSection = () => {
        if (imageSectionRef.current) {
            imageSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const detailsPage = (link) => {
        const response = getProductDetails(link);
        response
            .then((res) => {
                if (res?.status === 200) {
                    history.push({
                        pathname: `/productDetails/product?url=${encodeURIComponent(link)}`,
                        state: res?.data?.data,
                    });
                }
            }).catch(({ response }) => {
            if (response?.data) {
                messageApi.open({
                    type: 'error',
                    content: response?.data?.message,
                    style: {marginTop: '50px'}
                })
            }
        })
    };

    const getLatestProductData = () => {
        setFetchLatestProduct(true)
    }

    useEffect(() => {
        if (fetchLatestProduct) {
            setFetchLatestProduct(true)
            const response = getProductDetails(currentProduct?.link);
            response.then((res) => {
                const totalFx = store_rates.find(
                    (item) => item.storeId === res?.data?.data?.shop_id
                )?.totalFx;
                setTotalFx(totalFx)
                const updatedProduct = { ...res?.data?.data, id: Number(productId) };
                setCurrentProduct(updatedProduct);
                messageApi.open({
                    type: 'success',
                    content: 'Product Fetched Successfully!',
                    style: { marginTop: '50px' }
                });
                setSelectedImages([]);
                setSelectedVariants([])
            }).catch(({ response }) => {
                if (response?.data) {
                    messageApi.open({
                        type: 'error',
                        content: response?.data?.message,
                        style: {marginTop: '50px'}
                    })
                }
            }).finally(x => setFetchLatestProduct(false))
        }
    }, [fetchLatestProduct])

    useEffect(() => {
        const apiResponse = currentProduct?.specifications?.map((item) => {
            return {
                label: item.label.name,
                value: item.value.name
            }
        })

        const initialValue = apiResponse?.map((item) => `<div>
        <li>
        <span style={{fontWeight: 700}}>${item.label}</span>: ${item.value}
        </li>
        </div>`).join("");

        setEditorHtml(initialValue);
    }, [currentProduct]);

    const handleEditorChange = (content, delta, source, editor) => {
        setEditorHtml(content);
    };


    const nestedColumns = [
        {
            title: 'SL',
            dataIndex: 'sl',
            render: (_, record) => (
                <>
                    <span className='edit-import-shipping-table-cell-text'>{record.sl}</span>
                </>
            )
        },
        {
            title: 'Slot',
            dataIndex: 'slot',
            render: (_, record) => (
                <>
                    <span className='edit-import-shipping-table-cell-text'>{record.slot}</span>
                </>
            )
        },
        {
            title: 'Rate',
            dataIndex: 'rate',
            render: (_, record) => (
                <>
                    <span className='edit-import-shipping-table-cell-text' >{record.rate}/{record.unit_type}</span>
                </>
            )
        },
        {
            title: 'Delivery',
            dataIndex: 'delivery',
            render: (_, record) => (
                <>
                    <span className='edit-import-shipping-table-cell-text'>{record.delivery}</span>
                </>
            )
        },
    ];

    const nestedData = slots?.[0]?.categoryPriceSlots?.map((slot, index) => {
        return {
            sl: index + 1,
            slot: `${slot.min_amount}-${slot.max_amount}`,
            rate: slot.rate_per_unit,
            delivery: slots?.[0]?.warehouse.est_sh_time,
            unit_type: slots?.[0]?.unit_type
        }
    })

    const expandedRowRender = () => {
        return (
            <Table
                style={{ width: "80%", margin: '0 auto', paddingTop: '24px', paddingBottom: '24px' }}
                columns={nestedColumns}
                dataSource={nestedData}
                pagination={false}
                size="small"
            />
        );
    };

    return (
        <Layout
            style={{
                margin: "0 auto",
                height: "screen",
                paddingTop: "42px",
                paddingBottom: "42px",
                background: "#FFF",
                borderRadius: '8px',
                boxShadow: '0px 4px 4px 0px rgba(29, 49, 72, 0.08)',
                opacity: loading || fetchLatestProduct ? '0.5' : '1',
                position: 'relative'
            }}
        >
            {contextHolder}
            {(loading || fetchLatestProduct) && <Spin style={{ position: 'absolute', zIndex: 1000, top: '15%', left: '50%' }} size="large" />}
            <Form onFinish={editProduct} layout="vertical" form={form}>
                <Row gutter={[0, 16]} style={{ width: "95%", margin: "0 auto" }}>
                    <Col xl={12} xxl={12} >
                        <span className='edit-import-top-text'>{currentProduct?.title}</span>
                    </Col>
                    <Col xl={12} xxl={12} className='edit-import-top-cta-wrapper'>
                        <Col >
                            <Tooltip title={'upcoming'}>
                                <Form.Item>
                                    <Button style={{ width: '100px', cursor: 'not-allowed' }} className="edit-import-top-cta1">Translate</Button>
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col >
                            <Form.Item>
                                <Button htmlType="submit" style={{ width: '100px' }} className="edit-import-top-cta2">Save</Button>
                            </Form.Item>
                        </Col>
                        <Col >
                            <Tooltip placement="bottomRight" title={websitesList} arrow={false} trigger="click"   >
                                <Form.Item>
                                    <Button style={{ width: '140px' }} className="edit-import-top-cta3" >Edit & Import</Button>
                                </Form.Item>
                            </Tooltip>
                        </Col>
                    </Col>
                </Row>
                <div
                    style={{
                        border: "1px solid #E5E7EB",
                        padding: "24px 24px",
                        width: "95%",
                        margin: "24px auto 0px",
                        borderRadius: "8px",
                        background: "#FFF",
                    }}
                >
                    <Row style={{ gap: "12px", borderBottom: "1px solid #E5E7EB", paddingBottom: "12px" }}>
                        <Col><span className='edit-import-status-text'>Product Status</span></Col>
                        <Col><span style={{ background: "#E6FAF4", padding: "6px 20px", borderRadius: "16px", border: "1px solid #03C68B", color: "#03C68B", fontSize: "14px" }} className='edit-import-status-text'>Available</span></Col>
                        <Col><span className='edit-import-status-text'>Latest update {moment(currentProduct?.updated_at).format("DD MMM'YY [at] HH:mm")}</span></Col>
                        <Col><span style={{ cursor: 'pointer' }} onClick={() => detailsPage(currentProduct?.link)} className='edit-import-status-text edit-import-status-link'>View original product</span></Col>
                    </Row>
                </div>
                <div
                    style={{
                        border: "1px solid #E5E7EB",
                        padding: "24px 24px",
                        width: "95%",
                        margin: "24px auto 0px",
                        borderRadius: "8px",
                        background: "#FFF",
                    }}
                >
                    <Row>
                        <span className='edit-import-info-header'>Product Info</span>
                    </Row>
                    <Row style={{ gap: "12px", paddingTop: "24px", borderBottom: "1px solid #E5E7EB", paddingBottom: "12px" }}>
                        <Col><span className='edit-import-info-text'>Source via</span></Col>
                        <Col><span className='edit-import-info-text-store'>{currentProduct?.meta?.vendor}</span></Col>
                        <Col><span style={{ cursor: 'pointer' }} className='edit-import-info-text edit-import-info-link' onClick={getLatestProductData}>Get latest product data</span></Col>
                    </Row>
                    <Row style={{ paddingTop: '24px' }}>
                        <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item label="Product Title" name={`Product Title`} rules={[
                                {
                                    required: true,
                                },
                            ]}>
                                <Input
                                    style={{ height: '40px' }}
                                    defaultValue={currentProduct?.title}
                                    onChange={(e) => setPayload({ ...payload, title: e.target.value })}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16} style={{ paddingTop: '16px' }}>
                        <Col span={12} xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} >
                            <Form.Item label="Shipment Category" name={`Shipment Category`} rules={[
                                {
                                    required: true,
                                },
                            ]}>
                                <Select
                                    showSearch
                                    onChange={selectProductCategory}
                                    onSearch={handleSearch}
                                    style={{ height: '40px' }}
                                    notFoundContent={loadingCategory ? <Spin size="small" style={{display: 'flex', justifyContent: 'center',alignItems:'center',height: '200px'}}/>: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> }
                                    options={shipment.map((category) => {
                                        return {
                                            value: category.name.toLowerCase(),
                                            label: category.name,
                                            id: category.id,
                                        };
                                    })}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} >
                            <Form.Item label="Origin" name={`Origin`}>
                                <Select
                                    defaultValue="China"
                                    style={{ height: '40px' }}
                                    options={[
                                        { value: "china", label: "China" },
                                        { value: "usa", label: "USA" },
                                        { value: "india", label: "India" },
                                        { value: "dubai", label: "Dubai" },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{ paddingTop: '24px' }}>
                        <label style={{ fontWeight: 700 }} className="edit-import-info-label">Default MoveOn Category Slot</label>
                        <Table
                            className="edit-import-shipping-table"
                            columns={columns}
                            dataSource={shippingInfo}
                            style={{ paddingTop: "24px" }}
                            pagination={false}
                            expandable={{ expandedRowRender }}
                        />
                    </div>
                </div>
                <Layout
                    style={{
                        border: "1px solid #E5E7EB",
                        padding: "24px 24px",
                        width: "95%",
                        margin: "24px auto 0px",
                        borderRadius: "8px",
                        background: "#FFF",
                    }}
                    ref={mySectionRef}
                >
                    <Row>
                        <span className='edit-import-variant-text'>Variants ({`${selectedVariants.length}/${currentProduct?.variation?.skus?.length}`})</span>
                    </Row>
                    {currentProduct?.price?.wholesales && (
                        <Row style={{ paddingTop: "21px", gap: "16px", display: 'flex', justifyContent: 'center' }}>
                            {wholeSaleSlots?.map((item, index) => (
                                <Col key={index} xs={14} sm={8} lg={8} xl={6} xxl={3}>
                                    <div className="product-details-price-wrapper">
                                        <span className="product-details-price">
                                            ৳{(item.price * totalFx).toFixed(2)}
                                        </span>
                                        <span className="product-details-price-quantity">
                                            {item.begin && item.end
                                                ? `${item.begin} - ${item.end} Pcs`
                                                : `> ${item.begin} Pcs`}
                                        </span>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    )}
                    <TableData
                        variation={currentProduct?.variation}
                        selectedRowKeys={selectedRowKeys}
                        setSelectedRowKeys={setSelectedRowKeys}
                        setSelectedVariants={setSelectedVariants}
                        currentProduct={currentProduct}
                        totalFx={totalFx}
                    />
                </Layout>

                {/* Image gallery section */}
                <div
                    style={{
                        border: "1px solid #E5E7EB",
                        padding: "24px 24px",
                        width: "95%",
                        margin: "24px auto 0px",
                        borderRadius: "8px",
                        background: "#FFF",
                    }}
                    ref={imageSectionRef}
                >
                    <Row gutter={[0, 16]} style={{ padding: "0px 0px", display: 'flex', justifyContent: 'space-between' }}>
                        <Col style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <span className='edit-import-images-text'>Gallery Images</span>
                            <span className='edit-import-gallery-select-wrapper'>
                                <Checkbox onChange={handleSelectAllChange} checked={selectAll}>
                                    <span className='edit-import-images-select-text'>Select all&nbsp;({`${selectedImages.length} / ${currentProduct?.gallery?.length}`})</span>
                                </Checkbox>
                            </span>
                        </Col>
                        <Tooltip title="upcoming">
                            <Col style={{ cursor: 'not-allowed', display: 'flex', justifyContent: "end", paddingLeft: "4px", }}>
                                <span className='edit-import-images-editor-text'>Photo editor</span>
                            </Col>
                        </Tooltip>
                    </Row>
                    <Row style={{ gap: "16px", padding: "24px 0px" }}>
                        <List
                            grid={{
                                gutter: 16,
                                column: 6,
                                xs: 2,
                                sm: 2,
                                md: 3,
                                lg: 4,
                                xl: 5,
                                xxl: 6
                            }}
                            dataSource={currentProduct?.gallery?.map((image, index) => {
                                return {
                                    id: index,
                                    url: image.url,
                                    thumb: image.thumb,
                                    title: image.title
                                }
                            })}
                            renderItem={(item) => (
                                <List.Item style={{ position: 'relative', width: '100%', height: '100%', }}>
                                    <Image src={item.url} alt="error" style={{ display: 'block', width: '100%', height: 'auto', borderRadius: '8px' }} />
                                    <Checkbox
                                        onChange={(e) => handleCheckboxChange(e, item)}
                                        checked={selectedImages.find(image => image.id === item.id)}
                                        style={{ position: 'absolute', top: 8, left: 8 }}
                                    >
                                    </Checkbox>
                                </List.Item>
                            )}
                        />
                    </Row>
                </div>
                <Row style={{width: '95%', margin: '32px auto'}}>
                    <span className='edit-import-images-text'>Descriptions</span>
                </Row>
                <Row style={{ width: "95%", margin: "32px auto", border: '1px solid #E5E7EB', borderRadius: '8px' }}>
                    <ReactQuill
                        value={editorHtml}
                        onChange={handleEditorChange}
                        placeholder="Type your descriptions here"
                        style={{
                            width: "100%",
                        }}
                    />
                </Row>
            </Form>
        </Layout>
    )
}

export default EditAndImport
