import React, { useState, useEffect } from "react";
import { Form, InputNumber, Table } from "antd";

const { Column } = Table;

const MetaEdit = (Props) => {
  const { form } = Props;
  const [metas, setMetas] = useState([]);
  const [rowSelection, setRowCollection] = useState([]);
  const [count, setCount] = useState(1);

  useEffect(() => {
    setMetas(Props.metas);
    setRowCollection(Props.rowSelection);
  }, [Props]);

  const getTotalPrice = (id, quantity, price) => {
    let q = parseFloat(quantity);
    let total = price * q;
    let index = Props.updatedPrice.findIndex((x) => x.id === id);
    Props.updatedPrice[index].qty = quantity;
    Props.updatedPrice[index].price = total;
    Props.setUpdatedPrice(Props.updatedPrice);
    return total;
  };

  return (
    <Table
      dataSource={metas}
      bordered={true}
      rowKey="id"
      pagination={false}
      rowSelection={rowSelection}
      scroll={{ x: 1000 }}
    >
      <Column
        title="Sku"
        dataIndex="meta"
        key="Meta"
        align={"center"}
        width={"10%"}
        render={(meta) => (
          <div className={"product-info"}>
            {meta &&
              meta.map((item, index) => {
                return `${item.value} ${index !== meta.length - 1 ? "/ " : ""}`;
              })}
          </div>
        )}
      />
      <Column
        title="Meta"
        dataIndex="meta"
        key="Meta"
        align={"center"}
        width={"10%"}
        render={(meta) => (
          <div className={"product-metas"}>
            {meta &&
              meta.map((item, index) => {
                return (
                  <div key={"manual-order-meta-item-" + index}>
                    <span style={{ fontWeight: "bold" }}>{item.title}</span>:
                    &nbsp; {item.label || item.value}
                  </div>
                );
              })}
          </div>
        )}
      />
      <Column
        title="Buy quantity"
        dataIndex="qty"
        key="qty"
        align={"center"}
        render={(qty) => <span>{qty} Pieces</span>}
      />
      <Column
        title="Refund quantity"
        dataIndex="id"
        key="qty"
        align={"center"}
        render={(id, record) => (
          <Form.Item name={`metas_${id}_qty`}>
            <InputNumber
              min={1}
              max={record.qty}
              onChange={() => {
                setCount(count + 1);
              }}
            />
          </Form.Item>
        )}
      />
      <Column
        title="Unit Price"
        dataIndex="productPrice"
        key="productPrice"
        align={"center"}
        render={(productPrice) => <span>{productPrice.toFixed(2)}</span>}
      />
      <Column
        title="Refund Total Price"
        dataIndex="id"
        key="refund_total_price"
        align={"center"}
        render={(id, row) => (
          <span>
            {getTotalPrice(
              id,
              form.getFieldValue(`metas_${id}_qty`),
              row.price * row.fx
            ).toFixed(2)}
          </span>
        )}
      />
    </Table>
  );
};

export default MetaEdit;
