import { useEffect, useState } from 'react'
import moment from 'moment'
import Tag from 'antd/es/tag';
import { message } from 'antd';

export const formateDate = (date) => {
  return date ? moment(date).format('DD/MM/YYYY') : null
}

export const formateDateTime = (date) => {
  return date ? moment(date).format('DD/MM/YYYY hh:mm A') : null
}

export const calculateShippingPrice = (rate, commission) => {
  if (commission) {
    rate = rate + (rate * (commission / 100))
  }
  return rate
}

export const getStatus = (name, objectType) => {
  for (let [, value] of Object.entries(objectType)) {
    if (value.name === name) {
      return <Tag color={value.color}>{value.label}</Tag>
    }
  }
}

export const formatDate = (dateData) => {
  let monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ]

  let today = new window.Date(dateData)
  let date = today.getDate().toString()
  if (date.length === 1) {
    date = "0" + date
  }
  return date + ' ' + monthNames[today.getMonth()] + ' , ' + today.getFullYear()
}

export const formatTime = (time) => {
  return new window.Date(time).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
}

export const formatDateTime = (dateTime) => {
  return formatDate(dateTime) + ' - ' + formatTime(dateTime)
}

export const updateSize = (url, size) => {
  return url.replace(/_\d+x\d+/, `_${size}x${size}`)
}

export const getQueryParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlSearchParams.entries());
}

export function insertUrlParams(pairs = []) {
  if (window && window.history.pushState) {
    let urlSearchParams = new URLSearchParams(window.location.search);
    pairs.forEach(item => {
      urlSearchParams.set(item.key, item.value);
    })
    let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + urlSearchParams.toString();
    window.history.pushState({ path: newurl }, '', newurl);
  }
}

export function clearQuerySearchParams() {
  if (window && window.history.pushState) {
    let oldUrlSearchParams = getQueryParams();
    let urlSearchParams = new URLSearchParams();
    if (oldUrlSearchParams["page"]) {
      urlSearchParams.set("page", oldUrlSearchParams["page"]);
    }
    let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + urlSearchParams.toString();
    window.history.pushState({ path: newurl }, '', newurl);
  }
}

export function clearQuerySearchParamsByKeys(keys = []) {
  if (window && window.history.pushState) {
    let oldUrlSearchParams = getQueryParams();
    let urlSearchParams = new URLSearchParams();
    for (let [key, value] of Object.entries(oldUrlSearchParams)) {
      if (keys.findIndex(item => item === key) === -1) {
        urlSearchParams.set(key, value);
      }
    }
    let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + urlSearchParams.toString();
    window.history.pushState({ path: newurl }, '', newurl);
  }
}

// Hook
export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
        height: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export const getValueFromConfig = (configArray, key) => {
  if (!configArray) {
    return null;
  }
  let filtered = configArray.filter(item => item.key === key)
  return filtered.length > 0 ? filtered[0].value : null
}

export const toastErrors = (erorrs) => {
  if (erorrs?.errors) {
    let errors = erorrs.errors;
    Object.keys(errors)?.forEach(key => {
      message.error(errors?.[key]?.[0])
    })
  } else {
    message.error(erorrs?.message)
  }
}
