import { Menu, Modal } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import {
  Dashboard,

  AddressNook,
  ActionNeeded,
  WebSite,
  Products,
  Order,
  Invoice,
  Download,
  Wallet,
  SignOut,
  Tutorial,
  Profile,
} from "assets/SvgIcons";
import React from "react";
import { nanoid } from "nanoid";
import { logout } from "../../utils/auth-client";
import { LOCAL_STORAGE_KEY, LOCAL_STORAGE_USER } from "../../consts/storage";
import DeliveryTracker from "../../pages/DeliveryTracker";
import {CarOutlined} from "@ant-design/icons";

function Sidenav({ color }) {

  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const handleLogout = () => {
    logout()
      .then((res) => {
        window.localStorage.removeItem(LOCAL_STORAGE_KEY);
        window.localStorage.removeItem(LOCAL_STORAGE_USER);
        window.location.reload();
      })
      .catch((err) => {
        Modal.error({
          title: "Error Occurred",
          content: "Logout failed",
        });
      });
  };


  return (
    <>
      <Menu theme="light" mode="inline" style={{ background: "#FFF", borderRadius: "8px" }}>
        <Menu.Item key="1">
          <NavLink to="/dashboard">
            <span
              className="icon"
              style={{
                background: page === "dashboard" ? color : "",
              }}
            >
              <Dashboard />
            </span>
            <span className="label">Dashboard</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/websites">
            <span
              className="icon"
              style={{
                background: page === "websites" ? color : "",
              }}
            >
              <WebSite color={color} />
            </span>
            <span className="label">Websites</span>
          </NavLink>
        </Menu.Item>
        <Menu.SubMenu
          key='subMenu'
          className="label custom-submenu"
          icon={<Products color={color} />}
          title="Products"
          style={{ marginLeft: "-3px" }}
        >
          <Menu.Item key="3">
            <NavLink to="/products" >
              <span className="label">Product List</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4">
            <NavLink to="/saved-products" >
              <span className="label">Saved Products</span>
            </NavLink>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          key="SubMenu"
          className="label custom-submenu"
          icon={<Order color={color} />}
          title="Orders"
          style={{ marginLeft: "-6px", marginTop: '12px' }}
        >
          <Menu.Item key={nanoid(5)}>
            <NavLink to="/shop-orders">
              <span className="label">Shop Orders</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key={nanoid(5)}>
            <NavLink to="/order">
              <span className="label">My Orders</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key={nanoid(5)}>
            <NavLink to="/place-order">
              <span className="label">Manual Order</span>
            </NavLink>
          </Menu.Item>
        </Menu.SubMenu>
        {/* <Menu.Item key={nanoid(5)}>
          <Menu.Item disabled>
            <NavLink to="/">
              <span className="label">Cost Calculator</span>
            </NavLink>
          </Menu.Item>
        </Menu.SubMenu> */}
        <Menu.Item key="7">
          <NavLink to="/action-needed">
            <span
              className="icon"
              style={{
                background: page === "action-needed" ? color : "",
              }}
            >
              <ActionNeeded color={color} />
            </span>
            <span className="label">Action Needed</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="8">
          <NavLink to="/invoice">
            <span
              className="icon"
              style={{
                background: page === "invoice" ? color : "",
              }}
            >
              <Invoice color={color} />
            </span>
            <span className="label">Invoice</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="9">
          <NavLink to="/wallet">
            <span
              className="icon"
              style={{
                background: page === "wallet" ? color : "",
              }}
            >
              <Wallet color={color} />
            </span>
            <span className="label">Wallet</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="10">
          <NavLink to="/address">
            <span
              className="icon"
              style={{
                background: page === "address" ? color : "",
              }}
            >
              <AddressNook color={color} />
            </span>
            <span className="label">Address</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="100">
          <NavLink to="/delivery-trackeer">
            <span
              className="icon"
              style={{
                background: page === "delivery-trackeer" ? color : "",
              }}
            >
              <CarOutlined />
            </span>
            <span className="label">Delivery Tracker</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="11">
          <NavLink to="/tutorial">
            <span
              className="icon"
              style={{
                background: page === "tutorial" ? color : "",
              }}
            >
              <Tutorial color={color} />
            </span>
            <span className="label">Tutorial</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="12">
          <NavLink to="/download">
            <span
              className="icon"
              style={{
                background: page === "download" ? color : "",
              }}
            >
              <Download color={color} />
            </span>
            <span className="label">Download</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="13">
          <NavLink to="/profile">
            <span
              className="icon"
              style={{
                background: page === "profile" ? color : "",
              }}
            >
              <Profile color={color} />
            </span>
            <span className="label">Profile</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="14">
          <NavLink to="/auth/login">
            <span
              className="icon"
              style={{
                background: page === "signout" ? color : "",
              }}
            >
              <SignOut color={color} />
            </span>
            <a href="auth/login" onClick={handleLogout}>
              <span className="label" style={{ marginLeft: "-18px" }}>Sign Out  </span>
            </a>
          </NavLink>
        </Menu.Item>
      </Menu>
    </>
  );
}

export default Sidenav;
