import JSZip from 'jszip';

const prepareDataForDownload = async (title, description, link, weight, vendor, ratings, sales, store_name, imageGallery) => {
    const csvHeader = `"Title","Description","Link","Weight","Vendor","Ratings","Sales","Store Name"`;
    const csvRows = `"${title}","${description}","${link}","${weight}","${vendor}","${ratings}","${sales}","${store_name}"`;
    const csvData = `${csvHeader}\n${csvRows}`;
    const imageFolder = 'Image Gallery';
    const imageFiles = await Promise.all(
        imageGallery.map(async (image, index) => {
            const response = await fetch(image.url);
            const blob = await response.blob();
            return { name: `image${index + 1}.jpg`, content: blob };
        })
    );
    return { csvData, imageFolder, imageFiles };
};

export const ExportToZip = async (product) => {
    const title = product?.title;
    const description = product?.description;
    const specification = product?.specifications;
    const link = product?.link;
    const weight = product?.meta?.weight;
    const vendor = product?.meta?.vendor;
    const ratings = product?.ratings_average;
    const sales = product?.sales;
    const store_name = product?.store?.name;
    const imageGallery = product?.gallery?.map((item) => {
        return {
            url: item.url
        }
    })
    try {
        const { csvData, imageFolder, imageFiles } = await prepareDataForDownload(title, description, link, weight, vendor, ratings, sales, store_name, imageGallery);
        const zip = new JSZip();
        zip.file('product-details.csv', csvData);
        const imgFolder = zip.folder(imageFolder);
        imageFiles.forEach((file) => {
            imgFolder.file(file.name, file.content, { base64: true });
        });
        zip.generateAsync({ type: 'blob' }).then((content) => {
            const a = document.createElement('a');
            const url = URL.createObjectURL(content);
            a.href = url;
            a.download = 'product-details.zip';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        });
    } catch (error) {
        console.error('Error preparing data for download:', error);
    }
};

// const generateSpecificationTable = (specifications) => {
//     const rows = specifications.map(spec => `${spec.label.name}: ${spec.value.name}`);
//     return rows.join('\n');
// };