import React from 'react'
import {Col, Layout, Row} from "antd";
import {HeartFilled} from "@ant-design/icons";

const {Footer, Content} = Layout;
const AuthLayout = (props) => {
    const {children} = props;
    const d = new Date();
    let year = d.getFullYear();
    return (
        <Layout className="layout-default layout-signin">
            <Content className="signin">
                <Row gutter={[24, 0]} justify="space-around">
                    <Col
                        xs={{span: 20, offset: 1}}
                        md={{span: 20, offset: 1}}
                        lg={{span: 8, offset: 2}}
                        xl={{span: 8, offset: 2}}
                        xxl={{span: 8, offset: 2}}
                    >
                        {children}
                    </Col>
                </Row>
            </Content>
            <Footer>
                <div className="copyright">
                    © {year} , made with
                    {<HeartFilled/>} by
                    <a href="https://moveon.com.bd" rel="noreferrer" className="font-weight-bold" target="_blank">
                        MoveOn Technologies
                    </a>
                </div>
            </Footer>
        </Layout>

    )
}

export default AuthLayout