import { useWalletRecharge } from 'context/wallet-recharge-context'
import React, { useEffect } from 'react'
import { Col, Form, InputNumber, message, Modal, Row } from 'antd'

const WalletRechargeModal = Props => {
  const { show, onHide } = Props
  const [form] = Form.useForm()
  const {
    setPayableAmount,
  } = useWalletRecharge()

  useEffect(() => {
    setPayableAmount(1)
  }, [setPayableAmount])

  const handleSubmit = (values) => {
    if (values.amount <= 0) {
      message.warning('Payable amount must be greater than 0')
      return
    }
    setPayableAmount(values.amount)
    Props.handlePageAction('wallet-recharge')
    onHide()
  }

  return (
    <Modal
      title="Choose amount"
      style={{ top: 80 }}
      visible={show}
      onOk={() => form.submit()}
      okText={'Recharge wallet'}
      onCancel={onHide}
    >
      <Row>
        <Col
          span={24}
          style={{
            marginTop: 15
          }}
        >
          <Form
            form={form}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ amount: 1 }}
            onFinish={handleSubmit}
            autoComplete="off"
          >
            <Form.Item
              label="Amount"
              name="amount"
              rules={[{ required: true, message: 'Please input amount!' }]}
            >
              <InputNumber
                addonAfter="BDT"
                min={1}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  )
}

export default WalletRechargeModal