import React, { useEffect, useState } from 'react'
import { getBuyInvoiceDetail } from 'utils/buy-order-client'
import { BuyInvoiceDetail } from 'components'
import { toastErrors } from 'utils/helpers'

const BuyInvoice = Props => {
  const { invoice } = Props
  const [response, setResponse] = useState({ addresses: null, products: [] })
  const [isLoading, setIsLoading] = useState(false)
  const [isServiceGetCalled, setIsServiceGetCalled] = useState(false)

  const fetchInvoiceDetail = React.useCallback(
    (invoice) => {
      setIsLoading(true)
      getBuyInvoiceDetail(invoice.id).then(res => {
        let data = res?.data?.data
        if (data) {
          setResponse({
            addresses: data.order.addresses,
            products: data.order.buyProducts
          })
        }
      })
        .catch(({response}) => {
          if (response?.data) {
            toastErrors(response.data)
          }
        }).finally(() => setIsLoading(false))
    },[])

  useEffect(() => {
    if (!isServiceGetCalled) {
      fetchInvoiceDetail(invoice)
      setIsServiceGetCalled(true)
    }
  }, [invoice, fetchInvoiceDetail, isServiceGetCalled])

  return (
    <BuyInvoiceDetail
      invoice={invoice}
      addresses={response.addresses}
      buyProducts={response.products}
      isLoading={isLoading}
    />
  )
}

export default BuyInvoice
