import React, { useState } from 'react'
import { handleWalletRecharge } from 'utils/payment-client'
import { toastErrors } from 'utils/helpers';

const WalletRechargeContext = React.createContext();

const WalletRechargeProvider = (props) => {
  const [payableAmount, setPayableAmount] = useState(0)
  const [isRecharging, setIsRecharging] = useState(false)

  const rechargeWallet = (data, callback) => {
    setIsRecharging(true)
    handleWalletRecharge(data).then(response => {
      callback(response)
    }).catch(({response}) => {
      if (response?.data) {
        toastErrors(response.data)
      }
    }).finally(x => {
      setIsRecharging(false)
    })
  }

  return <WalletRechargeContext.Provider
    value={{
      rechargeWallet,
      payableAmount,
      setPayableAmount,
      isRecharging
    }}
    {...props}
  />
}

const useWalletRecharge = () => {
  const context = React.useContext(WalletRechargeContext)
  if (context === undefined) {
    throw new Error(`useWalletRecharge must be used within a WalletRechargeProvider`)
  }
  return context
}

export { WalletRechargeProvider, useWalletRecharge }
