import React from "react"
import {Alert} from "antd";
import {formatDate, formatTime, useWindowSize} from "utils/helpers";
import './ProgressTrack.css'
import {useSettings} from "context/settings-context";

const ProgressTrack = Props => {
    const {assetURL} = useSettings();
    const {logs} = Props;
    const winddowSize = useWindowSize();
    const steps = logs && logs[0]
    const buyPurchased = logs && logs.find(steps => steps.status_to === "buy-purchased")
    const arrivedAtWarehouse = logs && logs.find(steps => steps.status_to === "arrived-at-warehouse")
    const preparingForTransport = logs && logs.find(steps => steps.status_to === "preparing-for-transport")
    const receivedByMoveOn = logs && logs.find(steps => steps.status_to === "received-by-moveon")
    return (
        <div className="progress-track">
            <ul className={`buy-agent-track-steps`}>
                <li className={steps && steps.status_to === 'buy-pending' ? `item active` : `item`}>
                    <span>Pending</span>
                    <div className={steps && steps.status_to === 'buy-pending' ? `step checked` : `step`}>
                        <img className={`img-fluid uncheck-ico`}
                             src={assetURL + "/assets/images/track_progress/Pending.png"} alt="Pending"/>
                        <img className={`img-fluid checked-ico`}
                             src={assetURL + "/assets/images/track_progress/Pending.png"} alt="Pending Active"/>
                    </div>
                </li>
                <li className={buyPurchased && buyPurchased.status_to === "buy-purchased" ? "item active" : "item"}>
                    <span>Purchased</span>
                    <div
                        className={buyPurchased && buyPurchased.status_to === "buy-purchased" ? "step checked" : "step"}>
                        <img className={`img-fluid uncheck-ico`}
                             src={assetURL + "/assets/images/track_progress/Icon_Purchased.png"} alt="Purchased"/>
                        <img className={`img-fluid checked-ico`}
                             src={assetURL + "/assets/images/track_progress/Icon_Purchased.png"}
                             alt="Purchased Active"/>
                    </div>
                </li>
                <li className={arrivedAtWarehouse && arrivedAtWarehouse.status_to === 'arrived-at-warehouse' ? "item active" : "item"}>
                    <span>Received By Agent</span>
                    <div
                        className={arrivedAtWarehouse && arrivedAtWarehouse.status_to === 'arrived-at-warehouse' ? "step checked" : "step"}>
                        <img className={`img-fluid uncheck-ico`}
                             src={assetURL + "/assets/images/track_progress/ProductReceive.png"} alt="Pending"/>
                        <img className={`img-fluid checked-ico`}
                             src={assetURL + "/assets/images/track_progress/ProductReceiveWhite.png"}
                             alt="Pending Active"/>
                    </div>
                </li>
                <li className={preparingForTransport && preparingForTransport.status_to === 'preparing-for-transport' ? "item active" : "item"}>
                    <span>Preparing for Shipment</span>
                    <div
                        className={preparingForTransport && preparingForTransport.status_to === 'preparing-for-transport' ? "step checked" : "step"}>
                        <img className={`img-fluid uncheck-ico`}
                             src={assetURL + "/assets/images/track_progress/InTransit.png"} alt="Transit"/>
                        <img className={`img-fluid checked-ico`}
                             src={assetURL + "/assets/images/track_progress/InTransitWhite.png"} alt="Transit Active"/>
                    </div>
                </li>
                <li className={receivedByMoveOn && receivedByMoveOn.status_to === 'received-by-moveon' ? `item active` : "item"}>
                    <span>Received At MoveOn</span>
                    <div
                        className={receivedByMoveOn && receivedByMoveOn.status_to === 'received-by-moveon' ? "step checked" : "step"}>
                        <img className={`img-fluid uncheck-ico`}
                             src={assetURL + "/assets/images/track_progress/ProductReceive.png"} alt="Received"/>
                        <img className={`img-fluid checked-ico`}
                             src={assetURL + "/assets/images/track_progress/ProductReceiveWhite.png"}
                             alt="Received Active"/>
                    </div>
                </li>
                <li className={steps && steps.status_to === 'delivered-to-customer' ? "item active" : "item"}>
                    <span>Delivered</span>
                    <div className={steps && steps.status_to === 'delivered-to-customer' ? "step checked" : "step"}>
                        <img className={`img-fluid uncheck-ico`}
                             src={assetURL + "/assets/images/track_progress/Delivery.png"} alt="Delivery"/>
                        <img className={`img-fluid checked-ico`}
                             src={assetURL + "/assets/images/track_progress/DeliveryWhite.png"} alt="Delivery Active"/>
                    </div>
                </li>
            </ul>
            {
                logs && logs.length !== 0 ?
                    <Alert message={logs[0].instruction} type="warning"/>
                    :
                    null
            }
            <div className={`track-summary mt-3`}>
                {
                    winddowSize.width > 991 ?
                        <div className="table-responsive">
                            <table className={`table table`}>
                                <tbody>
                                {
                                    logs && logs.map((logData, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{formatDate(logData?.created_at)}</td>
                                                <td>{formatTime(logData?.created_at)}</td>
                                                <td>
                                                    <div className={`track-message`}>
                                                        {logData.instruction}
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        :
                        <div className="tract-summery-content">
                            {
                                logs && logs.map((logData, index) => {
                                    return (
                                        <div className={`tract-summery-block mb-2`} key={index}>
                                            <div className={`track-date-time`}>
                                                <span
                                                    className="font-weight-bold">{formatDate(logData?.created_at)} at {formatTime(logData?.created_at)}</span>
                                            </div>
                                            <div className={`track-message ml-2`}>
                                                {logData.instruction}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                }
            </div>
        </div>
    )
}
export default ProgressTrack
