import React, { useState } from 'react'
import {  Table, Spin, message,Image } from "antd";
import EditProductModal from './EditProductModal';

function AddedProductsTable({ placeProduct, setPlaceProduct, setSelectedProducts}) {
  const [messageApi, contextHolder] = message.useMessage();
  const [selectionType, setSelectionType] = useState('checkbox');
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [editProduct, setEditProduct] = useState(null)

  const handleProductDelete = (id) => {
    setIsLoading(true)
    setTimeout(() => {
      const result = placeProduct.filter((product, index) => {
        if (index !== id) {
          return product
        }
      })
      setPlaceProduct(result)
      setIsLoading(false)
      messageApi.open({
        type: 'success',
        content: 'Product deleted successfully!',
        style: { marginTop: '50px' }
      });
    }, 1000)
  }

    const columns = [
        {
            title: "Product Title",
            dataIndex: "title",
            width: 550,
            render: (_, record) => (
                <div style={{ display: "flex", gap: "13px", alignItems: "center" }} >
                    <Image
                        style={{ width: "83px", height: "83px", borderRadius: "8px" }}
                        src={record.image}
                        alt="product"
                    />
                    <p className="order-place-table-text" style={{ whiteSpace: 'pre-line' }} >
                        <a href={record?.link} target="_blank" style={{color: 'black', textDecoration: 'none'}}>{record.title.replace(/(.{50})/g, '$1\n')}</a>
                    </p>
                </div>
            )
        },
        {
            title: "Variations",
            dataIndex: "variations",
            width: 350,
            render: (_, record) => (
                <div style={{ maxHeight: '120px', overflowY: 'auto' }} >
                    {
                        record?.variations.length > 0 ? record?.variations?.map((variation, index) => (
                        <div key={index} style={{ gap: '8px', display: 'flex' }}>
                            {
                                variation?.meta?.map((metaValues, index) => (
                                    <span key={index} className="order-place-table-text">
                                        {metaValues.value ? metaValues.value : "N/A"}
                                    </span>
                                ))
                            }
                        </div>
                    )) : <span className="order-place-table-text">
                        N/A
                    </span>
                    }
                </div>
            )
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            width: 150,
            render: (_, record) => (
                <span className="order-place-table-text">{record?.variations.reduce((accumulator, variation) => {
                    return accumulator + variation.qty;
                }, 0)}
                </span>
            )
        },
        {
            title: "Product Price",
            dataIndex: "price",
            width: 250,
            render: (_, record) => (
                <span className="order-place-table-text" >
                    {record?.variations.reduce((accumulator, variation) => {
                        return accumulator + Number(variation.price);
                    }, 0).toFixed(2)}
                </span>
            )
        },
        {
            title: "Action",
            dataIndex: "action",
            width: 250,
            render: (_, record, index) => (
                <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
                    <span className="saved-product-table-action-btn1" onClick={() => {
                        setOpenModal(true);
                        setEditProduct(record);
                    }}>Edit</span>
                    <span className="saved-product-table-action-btn3" onClick={() => handleProductDelete(index)}>Delete</span>
                </div>
            ),
        },
    ]

    const data = placeProduct?.map((product, index) => {
        return {
            key: index,
            title: product?.title,
            aw_cat_id: product?.aw_cat_id,
            image: product?.image,
            link: product?.link,
            variations: product?.metaItems,
        }
    })

    const rowSelection = {
        onChange: (_, selectedRows) => {
            const selectedProductKeys = selectedRows.map(row => row.key);
            const getSelectedProduct = placeProduct.filter((product,index) => selectedProductKeys.includes(index));
            setSelectedProducts(getSelectedProduct)
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            name: record.name,
        }),
    };

    return (
        <div style={{ position: 'relative', width: "100%" }}>
            {contextHolder}
            <Table
                rowSelection={{
                    type: selectionType,
                    ...rowSelection,
                }}
                scroll={{ x: 1200 }}
                columns={columns}
                dataSource={data}
                style={{ width: "100%", opacity: isLoading ? '0.5' : "1" }}
                // pagination={false}
            />
            {isLoading && <Spin style={{ position: 'absolute', left: '50%', top: '50%' }} />}
            <EditProductModal openModal={openModal} setOpenModal={setOpenModal} editProduct={editProduct} setEditProduct={setEditProduct} placeProduct={placeProduct} setPlaceProduct={setPlaceProduct} />
        </div>

    )
}

export default AddedProductsTable
