import {
  Button, Card,
  Col, ConfigProvider, Progress,
  Row, Statistic, Tooltip, Spin,
} from "antd";
import React, { useState } from "react";
import Search from "antd/es/input/Search";
import {deliveryTracker} from "../../utils/generic-client";
import {LoadingOutlined} from "@ant-design/icons";

function DeliveryTracker() {
  const [data, setData] = useState({
    totalOrdered: 0,
    totalDelivered: 0,
    totalCancelled: 0,
    successRate: 100,
    activityResult: 'Good',
  });
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchDeliveryStats = async (phone) => {
    setLoading(true);
    try {
      const response = await deliveryTracker({ phone: phone });
      const responseData = response.data.data;

      const totalOrdered = responseData.total_parcels;
      const totalDelivered = responseData.total_delivered;
      const totalCancelled = responseData.total_parcels - responseData.total_delivered;
      const successRate = totalOrdered === 0 ? 100 : ((totalDelivered / totalOrdered) * 100).toFixed(0);
      const activityResult = responseData.activity_result;

      setData({
        totalOrdered,
        totalDelivered,
        totalCancelled,
        successRate,
        activityResult,
      });
    } catch (error) {
      console.error('Error fetching delivery stats:', error);
    }
    setLoading(false);
  };

  const handleSearch = (value) => {
    setPhoneNumber(value);
    fetchDeliveryStats(value);
  };

  return (
    <div style={{ padding: '50px' }}>
      <h1>Delivery Tracker</h1>
      <Search
        placeholder="Enter phone number"
        enterButton="Check"
        size="large"
        style={{ marginBottom: '20px', maxWidth: '400px' }}
        onSearch={handleSearch}
        disabled={loading}
      />
      {loading ? (
        <Spin indicator={<LoadingOutlined spin />} size="large" style={{ marginBottom: '20px' }} />
      ) : (
        <>
          <div style={{ marginTop: '10px', color: '#6B7386', fontSize: 16, marginBottom: '12px' }}>
            Activity Result: {data.activityResult}
          </div>
          <Row gutter={16}>
            <Col span={6}>
              <Card>
                <Statistic title="Total Parcel Ordered" value={data.totalOrdered} valueStyle={{ fontSize: '60px' }} />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic title="Total Delivered" value={data.totalDelivered} valueStyle={{ color: '#3f8600', fontSize: '60px' }} />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic title="Total Cancelled" value={data.totalCancelled} valueStyle={{ color: '#cf1322', fontSize: '60px' }} />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <div style={{ textAlign: 'center' }}>
                  <ConfigProvider
                    theme={{
                      components: {
                        Progress: {
                          remainingColor: '#ff0000',
                        },
                      },
                    }}
                  >
                    <Progress strokeWidth={15} type="circle" percent={data.successRate} success={{ percent: data.successRate }} />
                  </ConfigProvider>
                </div>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default DeliveryTracker;
