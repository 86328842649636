import { Row, Col, Card, Table, message, Button, Tag, Tooltip } from "antd";

import { CopyOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { DateTime } from "components";
import React, { useState, useCallback, useEffect } from "react";
import { getWebsites } from "utils/oauth-client";
import { CopyToClipboard } from "react-copy-to-clipboard";
import WebsiteAdd from "./WebsiteAdd";
function Website() {
  const [response, setResponse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showOAuthCreate, setShowOAuthCreate] = useState(false);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getWebsites()
      .then((res) => {
        setResponse(res.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 403) {
            message.error("The action is not authorized");
          } else {
            message.error(
              err.response.data.message
                ? err.response.data.message
                : "Unknown error"
            );
          }
        } else {
          message.error("Unknown error");
        }
      })
      .finally(() => setIsLoading(false));
  }, []);

  // const handleDelete = id => {
  //     deleteWebsite(id).then(res => {
  //             message.success("Access website deleted successfully");
  //             fetchData();
  //         }).catch(err => {
  //             if(err.response){
  //                 if(err.response.status === 403){
  //                     message.error("The action is not authorized");
  //                 }else{
  //                     message.error(err.response.data.message ? err.response.data.message : "Unknown error");
  //                 }
  //             }else{
  //                 message.error("Unknown error");
  //             }
  //     })
  // }

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    {
      title: "SL",
      dataIndex: "sl",
      key: "sl",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Client ID",
      dataIndex: "id",
      key: "client_id",
      align: "center",
      render: (id) => (
        <div>
          <span
            className="font-weight-600"
            style={{
              marginRight: 5,
            }}
          >
            {id}
          </span>
          <CopyToClipboard text={id}>
            <Tooltip title={"Copy"}>
              <CopyOutlined />
            </Tooltip>
          </CopyToClipboard>
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
      key: "name",
    },
    {
      title: "Secret",
      dataIndex: "secret",
      key: "secret",
      align: "center",
      render: (secret) => (
        <CopyToClipboard text={secret}>
          <Tooltip>
            <Button type="primary" icon={<CopyOutlined />}>
              Copy Secret
            </Button>
          </Tooltip>
        </CopyToClipboard>
      ),
    },
    {
      title: "Website URL",
      dataIndex: "redirect",
      key: "redirect",
      width: "20%",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "revoked",
      key: "revoked",
      align: "center",
      render: (revoked) =>
        !revoked ? (
          <Tag color="#f50">RUNNING</Tag>
        ) : (
          <Tag color="#2db7f5">REVOKED</Tag>
        ),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      render: (created_at) => <DateTime date={created_at} />,
    },
    // {
    //     title: 'Actions',
    //     dataIndex: 'id',
    //     align: 'center',
    //     render: (id, record) => {
    //         return (
    //             <Popconfirm
    //                 title="Are you sure to delete this access website?"
    //                 onConfirm={() => handleDelete(id)}
    //                 okText="Yes"
    //                 cancelText="No"
    //             >
    //                 <Button icon={<DeleteOutlined />} danger>
    //                     Delete
    //                 </Button>
    //             </Popconfirm>
    //         )
    //     },
    // },
  ];
  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              extra={
                <>
                  <Button
                    type="primary"
                    icon={<PlusCircleOutlined />}
                    onClick={() => setShowOAuthCreate(true)}
                  >
                    Add new Website
                  </Button>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={response}
                  pagination={false}
                  loading={isLoading}
                  key={"id"}
                />
              </div>
            </Card>
          </Col>
          <WebsiteAdd
            show={showOAuthCreate}
            onHide={() => setShowOAuthCreate(false)}
            handleWebsiteAdd={() => {
              fetchData();
              setShowOAuthCreate(false);
            }}
          />
        </Row>
      </div>
    </>
  );
}

export default Website;
