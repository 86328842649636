import React, {useState} from "react";
import {Typography} from "antd";
import {NavLink} from "react-router-dom";
import FirstStep from "./components/FirstStep";
import SecondStep from "./components/SecondStep";
import ThirdStep from "./components/ThirdStep";
import logo from "../../../assets/logo.png";

const {Title} = Typography;

const Register = (Props) => {
    const [step, setStep] = useState(1);
    const [data, setData] = useState(null);
    const [isPhone, setIsPhone] = useState(false);

    const updateStep = (newStep) => {
        setStep(newStep);
    };

    const updateData = (newData) => {
        setData(newData);
    };

    const updateIsPhone = (value) => {
        setIsPhone(value);
    };
    return (
        <>
            <div className="login-card-width">
                <NavLink to={"/"}>
                    <img className="brand-logo" src={logo} alt=""/>
                </NavLink>
            <Title className="mb-30" level={2}>Register</Title>
            {
                step === 1 &&
                (
                    <FirstStep
                        step={step}
                        updateStep={updateStep}
                        updateData={updateData}
                        updateIsPhone={updateIsPhone}
                    />
                )
            }

            {
                step === 2 &&
                (
                    <SecondStep
                        step={step}
                        data={data}
                        updateStep={updateStep}
                        updateData={updateData}
                        isPhone={isPhone}
                    />
                )
            }
            {
                step === 3 &&
                (
                    <ThirdStep
                        step={step}
                        data={data}
                        updateStep={updateStep}
                        updateData={updateData}
                        isPhone={isPhone}
                    />
                )
            }
            </div>
        </>
    );
}

export default Register