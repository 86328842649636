import React from 'react'
import { Col, Row, Spin } from 'antd'
import { PageHeader } from '@ant-design/pro-layout';
import './NagadPayment.css'
import { useSettings } from 'context/settings-context'

const NagadPayment = Props => {
  const { assetURL } = useSettings()
  const { isPaying, gateway, getPayable, payByGateway } = Props

  return (
    <PageHeader
      className="site-page-header"
    >
      <Row>
        <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
          <span className="font-weight-700">Payable (approximate): </span>
          {getPayable(gateway)}
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
          <Spin tip="paying..." spinning={isPaying}>
            <button onClick={payByGateway} className={`nagad-button`}>
              <img
                src={assetURL + '/assets/images/payments/nagad.png'}
                alt="Card Payment"
                width={120}
                height={32}
              />
            </button>
          </Spin>
        </Col>
      </Row>
    </PageHeader>
  )
}

export default NagadPayment
