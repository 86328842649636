import React, { useEffect } from 'react'
import { getValueFromConfig } from 'utils/helpers'
import { Button, Card, Col, DatePicker, Descriptions, Form, Input, Row } from 'antd'
import { PageHeader } from '@ant-design/pro-layout';

const RocketMerchant = Props => {
  const { gateway, payByGateway, isPaying, getPayable } = Props;
  const [form] = Form.useForm();
  
  useEffect(() => {
    if (gateway) {
      form.setFieldsValue({ bank: gateway.name })
    }
  }, [gateway, form])

  return (
    <PageHeader
      className="site-page-header"
    >
      {
        gateway ?
          <Row>
            <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
              <span className="font-weight-700">Payable (approximate): </span>
              {getPayable(gateway)}
            </Col>
          </Row>
          :
          <></>
      }
      <Row style={{ marginTop: 20 }}>
        <Col sm={24} xs={24} md={24} lg={12} xl={12} xxl={12}>
          <Descriptions title={<span className="font-weight-700">Account Information</span>} column={1}>
            <Descriptions.Item label={<span
              className="font-weight-600">Gateway Name</span>}>{gateway.name}</Descriptions.Item>
            <Descriptions.Item label={<span
              className="font-weight-600">Payee</span>}>{getValueFromConfig(gateway.config, 'payee')}</Descriptions.Item>
            <Descriptions.Item label={<span
              className="font-weight-600">Account Number</span>}>{getValueFromConfig(gateway.config, 'account_number')}</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card>
            <Row>
              <Col offset={4} span={16}>
                <Form
                  name="bank-deposit-form"
                  onFinish={payByGateway}
                  form={form}
                  labelCol={{ span: 6 }}
                  labelAlign={'left'}
                >
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="bank"
                        label="Bank name"
                        hasFeedback
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="reference"
                        label="Referece"
                        hasFeedback
                        rules={[{ required: true, message: 'Please enter referece number' }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="deposit_date"
                        label="Deposit Date"
                        hasFeedback
                        rules={[{ required: true, message: 'Please enter deposit date' }]}
                      >
                        <DatePicker
                          style={{ width: '100%' }}
                          onChange={(date, date_string) => form.setFieldsValue(date_string)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item wrapperCol={{ span: 18, offset: 6 }}>
                    <Button type="primary" htmlType="submit" loading={isPaying}>
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </PageHeader>
  )
}

export default RocketMerchant
