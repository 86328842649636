import React, { createContext, useCallback, useState } from 'react'
import { getBuyOrders } from "utils/buy-order-client"
import { toastErrors } from 'utils/helpers';

const BuyForwardingParcelContext = createContext()

const BuyForwardingParcelProvider = Props => {
  const [bdToYouOrders, setBdToYouOrders] = useState({
    data: [],
    meta: null,
  })

  const [bdToYouLoading, setBdToYouLoading] = useState(false)

  const [myOrders, setMyOrders] = useState({
    data: [],
    meta: null,
  })

  const [myOrdersLoading, setMyOrdersLoading] = useState(false)

  const fetchBdToYouOrders = useCallback(
    (page, filters = {}) => {
      setBdToYouLoading(true)
      getBuyOrders(page, { ...filters, ...{ filters: ["bd_to_you"] } }).then((response) => {
        setBdToYouOrders({
          data: response.data.data,
          meta: response.data.meta,
        })
      }).catch(({ response }) => {
        if (response?.data) {
          toastErrors(response.data)
        }
      }).finally(() => setBdToYouLoading(false))
    },
    [setBdToYouOrders, setBdToYouLoading],
  )

  const fetchMyOrders = useCallback(
    (page, filters = {}, per_page) => {
      setMyOrdersLoading(true)
      getBuyOrders(page, { ...filters, ...{ filters: ["my_order", "abroad_to_bd"] } }).then((response) => {
        setMyOrders({
          data: response.data.data,
          meta: response.data.meta,
        })
      }).catch(({ response }) => {
        if (response?.data) {
          toastErrors(response.data);
        }
      }).finally(() => setMyOrdersLoading(false))
    },
    [setMyOrders, setMyOrdersLoading],
  )

  return (
    <BuyForwardingParcelContext.Provider
      value={{
        myOrders,
        myOrdersLoading,
        bdToYouOrders,
        bdToYouLoading,
        fetchMyOrders,
        fetchBdToYouOrders
      }}
    >
      {Props.children}
    </BuyForwardingParcelContext.Provider>
  );
}

const useBuyForwardingParcel = () => {
  const context = React.useContext(BuyForwardingParcelContext)
  if (context === undefined) {
    throw new Error(`useBuyForwardingParcel must be used within a BuyForwardingParcelProvider`)
  }
  return context
}

export { BuyForwardingParcelProvider, useBuyForwardingParcel }
