import React, {useEffect, useState} from 'react'
import {getBuyShipProductDetails, takeBuyProductAction} from "utils/buy-order-client"
import {StatusTag} from "components";
import {
  Breadcrumb,
  Button,
  Col,
  Descriptions,
  Image,
  message, Modal,
  
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import Form from "antd/es/form";
import { PageHeader } from '@ant-design/pro-layout';

const {Column} = Table
const {Option} = Select

const ProductAction = Props => {
    const {productId} = Props
    const [form] = Form.useForm();
    const [productInfo, setProductInfo] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [showAction, setShowAction] = useState(false)
    const [activeIssue, setActiveIssue] = useState(null)
    const [issueMetas, setIssueMetas] = useState(null)
    const [actionLoading, setActionLoading] = useState(false)

    useEffect(() => {
        if (productId) {
            setIsLoading(true)
            getBuyShipProductDetails(productId)
                .then(response => {
                    if (response && response.data && response.data.data) {
                        setProductInfo(response.data.data)
                    }
                }).catch(err => {
                if (err.response && err.response.status === 403) {
                    message.error("The action is not authorized")
                } else {
                    if (err && err.response && err.response.data && err.response.data.data && err.response.data.data.message) {
                        message.error(err.response.data.data.message)
                    } else {
                        message.error("Something is wrong")
                    }
                }
            }).finally(() => setIsLoading(false))
        }
    }, [productId])

    useEffect(() => {
        const result = productInfo?.issues ? productInfo.issues.find(item => item.status !== 'resolved') : null
        if (result?.meta?.prices) {
            let newIssueItems = productInfo.itemMetas.filter(el => {
                return result.meta.prices.find(price => price.id === el.id)
            })
                .map(item => {
                    let issuePrice = result.meta.prices.find(price => price.id === item.id)
                    item.change_price = issuePrice.price
                    return item
                })
            setIssueMetas(newIssueItems)
        }
        setActiveIssue(result)
    }, [productInfo])

    const onSubmit = data => {
        setActionLoading(true)
        takeBuyProductAction(productInfo.id, {issue_id: activeIssue.id, action: data.action})
            .then(res => {
                message.success(`Success!`)
                setShowAction(false)
                setActiveIssue(null)
            })
            .catch(err => {
                setShowAction(false)
                if (err.response && err.response.status === 403) {
                    message.error("The action is not authorized")
                } else {
                    if (err && err.response && err.response.data && err.response.data.data && err.response.data.data.message) {
                        message.error(err.response.data.data.message)
                    } else {
                        message.error("Something is wrong")
                    }
                }
            }).finally(() => setActionLoading(false))
    }

    const handleAction = () => {
        setShowAction(true)
    }

    const calculateProductPrice = (price, fxRate, commission) => {
        let fxPriceFxRate = price * fxRate
        let fxPriceCommission = (fxPriceFxRate * commission) / 100
        return isNaN((fxPriceFxRate + fxPriceCommission).toFixed(2)) ? '' : (fxPriceFxRate + fxPriceCommission).toFixed(2)
    }

    let productItem = productInfo?.itemMetas?.reduce(function (acc, cur, i) {
        acc[i] = cur;
        return acc;
    })

    return (
        <PageHeader
            title={`Take actions for # ${productInfo && productInfo.product_number ? productInfo.product_number : ""}`}
            className="site-page-header"
            onBack={() => Props.handlePageAction("myorder")}
            extra={[
                (activeIssue) && (
                    <Button
                        key="1"
                        type="primary"
                        onClick={() => handleAction()}
                    >
                        Take action
                    </Button>
                )
            ]}
            breadcrumb={
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Button
                            key="1"
                            type="link"
                            onClick={() => Props.handlePageAction("myorder")}
                        >
                            Order List
                        </Button>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Product {productInfo && productInfo.product_number}</Breadcrumb.Item>
                </Breadcrumb>
            }
        >
            <Spin spinning={isLoading}>
                <Descriptions>
                    <Descriptions.Item label={<span className={"font-weight-700"}>Status</span>}>
                        <StatusTag text={productInfo && productInfo.status_label}
                                   slug={productInfo && productInfo.status}/>
                    </Descriptions.Item>
                    <Descriptions.Item label={<span
                        className={"font-weight-700"}>Reason</span>}>{activeIssue && activeIssue.type_label}</Descriptions.Item>
                    <Descriptions.Item label={<span
                        className={"font-weight-700"}>Message from MoveOn</span>}>{activeIssue && activeIssue.customer_note}</Descriptions.Item>
                </Descriptions>
            </Spin>
            <Table
                dataSource={issueMetas}
                rowKey="id"
                title={() => <span className="font-weight-700">Changed Product Details</span>}
                pagination={false}
                loading={isLoading}
                scroll={{
                    x: true
                }}
            >
                <Column
                    title="Meta"
                    dataIndex="meta"
                    key="meta"
                    align={"center"}
                    width={'20%'}
                    render={(meta, item) => {
                        let metaText = meta.reduce((acc, current) => acc + `${current.title} : ${current.value + " "}`, '')
                        return (
                            <span className="font-weight-bold">{metaText}</span>
                        )
                    }}
                />
                <Column
                    title="Previous price"
                    dataIndex="price"
                    key="price"
                    align={"center"}
                    width={'10%'}
                    render={(price, record) => {
                        return <>{price * parseFloat(record.fx).toFixed(2)} BDT</>
                    }}
                />
                <Column
                    title="Changed price"
                    dataIndex="change_price"
                    key="price"
                    align={"center"}
                    width={'10%'}
                    render={(change_price, record) => {
                        return <>{(change_price * parseFloat(record.fx)).toFixed(2)} BDT</>
                    }}
                />
            </Table>
            {
                activeIssue && activeIssue.meta && activeIssue.meta.local_delivery_charges && (

                <Spin spinning={isLoading}>
                    <div style={{marginRight:"160px",textAlign:"end"}}>
                        <span className={"font-weight-700"}>Local Delivery Charge : </span>
                        {calculateProductPrice(activeIssue.meta.local_delivery_charges, productInfo?.fx, productInfo?.mv_comm)} BDT
                    </div>
                </Spin>
                )
            }
            {
                productItem &&
                (
                    <Table
                        dataSource={[{...productInfo, ...productItem}]}
                        rowKey="id"
                        title={() => <span className="font-weight-700">Product Description</span>}
                        loading={isLoading}
                        scroll={{
                            x: true
                        }}
                    >
                        <Column
                            title="Image"
                            dataIndex="image"
                            key="image"
                            align="center"
                            responsive={['md']}
                            width={'10%'}
                            render={(image, record) => (
                                record && record.image != null ?
                                    <Image
                                        shape="square"
                                        width={100}
                                        src={record.image}
                                    />
                                    :
                                    <Image
                                        shape="square"
                                        size={100}
                                        URL="http://placehold.jp/100x100.png"
                                    />
                            )}
                        />

                        <Column
                            title="Product Details"
                            dataIndex="id"
                            key="product_detail"
                            width={'25%'}
                            render={(id, record) => {
                                return (
                                    <>
                                        <Row>
                                            <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
                                                <div className="buy_title">
                                                    <span>
                                                        <span className={"font-weight-600"}>Title </span>
                                                        {record.title}
                                                    </span>
                                                </div>
                                                <div className="productNumber">
                                                    <span>
                                                            <span className={"font-weight-600"}>Product Number: </span>
                                                        {record.product_number}
                                                    </span>
                                                </div>
                                                {
                                                    (record.order && record.order.order_number) &&
                                                    <span>
                                                            <span className={"font-weight-600"}>Order ID: </span>
                                                        {record?.order?.order_number}
                                                        </span>
                                                }
                                            </Col>
                                        </Row>
                                    </>
                                )
                            }}
                        />
                        <Column
                            title="Status"
                            dataIndex="status"
                            key="status"
                            align={"center"}
                            style={{width: 200}}
                            render={(status, record) => (
                                <StatusTag slug={status} text={record.status_label}/>
                            )}
                        />
                        <Column
                            title="Meta"
                            dataIndex="meta"
                            key="meta"
                            align={"center"}
                            width={'20%'}
                            render={(meta, record) => {
                                return (
                                    meta && meta.map((itemVariation, itemVariationID) => {
                                        return (
                                            <div className="warehouse-address ml-lg-4"
                                                 key={`varItem-${itemVariationID}`}>
                                                <div>
                                                    {itemVariation.title}: <span
                                                    className="font-weight-bold"
                                                    style={{color: "black"}}>{itemVariation.value}</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                )
                            }}
                        />
                        <Column
                            title="Quantity"
                            dataIndex="qty"
                            key="qty"
                            align={"center"}
                            width={'10%'}
                        />
                        <Column
                            title="Price (BDT)"
                            dataIndex="productPrice"
                            key="productPrice"
                            align={"center"}
                            width={'10%'}
                            render={(productPrice, record) => {
                                return productPrice ? (productPrice).toFixed(2) : 0
                            }}
                        />
                        <Column
                            title="Amount"
                            dataIndex="productPrice"
                            key="productPrice"
                            align={"center"}
                            width={'10%'}
                            render={(productPrice, record) => {
                                return productPrice ? (productPrice * record.qty).toFixed(2) : 0
                            }}
                        />
                    </Table>
                )
            }

            <Modal
                title="Take Action"
                closable={true}
                onClose={() => setShowAction(false)}
                visible={showAction}
                width={800}
            >
                <Form
                    form={form}
                    layout={"vertical"}
                    name="register"
                    onFinish={onSubmit}
                    scrollToFirstError
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item name="action" label="Action" rules={[{required: true}]}>
                                <Select
                                    onChange={(value) => form.setFieldsValue({action: value})}
                                    placeholder={"Select an action"}
                                >
                                    <Option value="purchase">Request to be purchased</Option>
                                    <Option value="refund">Request to be refund</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <p>If you select "Request to be purchased", we will adjust increased amount </p>
                            <br/>
                            {
                                issueMetas && issueMetas.map((item, index) => {
                                    let metaText = item.meta.reduce((acc, current) => acc + `${current.title} : ${current.value + ", "}`, '')
                                    let reducePrice = item.change_price - item.price
                                    return (
                                        <table key={index + "product_meta"}>
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <span className="font-weight-bold">{metaText} = </span>
                                                </td>
                                                <td>
                                                    <span
                                                        className="font-weight-bold">{calculateProductPrice(reducePrice, productInfo?.fx, productInfo?.mv_comm)}</span>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    )
                                })
                            }
                            <br/>
                            <p>to this product price and you must have to pay once the invoice is updated.</p>
                            <p>If you select "Request to be refund", We will refund you money. Please read our <a
                                href="/"> Refund Policy</a></p>
                            <br/>
                            <div className="form-group">
                                <Button type={"primary"} htmlType="submit" disabled={actionLoading}
                                        loading={actionLoading}>
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal>

        </PageHeader>
    )
}
export default ProductAction
