import React, { useState, useEffect, useRef } from 'react'
import {
    Layout,
    Select,
    Col,
    Row,
    Button,
    Modal,
    Form,
    Input,
    Table,
    Radio,
    Tooltip,
    message,
    Spin
} from "antd";
import ProductPropertiesTable from './ProductPropertiesTable';
import { getProductDetails } from 'utils/product-client';
import { ProductDetailsButtonAddNote } from 'assets/SvgIcons';
import { calculateShipmentCost, getShippingCategoriesWithQuery, getShippingCategories } from 'utils/product-client';
import EditPropertiesTable from './EditPropertiesTable';

function EditProductModal({ openModal, setOpenModal, editProduct,setEditProduct, setPlaceProduct }) {

    const [form] = Form.useForm();
    const shipmentSectionRef = useRef();
    const propertiesSectionRef = useRef()
    const [messageApi, contextHolder] = message.useMessage();
    const [input, setInput] = useState("");
    const [searchLink, setSearchLink] = useState("");
    const [searchTitle, setSearchTitle] = useState("")
    const [product, setProduct] = useState([]);
    const [selectedVariants, setSelectedVariants] = useState([]);
    const [shipment, setShipment] = useState([]);
    const [slots, setSlots] = useState([]);
    const [shipping, setShipping] = useState([]);
    const [isSearched, setIsSearched] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [fetched, setFetched] = useState(false)

    useEffect(() => {
      if(editProduct){
        setInput(editProduct?.link)
        setIsLoading(true)
          setFetched(false)
              const response = getProductDetails(editProduct?.link);
              response.then(res => {
                      setProduct(res?.data?.data)
                      setIsLoading(false)
                      setFetched(true)
                  }
              ).catch(({ response }) => {
                  if (response?.data) {
                      messageApi.open({
                          type: 'error',
                          content: response?.data?.message,
                          style: {marginTop: '50px'}
                      })
                  }
              }).finally(x=> {
                      setIsLoading(false)
                  }
              )

        form.setFieldsValue({
          'Product Link': input,
        })
      }
    }, [input, editProduct])

    useEffect(() => {
        const response = getShippingCategories();
        response
            .then((res) => {
                setShipment(res?.data?.data);
            })
          .catch(({ response }) => {
            if (response?.data) {
              messageApi.open({
                type: 'error',
                content: response?.data?.message,
                style: {marginTop: '50px'}
              })
            }
          })
    }, [editProduct]);

    const selectProductCategory = (_, label) => {
        if (label === undefined) {
            setShipping([])
            return
        }
        const { id } = label;
        const response = calculateShipmentCost(id);
        response.then(res => {
            setSlots(res?.data?.data)
            const shipping = res?.data?.data?.map((item) => {
                return {
                    aw_cat_id: item.id,
                    shipping: item.warehouse.name,
                    cost: item.rate,
                    unit: item.unit_type,
                    delivery: item.warehouse.est_sh_time
                }
            })
            setShipping(shipping)
        }).catch(({ response }) => {
            if (response?.data) {
              messageApi.open({
                type: 'error',
                content: response?.data?.message,
                style: {marginTop: '50px'}
              })
            }
          })
    };

    const handleCategorySearch = (value) => {
        const response = getShippingCategoriesWithQuery(value)
        response.then(res => setShipment(res?.data?.data))
          .catch(({ response }) => {
            if (response?.data) {
              messageApi.open({
                type: 'error',
                content: response?.data?.message,
                style: {marginTop: '50px'}
              })
            }
          })
    };

    const handleLinkSearch = (value) => {
        setIsLoading(true)
        setIsSearched(false)
        let link;
        link = value
        if(value.trim().split("url=").length === 2){
            const encodedUrl = value.trim().split("=")[1]
            const decodedUrl = decodeURIComponent(encodedUrl)
            link = decodedUrl
        }
        const response = getProductDetails(link);
        response.then(res => {
            setProduct(res?.data?.data)
            setSearchTitle(res?.data?.data?.title)
            setSearchLink(res?.data?.data?.link)
            if (res?.status === 200) {
                setIsSearched(true)
            }
            setIsLoading(false)
        }
        ).catch(({ response }) => {
          if (response?.data) {
            messageApi.open({
              type: 'error',
              content: response?.data?.message,
              style: {marginTop: '50px'}
            })
          }
        }).finally((x) => {
            setIsLoading(false)
            setIsSearched(false)
        })
    };

    const columns = [
        {
            title: "Shipping",
            dataIndex: 'shipping',
            width: '33.33%',
            render: (_, record) => (
                <>
                    <span className='edit-import-shipping-table-cell-text'> <Radio value={1}></Radio>{record.shipping}</span>
                </>
            )
        },
        {
            title: "Cost",
            dataIndex: 'cost',
            width: '33.33%',
            render: (_, record) => (
                <>
                    <span className='edit-import-shipping-table-cell-text'> {record.cost}/{record.unit} </span>
                </>
            )
        },
        {
            title: "Delivery",
            dataIndex: 'delivery',
            width: '33.33%',
            render: (_, record) => (
                <>
                    <span className='edit-import-shipping-table-cell-text'> {record.delivery}</span>
                </>
            )
        },
    ]

    const nestedColumns = [
        {
            title: 'SL',
            dataIndex: 'sl',
            render: (_, record) => (
                <>
                    <span className='edit-import-shipping-table-cell-text'> {record.sl}</span>
                </>
            )
        },
        {
            title: 'Slot',
            dataIndex: 'slot',
            render: (_, record) => (
                <>
                    <span className='edit-import-shipping-table-cell-text'> {record.slot}</span>
                </>
            )
        },
        {
            title: 'Rate',
            dataIndex: 'rate',
            render: (_, record) => (
                <>
                    <span className='edit-import-shipping-table-cell-text'> {record.rate}/{record.unit_type}</span>
                </>
            )
        },
        {
            title: 'Delivery',
            dataIndex: 'delivery',
            render: (_, record) => (
                <>
                    <span className='edit-import-shipping-table-cell-text'> {record.delivery}</span>
                </>
            )
        },
    ];

    const nestedData = slots?.[0]?.categoryPriceSlots?.map((slot, index) => {
        return {
            sl: index + 1,
            slot: `${slot.min_amount}-${slot.max_amount}`,
            rate: slot.rate_per_unit,
            delivery: slots?.[0]?.warehouse.est_sh_time,
            unit_type: slots?.[0]?.unit_type
        }
    })

    const expandedRowRender = (record) => {
        return (
            <Table
                style={{ width: "80%", margin: '0 auto', paddingTop: '24px', paddingBottom: '24px' }}
                columns={nestedColumns}
                dataSource={nestedData}
                pagination={false}
                size="small"
            />
        );
    };

    const handleSubmit = () => {
        if (selectedVariants.length === 0) {
            messageApi.open({
                type: 'warning',
                content: 'Please select atleast one property!',
                style: { marginTop: '50px' }
            });
          scrollToProductPropertySection()
        }
        else {
          let hasMinQty = selectedVariants.every(variant=>variant.qty > 0 );
          if(hasMinQty){
            const newProduct = {
              title: product?.title,
              vid: product?.vid,
              link: product?.link,
              image: product?.image,
              vendor: product?.meta?.vendor,
              store_id: product?.shop_id,
              aw_cat_id: shipping?.[0]?.aw_cat_id,
              metaItems: selectedVariants.map((rows) => {
                return {
                  sku_id: rows.sku,
                  key: rows.variation.map((data)=>data.propValue).join(","),
                  price: parseFloat(rows.price),
                  qty: rows.qty,
                  meta:
                      rows.variation.map((item) => {
                          if(Array.isArray(item) === true) {
                              return {
                                  title: "Main Product",
                                  value: "No Value"
                              }
                          } else {
                              return {
                                  title: item.propTitle,
                                  value: item.propInnerValue,
                                  label: item.propLabel,
                                  thumb: item.innerImage
                              }
                          }
                      })
                };
              })
            }
            setOpenModal(false)
            setSearchTitle('')
            setShipping([])
            setSelectedVariants([])
            setSearchLink("")
            setIsSearched(false)
            setPlaceProduct((prevState) => {
              const newArray = [...prevState];
              newArray[editProduct?.key] = newProduct
              return newArray
            })
            messageApi.open({
              type: 'success',
              content: 'Product Updated Successfully!',
              style: { marginTop: '50px' }
            });
          } else {
            messageApi.open({
              type: 'warning',
              content: 'Please select minimum quantity!',
              style: {marginTop: '50px'}
            })
            scrollToProductPropertySection()
          }
        }
    }

    const scrollToShipmentSection = () => {
        if (shipmentSectionRef.current) {
            shipmentSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
  const scrollToProductPropertySection = ()=> {
    if (propertiesSectionRef.current) {
      propertiesSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

    const handleCloseModal = () => {
        form.resetFields();
        setOpenModal(false);
        setInput("")
        setShipping([])
        setSelectedVariants([])
        setSearchTitle('')
        setSearchLink("")
        setIsSearched(false)
        setFetched(false);
        setEditProduct(null)
    }

    const onFinishFailed = (errorInfo) => {
        scrollToShipmentSection()
    };

    return (
        <>
            {contextHolder}
            <Modal
                destroyOnClose={true}
                open={openModal}
                onCancel={handleCloseModal}
                footer={null}
                width={1200}
            >
                <div style={{ position: 'relative' }}>
                    <Layout className='order-place-modal-wrapper' ref={shipmentSectionRef} style={{ opacity: isLoading ? '0.5' : '1' }}>
                        {isLoading && <Spin style={{ position: 'absolute', zIndex: 1000, top: '25%', left: '50%' }} size="large" />}
                        <Form layout='vertical' onFinish={handleSubmit} form={form} onFinishFailed={onFinishFailed}>
                            <Row gutter={16} style={{ paddingTop: '16px' }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                                    <h1 className='order-place-modal-header'>Edit Product</h1>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ marginTop: "12px" }}>
                                    <Form.Item label="Product Link" name={`Product Link`} rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a link',
                                        },

                                    ]}>
                                        <div style={{ position: 'relative' }}>
                                            <Input.Search
                                                placeholder="Copy & paste your desired product link here"
                                                enterButton="Search"
                                                size="large"
                                                className="custom-search-button"
                                                defaultValue={searchLink ? searchLink : input}
                                                onChange={(e) => setSearchLink(e.target.value)}
                                                onSearch={handleLinkSearch}
                                                onKeyPress={(e) => {
                                                  if (e.key === 'Enter') {
                                                    e.preventDefault();
                                                  }
                                                }}
                                            />
                                        </div>
                                    </Form.Item>
                                </Col>
                                {searchTitle ? <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                                    <Form.Item label="Title" name={`title`}>
                                        <h3>{searchTitle}</h3>
                                    </Form.Item>
                                </Col> : <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                                    <Form.Item label="Title" name={`title`}>
                                        <h3>{editProduct?.title}</h3>
                                    </Form.Item>
                                </Col>}
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                                    <Form.Item label="Shipment Category" name={`Shipment Category`} rules={[
                                        {
                                            required: true,
                                        },
                                    ]}>
                                        <Select
                                            showSearch
                                            onChange={selectProductCategory}
                                            onSearch={handleCategorySearch}
                                            defaultValue=""
                                            style={{ height: '40px' }}
                                            options={shipment.map((category) => {
                                                return {
                                                    value: category.name.toLowerCase(),
                                                    label: category.name,
                                                    id: category.id,
                                                };
                                            })}
                                            allowClear
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                    <div style={{ paddingTop: '24px' }}>
                                        <label style={{ fontWeight: 700 }} className="edit-import-info-label">Category Shipment Cost</label>
                                        <Table
                                            scroll={{ x: 800 }}
                                            className="edit-import-shipping-table"
                                            columns={columns}
                                            dataSource={shipping}
                                            style={{ paddingTop: "24px", width: '100%' }}
                                            pagination={false}
                                            expandable={{ expandedRowRender }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: "24px", width: '100%' }} ref={propertiesSectionRef}>
                                <Row gutter={[0, 24]} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <Col span={21} xs={12} sm={17} md={18} lg={19} xl={20} xxl={21} >
                                        <h1 className='place-order-address-section-header'>Product Properties</h1>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '16px', width: '100%', borderRadius: '6px' }}>
                                    <EditPropertiesTable
                                        product={product}
                                        editProduct={editProduct}
                                        variation={product?.variation}
                                        selectedVariants={selectedVariants}
                                        setSelectedVariants={setSelectedVariants}
                                        openModal={openModal}
                                        setOpenModal={setOpenModal}
                                        isSearched={isSearched}
                                        fetched={fetched}
                                        setFetched={setFetched}
                                    />
                                </Row>
                            </Row>
                            <Row style={{ paddingTop: "4px" }}>
                                <Tooltip title="upcoming">
                                    <Button style={{ height: '40px', cursor: 'not-allowed' }} className='order-place-bottom-cta' ><span style={{ color: "black" }}>
                                        <ProductDetailsButtonAddNote />
                                    </span>Add Note</Button>
                                </Tooltip>
                            </Row>
                            {/* <Row style={{ paddingTop: "24px", display: 'flex', justifyContent: 'space-between' }}>
                        <h1 className='place-order-address-section-header'>Total amount</h1>
                        <h1 className='place-order-address-section-header'>৳50,000</h1>
                            </Row> */}
                            <Row
                                style={{
                                    paddingTop: "32px",
                                    gap: "12px",
                                    display: "flex",
                                    justifyContent: "end",
                                }}
                            >
                                <Form.Item>
                                    <Button
                                        style={{
                                            color: "#4D4D4D",
                                            padding: "12px 32px",
                                            display: "flex",
                                            alignItems: "center",
                                            background: "#DDD",
                                            borderRadius: "5px",
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                            fontWeight: "500",
                                            lineHeight: "16px",
                                        }}
                                       onClick={handleCloseModal}
                                    >
                                        <span>Cancel</span>
                                    </Button>
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        htmlType="submit"
                                        style={{
                                            color: "#FFF",
                                            padding: "12px 33px",
                                            display: "flex",
                                            alignItems: "center",
                                            background: "#F37B7B",
                                            borderRadius: "5px",
                                            fontSize: "14px",
                                            fontStyle: "normal",
                                            fontWeight: "500",
                                            lineHeight: "16px",
                                        }}
                                    >
                                        Save
                                    </Button>
                                </Form.Item>
                            </Row>
                        </Form>
                    </Layout>
                </div>
            </Modal>
        </>
    )
}

export default EditProductModal
